/* stylelint-disable plugin/browser-compat */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable max-line-length */
:root {
  --card-background: #FFFFFF;
  --card-color: #000000;
  --card-font-size: 14px;
  --color: #000000;
  --color-dark: #000000;
  --color-primary: #27AA5E;
  --color-primary-rgb: 39, 170, 94;
  --color-primary-contrast: #FFFFFF;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-primary-shade: #1F884B;
  --color-primary-tint: #BEE5CE;
  --color-medium: #666666;
  --color-light: #C8C7CC;
  --border-color: #92949C;
  --gcn-popup-background-color: white;
  --menu-filters-container-background-color: transparent;

  /* We are now overriding some of the newly imported styles form the core-rect package */
  --ion-color-primary: var(--color-primary);
  --ion-color-primary-rgb: var(--color-primary-rgb);
  --ion-color-primary-contrast: var(--color-primary-contrast);
  --ion-color-primary-contrast-rgb: var(--color-primary-contrast-rgb);
  --ion-color-primary-shade: var(--color-primary-shade);
  --ion-color-primary-tint: var(--color-primary-tint);
  --ion-color-medium: var(--color-medium);
  --ion-color-light: var(--color-light);

  /* Custom color variable presets */
  --color-top-nav-title: var(--color-primary);
  --color-top-nav-bg: white;
  --color-scroll-nav-button: darkgrey;
  --color-scroll-nav-button-selected: var(--color-primary);
  --color-scroll-nav-bg: white;
  --color-scroll-nav-border: var(--color-primary);
  --color-sec-header-title: white;
  --color-sec-header-bg: var(--color-medium);
  --color-menu-item-title: black;
  --color-menu-item-description: black;
  --color-menu-item-price: black;
  --color-menu-item-mod-bg: var(--color-primary);
  --color-menu-item-mod-checkmark: var(--color-primary);
  --color-button-affirmative: white;
  --color-button-affirmative-bg: var(--color-primary);
  --color-button-affirmative-border: 1px solid var(--color-primary);
  --color-button-negative: white;
  --color-button-negative-bg: var(--cancel-red);
  --color-button-negative-border: 1px solid var(--cancel-darkred);
  --color-button-cart-icon: white;
  --color-button-cart-number: var(--color-primary);
  --color-button-cart-bg: var(--color-primary);
  --color-button-cart-border: 1px solid white;
  --color-button-disabled: white !important;
  --color-button-disabled-bg: #BCC1CC !important;
  --color-button-disabled-border: none !important;
  --color-flash-cart-order-quantity: #B3B3B3;
}

.gcn-web .gcn .font-title {
  font-family: Futura, Arial, sans-serif;
}

.gcn-web .gcn .font-body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.gcn-web .gcn.with-popup {
  overflow: hidden;
}

.gcn-web .gcn .touch-blocker-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

.gcn-web .gcn .touch-blocker-overlay .popup .popup-inner {
  background-color: white;
}

/* FLASH-COMPATIBLE PAGE NAVIGATION */
.gcn-web .gcn .page-nav-view .header-container .logo-container {
  height: 90px;
  background-color: white;
}

.gcn-web .gcn .page-nav-view .header-container .logo-container .logo {
  color: black;
  font-size: 32px;
  line-height: 2.4;
  width: auto;
}

.gcn-web .gcn .page-nav-view .header-container .title-container {
  margin: 8px 20px;
}

.gcn-web .gcn .page-nav-view .header-container .title-container .title {
  font-size: 18px;
  font-weight: bold;
  width: 300px;
}

.gcn-web .gcn .page-nav-view .column {
  grid-template-columns: repeat(1, 1fr);
  height: auto;
  margin-bottom: 20px;
}

.gcn-web .gcn .page-nav-view .body {
  padding-bottom: 0;
  overflow: scroll;
  align-items: flex-start;
}

.gcn-web .gcn .page-nav-view .column .page-button {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  font-size: 18px;
  padding: 10px 6px;
  margin: 8px auto;
  width: 300px;
}

.gcn-web .gcn .page-nav-view .footer {
  border-top: 1px solid #CCCCCC;
  height: 30px;
}

/* On mobile devices */
@media screen and (max-width: 600px) {
  .gcn-web .gcn .page-nav-view .header-container .title-container .title {
    padding: 10px 8px;
  }
}

.gcn-web .gcn .full-screen-overlay {
  position: fixed;
}

.gcn-web .gcn .scrolling-nav-view {
  height: 50px;
  color: white;
}

.gcn-web .gcn .scrolling-nav-view .button-touch-area {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin-left: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 600px) {
  .gcn-web .gcn .scrolling-nav-view .button-touch-area {
    padding-left: 0;
    padding-right: 0;
  }
}

.gcn-web .gcn .scrolling-nav-view .button-actual {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-scroll-nav-button);
}

.gcn-web .gcn .scrolling-nav-view .button-actual.selected {
  color: var(--color-scroll-nav-button-selected);
  border-color: var(--color-scroll-nav-border);
}

.gcn-web .gcn .section-view .section-header {
  min-height: 50px;
  text-align: center;
  background-color: var(--color-sec-header-bg);
}

.gcn-web .gcn .section-view .column {
  max-width: 1080px;
  margin: auto;
}

.gcn-web .gcn .section-view .item-cell-view.promoted .header {
  --reco-highlight-color: var(--color-primary);
}

.gcn-web .gcn .page-view .section-view .section-header .name {
  border: none;
  font-size: 24px;
  color: var(--color-sec-header-title);
  margin: 0;
}

.gcn-web .gcn .page-view .section-view .item-cell-view {
  box-shadow: none !important;
  border-width: 0;
  margin: 0 !important;
  width: 50% !important;
}

@media screen and (min-width: 768px) {
  .gcn-web .gcn .page-view .section-view .item-cell-view {
    width: 33% !important;
  }
}

@media screen and (min-width: 1080px) {
  .gcn-web .gcn .page-view .section-view .item-cell-view {
    width: 25% !important;
  }
}

/* Menu item popup breakpoints */
.gcn-web .gcn .touch-blocker-overlay.menu-item-order-view .popup,
.gcn-web .gcn .touch-blocker-overlay.recommended-item .popup {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0 !important;
  right: 0;
}

@media (min-width: 600px) {
  .gcn-web .gcn .touch-blocker-overlay.menu-item-order-view .popup,
  .gcn-web .gcn .touch-blocker-overlay.recommended-item .popup {
    width: 80vw;
  }
}

@media (min-width: 768px) {
  .gcn-web .gcn .touch-blocker-overlay.menu-item-order-view .popup,
  .gcn-web .gcn .touch-blocker-overlay.recommended-item .popup {
    width: 768px;
  }
}

@media (min-width: 940px) {
  .gcn-web .gcn .touch-blocker-overlay.menu-item-order-view .popup,
  .gcn-web .gcn .touch-blocker-overlay.recommended-item .popup {
    width: 940px;
    max-width: 1200px;
  }
}

.gcn-web .gcn .item-order-view {
  background-color: var(--gcn-popup-background-color);
  width: 100%;

  /* max-width: 1200px !important; */
}

@media (max-width: 600px) {
  .gcn-web .gcn .item-order-view .bottom-nav .button {
    min-width: 80px;
  }

  .gcn-web .gcn .item-order-view .bottom-nav .button.checkout {
    display: none;
  }
}

.gcn-web .gcn .item-order-view .bottom-nav .button.done {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;
}

.gcn-web .gcn .item-cell-view .card {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 24px 16px;
  border-radius: 8px;
  background: var(--card-background);
  color: var(--card-color);
  font-size: var(--card-font-size);
  box-shadow: 0 4px 16px rgba(0,0,0,0.12);
  overflow: hidden;
}

.gcn-web .gcn .item-cell-view .card .image {
  height: 150px;
}

.gcn-web .gcn .item-cell-view .card .title-line {
  display: block;
  position: relative;
  margin: 0 0 4px;
  padding: 16px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.3;
}

.gcn-web .gcn .item-cell-view .card .title-line .title {
  color: var(--color-menu-item-title);
  max-width: 100% !important;
}

.gcn-web .gcn .item-cell-view .card .description {
  color: var(--color-menu-item-description);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.1em;
  padding: 0 16px 16px;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: 100% !important;
}

.gcn-web .gcn .addon-set-picker-view .addons,
.gcn-web .gcn .price-option-picker-view .price-options {
  display: grid;
}

.gcn-web .gcn .addon-set-picker-view .addons .addon-item,
.gcn-web .gcn .price-option-picker-view .price-options .price-option-button {
  box-shadow: none;
  border: 1px solid lightgrey;
  padding: 8px;
  width: auto;
}

.gcn-web .gcn .addon-set-picker-view .addons .addon-item.selected,
.gcn-web .gcn .price-option-picker-view .price-options .price-option-button.selected {
  background-color: var(--color-menu-item-mod-bg);
}

.gcn-web .gcn .addon-set-picker-view .addons .addon-item .image {
  margin-right: 8px;
  height: 34px;
  width: 34px;
}

.gcn-web .gcn .addon-set-picker-view .addons .addon-item.selected .image::after {
  color: var(--color-menu-item-mod-checkmark);
  font-size: 24px;
  padding-left: 5px;
}

.gcn-web .gcn .addon-set-picker-view .addons .addon-item .content {
  /* Leaner mods, reduced padding as compared to Kiosk */
  padding: 0;
}

@media screen and  (max-width: 600px) {
  .gcn-web .gcn .addon-set-picker-view .addons .addon-item .price {
    display: table-footer-group;
    text-align: center;
  }
}

.gcn-web .gcn .item-cell-view .card .price-options.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.gcn-web .gcn .item-cell-view .card .price-options .price-options-list {
  width: 50%;
  min-width: 120px;
}

.gcn-web .gcn .item-cell-view .web-badges {
  width: 50%;
  text-align: right;
}

.gcn-web .gcn .item-cell-view .badges span {
  vertical-align: middle;
  width: 0.85em;
  height: 0.85em;
  margin-left: 4px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.gcn-web .gcn .item-cell-view .card .price-options {
  font-weight: normal;
  padding: 8px 16px 16px;
}

.gcn-web .gcn .item-cell-view .card .price-option {
  display: block;
  color: var(--color-menu-item-price);
  font-size: 14px;
  margin-bottom: 0;
  padding-right: 8px;
}

.gcn-web .gcn .item-cell-view .card .price-option .price {
  color: var(--color-menu-item-price);
  font-size: 18px;
  font-weight: normal;
}

.gcn-web .gcn .item-cell-view .card .price-option .price .cents {
  font-size: 18px;
  font-weight: inherit;
  line-height: 1;
  vertical-align: inherit;
}

.gcn-web .gcn .item-cell-view .card .calories {
  font-size: 14px;
}

.gcn-web .gcn .item-details-view .item-title-line .name {
  color: var(--color-menu-item-title);
}

.gcn-web .gcn .item-details-view .item-title-line .price {
  color: var(--color-menu-item-price);
}

@media (max-width: 600px) {
  .gcn-web .gcn .page-view .section-view .item-cell-view {
    width: 100% !important;
  }

  .gcn-web .gcn .item-cell-view .card {
    display: flex;
    display: flexbox;
    flex-direction: column;
    padding-left: 100px;
    margin: 8px;
    border-radius: 4px;
  }

  .gcn-web .gcn .item-cell-view .card.no-image {
    padding-left: 0;
  }

  .gcn-web .gcn .item-cell-view .card .image {
    width: 100px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .gcn-web .gcn .item-cell-view .card .title-line {
    padding: 8px 14px;
    font-size: 16px;
  }

  .gcn-web .gcn .item-cell-view .card .description {
    font-size: 14px;
    padding: 0 14px;
  }

  .gcn-web .gcn .item-cell-view .card .price-options {
    padding: 0 14px;
    padding: 8px 14px;
    margin-bottom: 12px;
  }

  .gcn-web .gcn .item-cell-view .card .price-option {
    font-size: 12px;
  }

  .gcn-web .gcn .item-cell-view .card .price-option .price {
    font-size: 14px;
  }

  .gcn-web .gcn .item-cell-view .card .price-option .price .cents {
    font-size: 14px;
  }
}

/* RECOS */
.gcn-web .gcn .recommendations .carousel-h-inner {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.gcn-web .gcn .reco-container .item-cell-view .card {
  margin: 0;
  height: 100%;
}

.gcn-web .gcn .recommendations .carousel-h-inner .item-cell-view .card .description {
  white-space: break-spaces;
}

.gcn-web .gcn .customize-flow-view .recommendations .reco-container,
.gcn-web .gcn .item-customize-view .recommendations .reco-container,
.gcn-web .gcn .popup .item-details-view .recommendations .reco-container,
.gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container {
  min-height: 450px;
}

.gcn-web .gcn .customize-flow-view .recommendations .reco-container .item-cell-view .web-badges .calories.align-left,
.gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view .web-badges .calories.align-left,
.gcn-web .gcn .popup .item-details-view .recommendations .reco-container .item-cell-view .web-badges .calories.align-left,
.gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .item-cell-view .web-badges .calories.align-left {
  text-align: left;
}

.gcn-web .gcn .popup-inner .upsell-interstitial-view {
  min-height: 480px;
}

.gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view {
  max-width: 200px;
  height: auto;
}

@media (max-width: 600px) {
  .gcn-web .gcn .upsell-interstitial-view .body {
    height: 100%;
    padding-bottom: 80px;
  }

  .gcn-web .gcn .popup-inner .upsell-interstitial-view {
    min-height: 380px;
    max-width: 500px !important;
    width: 100vw;
  }

  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view {
    max-width: none;
  }

  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view .card {
    min-height: 100px;
    padding-left: 150px;
  }

  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view .title-line .title {
    height: auto;
  }

  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .item-cell-view .image {
    height: 100%;
    width: 150px;
  }
}

.gcn-web .gcn .upsell-interstitial-view {
  height: 100%;
}

.gcn-web .gcn .upsell-interstitial-view .body {
  height: auto;
}

.gcn-web .gcn .upsell-interstitial-view .recommendations .item-cell-view {
  max-height: 350px;
}

.gcn-web .gcn .upsell-interstitial-view .bottom-bar .button {
  background-color: white;
  border: 1px solid #CCCCCC;
  border-radius: 50px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
  padding: 8px 32px;
  text-decoration: none;
}

.gcn-web .gcn .upsell-interstitial-view .bottom-bar .button.active {
  color: black;
}

.gcn-web .gcn .item-cell-view .false-button-container {
  width: 50%;
}

.gcn-web .gcn .item-cell-view .false-button {
  float: right;
  position: relative;
  top: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view {
    width: 50vw;
  }

  .gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view .image {
    height: 230px;
  }
}

@media screen and (min-width: 940px) {
  .gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view {
    width: 50vw;
  }

  .gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view .image {
    height: 284px;
  }
}

@media screen and (max-width: 768px) {
  .gcn-web .gcn .recommendations .carousel-h-inner {
    display: flex;
    flex-direction: column;
  }

  .gcn-web .gcn .customize-flow-view .recommendations .reco-container,
  .gcn-web .gcn .item-customize-view .recommendations .reco-container,
  .gcn-web .gcn .popup .item-details-view .recommendations .reco-container,
  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container {
    height: auto;
    min-height: 225px;
  }

  .gcn-web .gcn .customize-flow-view .recommendations .reco-container .item-cell-view,
  .gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view,
  .gcn-web .gcn .popup .item-details-view .recommendations .reco-container .item-cell-view,
  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .item-cell-view {
    width: calc(100% - 40px);
    padding: 0;
    margin: 10px 20px;
    box-shadow: none;
  }

  .gcn-web .gcn .reco-container .item-cell-view .card {
    min-height: 112px;
  }

  .gcn-web .gcn .customize-flow-view .recommendations .reco-container .item-cell-view .image,
  .gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view .image,
  .gcn-web .gcn .popup .item-details-view .recommendations .reco-container .item-cell-view .image,
  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .item-cell-view .image {
    height: 100%;
    border-radius: 0;
  }

  .gcn-web .gcn .customize-flow-view .recommendations .reco-container .item-cell-view .description,
  .gcn-web .gcn .item-customize-view .recommendations .reco-container .item-cell-view .description,
  .gcn-web .gcn .popup .item-details-view .recommendations .reco-container .item-cell-view .description,
  .gcn-web .gcn .popup .upsell-interstitial-view .recommendations .reco-container .item-cell-view .description {
    display: none;
  }
}

.gcn-web .button-container.settings {
  display: flex;
  align-items: center;
}

/* CART BUTTON */
.gcn-web .gcn .cart-button-container .cart-button.button {
  margin: 4px;
  background-color: var(--color-button-cart-bg);
  border: var(--color-button-cart-border);
  text-transform: none;
  color: #FFFFFF;

  /* to visually center it because the bubble adds width */
  padding: 0 8px 0 0;
}

.gcn-web .gcn .cart-button-container .cart-button.button > ion-icon {
  color: var(--color-button-cart-icon);
}

.gcn-web .gcn .cart-button-container .cart-button.button .cart-item-count {
  position: absolute;
  background: var(--color-button-cart-icon);
  font-weight: bold;
  color: var(--color-button-cart-number);
  top: 5px;
  right: -10px;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

/*********
 * REACT *
 *********/
.gcn-web .gcn .carousel-h-inner {
  padding-bottom: 0;
}

.gcn-web .gcn .scrolling-nav-view .carousel-h-inner {
  background-color: var(--color-scroll-nav-bg);
}

.gcn-web .gcn .page-view:not(.desktop) .background-container {
  padding: 0;
}

@media (max-width: 600px) {
  .gcn-web .gcn {
    --top-nav-bar-height: 48px;
  }
}

.gcn-web .gcn .scrolling-nav-view.has-fulfillment-header {
  position: sticky;
  height: auto;
  background-color: white;
}

.gcn-web .gcn .scrolling-nav-view .carousel-h-outer,
.gcn-web .gcn .scrolling-nav-view .carousel-h-inner,
.gcn-web .gcn .has-menu-filters-header,
.gcn-web .gcn .upsell-interstitial-view .reco-container .carousel-h-outer {
  height: auto;
}

.gcn-web .gcn .fulfillment-header {
  --font-color: #000000;

  width: 100%;
  color: var(--font-color);
  text-align: center;
}

.gcn-web .gcn .page-view {
  position: relative;
  height: auto !important;
  top: var(--top-nav-bar-height) !important;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container {
  display: inline-block;
  min-width: 400px;
  margin: 20px;
  padding: 20px 10px;
  background: #FFFFFF;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  transition: all 0.2s linear;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container .item {
  --padding-start: 10px;
  --inner-padding-end: 0;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container .fulfillment-secondary-link {
  text-decoration: underline;
  height: 2em;
  padding-top: 8px;
  font-size: 14px;
  margin: 0;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container .location-name {
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container .location-address {
  font-size: 18px;
  margin: 0;
  white-space: pre;
  line-height: 1.2;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container .fulfillment-method-label {
  color: var(--color-primary);
  font-weight: bold;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container .edit-fulfillment-method-button {
  text-decoration: underline;
  font-size: 18px;
  color: var(--color-primary);
  font-weight: normal;
}

.gcn-web .gcn .fulfillment-header .fulfillment-info-container .change-fulfillment-text {
  margin: 0;
  font-size: 18px;
}

/* COLLAPSED HEADER ON SCROLL */
.gcn-web .gcn .fulfillment-header.collapse {
  width: 100%;
  background: #FFFFFF;
}

.gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container {
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
  align-items: center;
  justify-content: center;
}

.gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .location-name {
  font-size: 18px;
  line-height: 1em;
  margin: 8px 0;
  color: black;
}

.gcn-web .gcn .fulfillment-header .location-name-label,
.gcn-web .gcn .fulfillment-header .location-name-button {
  --color: var(--font-color);

  display: flex;
  justify-content: flex-end;
}

.gcn-web .gcn .fulfillment-header .location-name-label {
  /*
   * The label is a disabled button which would have a < 1.0 opacity.
   * We want the label to look like an ordinary label, so reset the opacity to 1
   */
  opacity: 1;
}

/* Temporarily hide original slash and use the one above instead */
.gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .location-name::after {
  display: none;
}

.gcn-web .gcn .fulfillment-header.collapse .location-name-label ion-icon,
.gcn-web .gcn .fulfillment-header.collapse .location-name-button ion-icon {
  display: none;
}

.gcn-web .gcn .fulfillment-header.collapse .fulfillment-secondary-link {
  display: none;
}

.gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .location-address-container {
  display: none;
}

@media screen and (max-width: 600px) {
  .gcn-web .gcn .fulfillment-header {
    width: 100%;
    background: #FFFFFF;
  }

  .gcn-web .gcn .fulfillment-header .fulfillment-info-container {
    min-width: auto;
  }

  .gcn-web .gcn .fulfillment-header .fulfillment-info-container,
  .gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 4px;
    border: none;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: 0;
  }

  .gcn-web .gcn .fulfillment-header .fulfillment-info-container .edit-fulfillment-method,
  .gcn-web .gcn .fulfillment-header .fulfillment-info-container .location-address-container {
    --min-height: 20px;
  }

  .gcn-web .gcn .fulfillment-header .fulfillment-info-container .location-name {
    font-size: 18px;
    line-height: 1.2em;
    margin: 4px 0;
  }

  .gcn-web .gcn .fulfillment-header .fulfillment-info-container .change-fulfillment-text,
  .gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .location-name,
  .gcn-web .gcn .fulfillment-header .fulfillment-info-container .location-address {
    font-size: 18px;
    line-height: 1em;
    margin: 8px 0;
  }

  .gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .change-fulfillment-text {
    color: black;
  }

  .gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .edit-fulfillment-method {
    text-align: center;

    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .location-name::after {
    content: none;
    margin-right: 0;
  }

  .gcn-web .gcn .fulfillment-header.collapse .fulfillment-info-container .edit-fulfillment-method-button {
    display: none;
  }
}

.gcn-web .store-info-modal .store-info-content {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 16px;
  --padding-end: 16px;

  height: calc(100% - 44px);
}

.gcn-web .store-info-modal .store-info-container .title {
  font-weight: bold;
}

.gcn-web .store-info-modal .store-info-container .store-details,
.gcn-web .store-info-modal .store-info-container .store-hours {
  border-bottom: 1px solid var(--ion-color-light);
  padding-bottom: 20px;
}

.gcn-web .store-info-modal .store-info-container .store-hours .fulfillment-method-hours {
  margin: 0;
}

.gcn-web .store-info-modal .store-info-container .store-hours .fulfillment-method-hours .day-schedule .label {
  color: #666666;
}

.gcn-web .store-info-modal .store-info-container .store-accepted-payment-methods .payment-methods-container {
  align-items: center;
  padding: 10px 0;
}

.gcn-web .store-info-modal .store-info-container .store-accepted-payment-methods .payment-method-icon-title {
  width: 100%;
  margin: 0 0 10px;
}

.gcn-web .store-info-modal .store-info-container .store-accepted-payment-methods .card-scheme-image {
  width: 50px;
  margin: 0 10px;
  max-height: 50px;
}

@media screen and (max-width: 768px) {
  .gcn-web .gcn .touch-blocker-overlay.recommended-item .popup {
    right: 0;
    left: 0 !important;
    top: 0 !important;
  }
}

.gcn-web .gcn .web-cart .cta-button.button,
.gcn-web .gcn .checkout-page .cta-button.button {
  --background: var(--color-primary);
  --background-hover: white;
  --background-hover-opacity: 0.4;
  --ion-color-tint: white;
  --border-radius: 6px;

  background-color: var(--color-primary);
  width: 100%;
  margin: 0;
  height: 40px;
  text-transform: none;
  color: #FFFFFF;
  border-bottom: none;
  border-width: var(--border-width);
  font-size: 18px;
  font-weight: bold;
  line-height: 44px;
  border-radius: var(--border-radius);
  text-align: center;
  padding-top: 0;
}

.gcn-web .gcn .web-cart .cta-button:active,
.gcn-web .gcn .checkout-page .cta-button:active {
  background-color: none;
}

.gcn-web .gcn .web-cart .simple-icon-button.button,
.gcn-web .gcn .checkout-page .simple-icon-button.button {
  background: transparent;
  padding: 0;
  margin: 0;
  height: 35px;
  border-bottom: none;
  text-transform: none;
  color: var(--color-primary);
}

.gcn-web .gcn .web-cart .simple-icon-button.button .button:active,
.gcn-web .gcn .checkout-page .simple-icon-button.button .button:active {
  background-color: rgba(0,0,0,0.2);
  border-bottom: none;
  border-top: none;
}

.gcn-web .gcn .checkout-page .favorite-button-container {
  align-self: flex-end;
  text-align: right;
}

.gcn-web .gcn .checkout-page .favorite-button-container .favorite-button {
  --padding-end: 0;

  color: var(--ion-color-light);
}

.gcn-web .gcn .checkout-page .favorite-button-container .favorite-button:hover,
.gcn-web .gcn .checkout-page .favorite-button-container .favorite-button.selected {
  color: var(--ion-color-primary);
}

.gcn-web .gcn .checkout-page .favorite-button-container .favorite-button.selected {
  opacity: 1;
}

.gcn-web .gcn .web-cart {
  position: absolute;
  top: 50px;
  right: 0;
  width: 350px;
  height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  background: #FFFFFF;
  padding: 16px;
  z-index: 1000;
  overflow: scroll;
  box-shadow: -16px 10px 16px 0 rgba(0,0,0,0.12);
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .gcn-web .gcn .web-cart {
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 0;
    box-shadow: none;
  }
}

.gcn-web .gcn .checkout-page {
  overflow: scroll;
  width: 100%;
  height: 100%;
}

.gcn-web .gcn .web-cart ion-item {
  --padding-start: 0;

  padding-top: 16px;
}

.gcn-web .gcn .checkout-page ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

.gcn-web .gcn .web-cart .title-item {
  padding-top: 0;
  font-weight: bold;
}

.gcn-web .gcn .web-cart .totals-list .order-totals-item,
.gcn-web .gcn .web-cart .totals-list .checkout-item {
  padding-top: 0;
}

.gcn-web .gcn .checkout-page ion-row {
  width: 100%;
}

.gcn-web .gcn .web-cart .item-row,
.gcn-web .gcn .web-cart ion-col,
.gcn-web .gcn .web-cart ion-label,
.gcn-web .gcn .web-cart .ordered-item .modifier,
.gcn-web .gcn .checkout-page ion-row,
.gcn-web .gcn .checkout-page ion-col,
.gcn-web .gcn .checkout-page ion-label,
.gcn-web .gcn .checkout-page .ordered-item .modifier {
  padding: 0;
  margin: 0;
}

.gcn-web .gcn .checkout-page .checkout-promo-image {
  height: 180px;
  margin: -20px -20px 10px;
}

@media screen and (max-width: 768px) {
  .gcn-web .gcn .checkout-page .checkout-promo-image {
    display: none;
  }
}

.gcn-web .gcn .checkout-page .checkout-promo-image ion-img {
  object-fit: cover;
}

.gcn-web .gcn .checkout-page .col-content .payment-details .no-rewards ion-label {
  white-space: normal;
}

.gcn-web .gcn .web-cart .order-totals-item.bold,
.gcn-web .gcn .checkout-page .order-totals-item.bold {
  font-weight: bold;
}

.gcn-web .gcn .web-cart .order-totals-item .text-right,
.gcn-web .gcn .checkout-page .order-totals-item .text-right {
  text-align: right;
}

.gcn-web .gcn .web-cart .ordered-item .quantity,
.gcn-web .gcn .checkout-page .ordered-item .quantity {
  font-size: 18px;
  color: var(--color-flash-cart-order-quantity);
  margin-right: 4px;
  font-weight: bold;
}

.gcn-web .gcn .web-cart .ordered-item .name,
.gcn-web .gcn .checkout-page .ordered-item .name {
  padding: 10px;
  font-weight: bold;
  white-space: break-spaces;
}

.gcn-web .gcn .web-cart .ordered-item .modifier,
.gcn-web .gcn .web-cart .ordered-item .special-request,
.gcn-web .gcn .web-cart .ordered-item .recipient,
.gcn-web .gcn .web-cart .cart-recommendations .recommended-item ion-card-content,
.gcn-web .gcn .checkout-page .ordered-item .modifier,
.gcn-web .gcn .checkout-page .ordered-item .special-request,
.gcn-web .gcn .checkout-page .ordered-item .recipient {
  padding: 5px 10px;
  white-space: normal;
}

.gcn-web .gcn .web-cart .ordered-item .modifier::before,
.gcn-web .gcn .checkout-page .ordered-item .modifier::before {
  content: "+ ";
}

.gcn-web .gcn .web-cart .ordered-item .modifier.deselected::before,
.gcn-web .gcn .checkout-page .ordered-item .modifier.deselected::before {
  content: "- ";
}

.gcn-web .gcn .web-cart .ordered-item .special-request,
.gcn-web .gcn .checkout-page .ordered-item .special-request,
.gcn-web .gcn .web-cart .ordered-item .recipient,
.gcn-web .gcn .checkout-page .ordered-item .recipient {
  text-transform: uppercase;
  font-weight: bold;
}

.gcn-web .gcn .web-cart .ordered-item .price,
.gcn-web .gcn .checkout-page .ordered-item .price {
  padding: 10px 0;
  text-align: right;
  font-weight: bold;
}

.gcn-web .gcn .checkout-page .paid-by-line {
  display: flex;
}

.gcn-web .gcn .checkout-page .paid-by-line .card-scheme-image {
  display: inline-block;
  width: 30px;
  margin: 0 10px;
  max-height: 20px;
}

.gcn-web .gcn .web-cart .button {
  margin: 0;
  height: 35px;
  border: none;
  padding-left: 0;
  padding-right: 0;
  text-transform: none;
  color: var(--color-primary);
}

.gcn-web .gcn .web-cart .button:active {
  background-color: rgba(0,0,0,0.2);
  border-bottom: none;
  border-top: none;
}

.gcn-web .web-cart .edit-fulfillment-method-button {
  background-color: transparent;
  color: var(--color-primary);
  padding-right: 0;
}

.gcn-web .web-cart .totals-list {
  position: sticky;
  bottom: -16px;
  padding-top: 8px;
  padding-bottom: 16px;
  z-index: 1000;
}

.gcn-web .gcn .checkout-page .order-totals-list .order-totals-item p {
  margin-bottom: 0;
}

.gcn-web .gcn .web-cart .totals-list .order-totals-item p,
.gcn-web .gcn .checkout-page .order-totals-list .order-totals-item p,
.gcn-web .gcn .checkout-page .loyalty .reward .apply-reward-button,
.gcn-web .gcn .checkout-page .stored-value .stored-value-transaction .refund-stored-value-transaction,
.gcn-web .gcn .checkout-page .coupon .get-coupons-button,
.gcn-web .gcn .checkout-page .location-buttons .call-button,
.gcn-web .gcn .checkout-page .location-buttons .directions-button,
.gcn-web .gcn .checkout-page .loyalty .get-rewards-button,
.gcn-web .gcn .checkout-page .post-close-buttons .email-receipt-button,
.gcn-web .gcn .checkout-page .post-close-buttons .new-order-button,
.gcn-web .gcn .checkout-page .post-close-buttons .sign-up-button,
.gcn-web .gcn .checkout-page .stored-value .use-stored-value-button,
.gcn-web .gcn .checkout-page .edit-order-details-button,
.gcn-web .gcn .checkout-page .ordered-item .name,
.gcn-web .gcn .web-cart .ordered-item .name,
.gcn-web .gcn .checkout-page .ordered-item .price,
.gcn-web .gcn .web-cart .ordered-item .price,
.gcn-web .gcn .checkout-page .fulfillment-method .fulfillment-method-label,
.gcn-web .gcn .checkout-page .fulfillment-method .pickup-time,
.gcn-web .gcn .checkout-page .fulfillment-method .outpost-name,
.gcn-web .gcn .checkout-page .fulfillment-address .fulfillment-address-header,
.gcn-web .gcn .checkout-page .fulfillment-address .fulfillment-address-body,
.gcn-web .gcn .checkout-page .ordered-item .modifier,
.gcn-web .gcn .checkout-page .ordered-item .special-request,
.gcn-web .gcn .web-cart .ordered-item .modifier,
.gcn-web .gcn .web-cart .ordered-item .special-request {
  font-size: 18px;
}

.gcn-web .gcn .order-destination-view.curbside {
  height: auto;
  max-height: 600px;
}

.gcn-web .gcn .order-destination-view .guest-name .guest-name-input {
  width: calc(100% - 20px);
}

.gcn-web .gcn .delivery-address-view .delivery-instructions {
  text-align: center;
  padding: var(--ion-padding, 16px);
  padding-bottom: 0;
}

@media screen and (min-width: 768px) {
  .gcn-web .gcn .order-destination-view .guest-name .guest-name-input {
    width: calc(100% - 20px);
  }
}

/* Table number buzzer */
@media (max-width: 600px) {
  .gcn-web .gcn .order-destination-view.table-number {
    height: 600px;
  }

  .gcn-web .gcn .order-destination-view .table-number .table-stand {
    background-image: url("https://assets.getbite.com/images-default/table-number-stand@2x.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 40%;
    height: 150px;
  }

  .gcn-web .gcn .order-destination-view .table-number .table-stand .user-input {
    top: 0;
  }

  .gcn-web .gcn .order-destination-view .table-number.tracker .table-stand .user-input {
    position: relative;
    top: 76px;
    left: 14px;
  }
}

/* Affirmative and Negative buttons */
.gcn-web .gcn .alert-view .button.ok,
.gcn-web .gcn .item-order-view .bottom-nav .button.done,
.gcn-web .gcn .order-destination-view .guest-name .done-button {
  color: var(--color-button-affirmative);
  background-color: var(--color-button-affirmative-bg);
  border: var(--color-button-affirmative-border);
}

.gcn-web .gcn .item-order-view .bottom-nav .button.done.disabled,
.gcn-web .gcn .item-order-view .bottom-nav .button.done.disabled:active {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn-web .gcn .alert-view .button.cancel {
  color: var(--color-button-negative);
  background-color: var(--color-button-negative-bg);
  border: var(--color-button-negative-border);
}

/* NEW CHECKOUT PAGE */
.gcn-web .gcn .checkout-page .checkout-content {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
}

.gcn-web .gcn .checkout-page .left-side,
.gcn-web .gcn .checkout-page .right-side {
  padding: 10px;
  min-height: auto;
}

.gcn-web .gcn .checkout-page .left-side {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .gcn-web .gcn .checkout-page .left-side,
  .gcn-web .gcn .checkout-page .right-side {
    padding: 10px;
    min-height: calc(100vh - 44px - 32px);
    min-height: calc(calc(var(--vh, 1vh) * 100) - 44px - 32px);
  }
}

@media screen and (min-width: 900px) {
  .gcn-web .gcn .checkout-page .left-side,
  .gcn-web .gcn .checkout-page .right-side {
    padding: 30px;
    min-height: calc(100vh - 44px - 32px);
    min-height: calc(calc(var(--vh, 1vh) * 100) - 44px - 32px);
  }
}

/* Panel width and alignment in new checkout (large screens) */
@media screen and (min-width: 768px) {
  .gcn-web .gcn .checkout-page .left-side .col-content,
  .gcn-web .gcn .checkout-page .right-side .col-content {
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    overflow: hidden;
    overflow-y: scroll;
    max-width: 500px;
  }

  .gcn-web .gcn .checkout-page .left-side .col-content {
    float: right;
    width: 100%;
  }
}

.gcn-web .gcn .scrolling-nav-view .carousel-h-inner::-webkit-scrollbar,
.gcn-web .gcn .page-view::-webkit-scrollbar {
  display: none;
}

.gcn-web .gcn .checkout-page .left-side .col-content::-webkit-scrollbar,
.gcn-web .gcn .checkout-page .right-side .col-content::-webkit-scrollbar,
.gcn-web .gcn .full-screen-overlay::-webkit-scrollbar,
.gcn-web .gcn .checkout-page::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .gcn-web .gcn .checkout-page .checkout-col-container {
    margin-bottom: 32px;
  }

  .gcn-web .gcn .checkout-page .left-side,
  .gcn-web .gcn .checkout-page .right-side {
    padding: 0;
    min-height: auto;
  }

  .gcn-web .gcn .checkout-page .left-side {
    margin-bottom: 0;
  }
}

.gcn-web .checkout-page .show-all-button-container ion-button {
  margin: auto;
}

.gcn-web .checkout-page ion-label h3 {
  font-size: 1.17em;
  font-weight: bold;
}

.gcn-web .gcn .checkout-page .edit-order-details-button,
.gcn-web .gcn .checkout-page .edit-customer-identifiers-button,
.gcn-web .gcn .checkout-page .loyalty .reward .apply-reward-button,
.gcn-web .gcn .checkout-page .stored-value .stored-value-transaction .refund-stored-value-transaction {
  text-decoration: underline;
  font-size: 18px;
  font-weight: bold;
  margin: 4px 0;

  --color: var(--color-primary);
}

.gcn-web .gcn .checkout-page .order-details-list,
.gcn-web .gcn .checkout-page .customer-identifiers-list {
  border-bottom: 1px solid var(--color-light);
  padding-bottom: 16px;
}

.gcn-web .gcn .checkout-page .login-wall-container {
  border-bottom: 1px solid var(--color-light);
  margin-bottom: 16px;
}

.gcn-web .gcn .checkout-page .login-wall-container ion-button {
  --border-radius: 6px;
}

.gcn-web .gcn .checkout-page .login-wall-container .login-wall-title-container .title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.gcn-web .gcn .checkout-page .login-wall-container ion-button.signin {
  font-size: 18px;
  margin-right: 8px;

  --background-hover: white;
  --background-hover-opacity: 0.4;
}

.login-button-container ion-button {
  font-weight: bold;
}

.login-button-container ion-button,
.generic-form ion-button,
.gcn-web .gcn .web-cart .simple-icon-button.button {
  --background-hover: white;
  --background-hover-opacity: 0.4;
}

.gcn-web .gcn .checkout-page .login-wall-container ion-button.signup {
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .gcn-web .gcn .checkout-page .login-wall-container ion-button.signin {
    margin-right: 0;
    margin-left: 0;
  }

  .gcn-web .gcn .checkout-page .login-wall-container ion-button.signup {
    margin-right: 0;
    margin-left: 0;
  }
}

.gcn-web .gcn .checkout-page .login-wall-container .login-wall-button-container .continue-as-guest {
  text-decoration: underline;
}

.gcn-web .gcn .checkout-page .customer-identifiers-list .customer-identifiers-item {
  --min-height: 20px;
}

.gcn-web .gcn .checkout-page .fulfillment-method {
  font-weight: bold;
  --min-height: unset;
}

.gcn-web .gcn .checkout-page .fulfillment-method .fulfillment-method-label {
  margin-right: 5px;
}

.gcn-web .gcn .checkout-page .fulfillment-method .pickup-time,
.gcn-web .gcn .checkout-page .fulfillment-method .outpost-name {
  color: var(--color-primary);
}

.gcn-web .gcn .checkout-page .fulfillment-address .fulfillment-address-header {
  font-weight: bold;
  color: var(--color-primary);
}

.gcn-web .gcn .checkout-page .fulfillment-address .fulfillment-address-body {
  white-space: pre-line;
}

.gcn-web .gcn .checkout-page .fulfillment-address,
.gcn-web .gcn .checkout-page .delivery-instructions {
  margin-top: 16px;
}

.gcn-web .gcn .checkout-page .delivery-instructions ion-label {
  font-weight: bold;
  margin-bottom: 6px;
}

.gcn-web .gcn .checkout-page .delivery-instructions textarea {
  --border-radius: 6px;
  --padding-start: 16px;
  --padding-end: 16px;

  border: 1px solid var(--border-color);
}

.gcn-web .gcn .checkout-page .location-buttons {
  --inner-padding-end: 0;
}

.gcn-web .gcn .checkout-page .location-buttons .locations-buttons-row {
  width: 100%;
  margin: 8px 0 4px;
}

.gcn-web .gcn .checkout-page .location-buttons .locations-buttons-row ion-col {
  padding: 4px;
}

.gcn-web .gcn .checkout-page .order-summary-list .ordered-item {
  padding-top: 8px;
  padding-bottom: 8px;
}

.gcn-web .gcn .checkout-page .order-summary-list .ordered-item ion-label {
  padding-bottom: 8px;
}

.gcn-web .gcn .checkout-page .order-summary-list .ordered-item:last-child {
  --inner-border-width: 0;
}

.gcn-web .gcn .checkout-page .tip-selector,
.gcn-web .gcn .checkout-page .stored-value {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-light);
}

.gcn-web .gcn .checkout-page .loyalty.authed {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-light);
}

.gcn-web .gcn .checkout-page .stored-value.has-transactions {
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid var(--color-light);
}

.gcn-web .checkout-page .coupon ion-item,
.gcn-web .checkout-page .loyalty ion-item,
.gcn-web .checkout-page .stored-value ion-item {
  --inner-padding-end: 0;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --min-height: 20px;
}

/* TODO - do a better job of generalizing buttons vs this mess */
.gcn-web .checkout-page .coupon .get-coupons-button,
.gcn-web .checkout-page .loyalty .get-rewards-button,
.gcn-web .checkout-page .stored-value .use-stored-value-button,
.gcn-web .checkout-page .post-close-buttons .sign-up-button,
.gcn-web .checkout-page .post-close-buttons .email-receipt-button,
.gcn-web .checkout-page .post-close-buttons .new-order-button,
.gcn-web .checkout-page .location-buttons .call-button,
.gcn-web .checkout-page .location-buttons .directions-button,
.gcn-web .checkout-page .submit-custom-tip {
  width: 100%;
  margin: 0;
  height: 40px;
  text-transform: none;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  padding-top: 0;
  font-weight: normal;

  --padding-start: 16px;
  --padding-end: 16px;
  --background: #FFFFFF;
  --border-radius: 6px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--color-medium);
  --color: var(--color-medium);
}

.gcn-web .checkout-page .stored-value .stored-value-transaction .card-name,
.gcn-web .checkout-page .stored-value .stored-value-transaction .card-balance {
  color: var(--color-medium);
  font-size: 14px;
}

.gcn-web .gcn .checkout-page .post-close-buttons ion-item {
  --inner-padding-end: 0;

  padding-top: 4px;
  padding-bottom: 4px;
}

.gcn-web .gcn .checkout-page .cta-button.button {
  --border-style: none;
  --border-width: 0;
}

.gcn-web .gcn .checkout-page .loyalty .status,
.gcn-web .gcn .checkout-page .loyalty .guest-text {
  font-weight: bold;
  color: var(--color-primary);
}

.gcn-web .gcn .checkout-page .loyalty .status .status-value {
  text-align: right;
}

.gcn-web .gcn .checkout-page .loyalty .description {
  color: black;
  font-weight: normal;
  font-size: 18px;
}

.gcn-web .checkout-page .loyalty .guest-text ion-label {
  white-space: pre-wrap;
}

.gcn-web .gcn .checkout-page .loyalty .reward .reward-name {
  font-size: 18px;
  font-weight: normal;
  padding-left: 10px;
}

.gcn-web .gcn .checkout-page .tip-selector {
  align-items: center;
  font-size: 18px;
}

.gcn-web .gcn .checkout-page .tip-selector .other-tip ion-icon {
  margin-inline: 20px 0;
  font-size: 1em;
}

.gcn-web .gcn .checkout-page .tip-selector .other-tip ion-input {
  --padding-start: 10px;

  border: 1px solid var(--ion-color-light);
  border-radius: 6px;
  margin: 5px 0;
}

.gcn-web .gcn .checkout-page .order-totals-list .order-totals-item {
  --min-height: 20px;

  padding: 6px 0;
}

.gcn-web .gcn .checkout-page .order-totals-list .order-totals-item ion-skeleton-text {
  float: right;
}

.gcn-web .gcn .checkout-page .guest-survey {
  --background: var(--color-primary-tint);
  --border-radius: 6px;

  margin-top: 8px;
  margin-bottom: 8px;
}

.gcn-web .gcn .checkout-page .guest-survey .survey-info {
  padding: 16px;
}

.gcn-web .gcn .checkout-page .guest-survey .survey-info .title,
.gcn-web .gcn .checkout-page .guest-survey .survey-info .rating-submitted {
  text-align: center;
  font-size: 14px;
  margin-bottom: 8px;
}

.gcn-web .gcn .checkout-page .guest-survey .survey-info .rating-submitted {
  margin-top: 16px;
}

.gcn-web .gcn .checkout-page .guest-survey .survey-info .survey-button {
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 0;

  font-size: 3em;
  height: auto;
}

.gcn-web .gcn .checkout-page .fulfillment-instructions ion-label {
  text-align: center;
  white-space: break-spaces;
}

.gcn-web .gcn .checkout-page .fulfillment-instructions ion-label .fulfillment-instructions-text {
  text-align: left;
}

.gcn-web .page-view .footnote {
  font-size: 12px;
  max-width: 900px;
  margin: 0 auto 10px;
}

.gcn-web .gcn .checkout-page .footer-container {
  margin-bottom: 28px; /* handle some weirdness coming from fullscreen-overlay */
  margin-left: -16px;
  margin-right: -16px;
  padding-bottom: 36px; /* handle weirdness from large height iframes like FreedomPay */
}

@media (max-width: 600px) {
  .gcn-web .gcn .checkout-page .footer-container {
    margin-left: -16px;
    width: calc(100% + 32px);
  }
}

.gcn-web .gcn .web-cart .summary-footnote,
.gcn-web .gcn .web-cart .cart-recommendations .recommended-item {
  --inner-padding-end: 0;

  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 0;
  margin-inline-start: unset;
  margin-inline-end: unset;
  min-height: 72px;
  padding: 8px;
  width: 100%;
}

.gcn-web .gcn .web-cart .summary-footnote,
.gcn-web .gcn .web-cart .inactive {
  border-radius: 8px;
  border: 1px solid #CCCCCC;
}

.gcn-web .gcn .web-cart .active {
  border-radius: 8px;
  border: 1px solid var(--color-primary);
}

.gcn-web .web-cart .recommendation-name {
  color: var(--color);
  display: flex;
  font-size: 16px;
  font-weight: bold;
  font-family: var(--ion-font-family, inherit);
  line-height: normal;
  margin-bottom: 0;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.gcn-web .web-cart .summary-description,
.gcn-web .web-cart .recommendation-description {
  color: var(--color);
  display: flex;
  font-size: 12px;
  font-weight: normal;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gcn-web .web-cart .recommendation-price {
  color: var(--color);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  margin: 0;
}

.gcn-web .web-cart .recommendation-badge {
  display: inline-flex;
  height: 16px;
  width: 16px;
  margin-right: 6px;
}

.gcn-web .web-cart .bottom-aligned {
  align-content: flex-end;
  justify-content: flex-end;
}

.gcn-web .web-cart .bottom-aligned .hidden {
  display: none;
}

.gcn-web .web-cart .summary-footnote .summary-column,
.gcn-web .web-cart .cart-recommendations .recommended-item .recommendation-column {
  align-self: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  white-space: normal;
}

.gcn-web .web-cart .cart-recommendations {
  flex: auto;
  padding-top: 20px;
}

.gcn-web .web-cart .summary-icon {
  align-self: auto;
}

.gcn-web .web-cart .cart-recommendations .recommendation-image {
  align-self: flex-start;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.gcn-web .web-cart .recommendation-column-full-height {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.gcn-web .web-cart .checkmark {
  height: 16px;
  width: 16px;
  padding: 4px;
  border-radius: 100px;
  background-color: var(--color-primary);
  text-align: center;
  text-align: -webkit-center;
  color: #FFFFFF;
}

.gcn-web .web-cart .add {
  height: 16px;
  width: 16px;
  padding: 4px;
  border-radius: 100px;
  border: 1px solid #CCCCCC;
}

/* CODE TO UNIFY LANDING PAGE AND GCN */

.gcn-web.bite-web {
  --top-nav-bar-height: 76px;
}

::slotted(ion-label) {
  pointer-events: auto;
}

ion-label {
  pointer-events: auto;
}

.gcn-web.bite-web .gcn .top-nav-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-top-nav-bg);
}

.gcn-web .gcn .top-nav-view .container {
  background-color: var(--color-top-nav-bg);
}

.gcn-web .gcn .top-nav-view .title {
  color: var(--color-top-nav-title);
}

.gcn-web.bite-web .gcn .top-nav-view .title-container {
  height: 100%;
  display: block;
  padding: 8px 16px;
}

@media (max-width: 400px) {
  .gcn-web .top-nav-view .title-container {
    padding-left: 20px;
  }

  .gcn-web .gcn .top-nav-view .title-container .title {
    font-size: 18px;
  }
}

.gcn-web.bite-web .gcn .top-nav-view .title-container .title {
  width: auto;
  height: 60px;
  display: block;
  color: var(--color-primary);
  text-align: center;
  line-height: 3;
}

@media (max-width: 600px) {
  .gcn-web.bite-web .gcn .top-nav-view .title-container .title {
    line-height: normal;
    text-align: left;
    max-width: 170px;
  }
}

/* override the js width/heights */
.gcn-web.bite-web .gcn .top-nav-view .title-container img {
  margin: 0 !important;
  object-fit: contain;
  height: 100% !important;
  width: 100% !important;
}

.gcn-web.bite-web .gcn .top-nav-view .button-container {
  position: relative;
}

.gcn-web .gcn .top-nav-view .button-container {
  height: var(--top-nav-bar-height);
}

.gcn-web.bite-web .gcn .top-nav-view .container,
.gcn-web.bite-web .gcn .top-nav-view .button-container.home {
  display: none;
}

.gcn-web.bite-web .gcn .top-nav-view .button-container.settings {
  order: 6; /* Sit all the way to the right */
  text-align: end;
  padding: 8px;
}

@media screen and (max-width: 600px) {
  .gcn-web.bite-web .gcn,
  .gcn-web.bite-web .gcn .top-nav-view {
    --top-nav-bar-height: 76px;
  }
}

.gcn-web .popover-container {
  position: absolute;
  box-shadow: 0 0 16px 10px rgba(0,0,0,0.15);
}

.gcn-modal {
  --width: 100%;
  --min-width: auto;
  --max-width: auto;
  --height: 100%;
  --min-height: auto;
  --max-height: auto;
  --overflow: hidden;
  --border-radius: 0;
  --border-width: 0;
  --border-style: none;
  --border-color: transparent;
  --background: var(--ion-background-color, #FFFFFF);
  --box-shadow: none;
  --backdrop-opacity: 0;

  z-index: 1000;
  inset: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  outline: none;
  contain: strict;
  pointer-events: none;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .gcn-modal {
    --width: 600px;
    --height: 500px;
    --ion-safe-area-top: 0;
    --ion-safe-area-bottom: 0;
    --ion-safe-area-right: 0;
    --ion-safe-area-left: 0;
    --border-radius: 10px;
  }
}

.gcn-modal .gcn-modal-backdrop {
  inset: 0;
  display: block;
  position: absolute;
  transform: translateZ(0);
  contain: strict;
  cursor: pointer;
  opacity: 0.01;
  touch-action: none;
  z-index: 2;
  background-color: var(--ion-backdrop-color, #000000);
  pointer-events: auto;
}

.gcn-modal .gcn-modal-backdrop:first-of-type {
  opacity: 0.4;
}

.gcn-modal .gcn-modal-container {
  pointer-events: auto;
  border-radius: var(--border-radius);
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
  transform: translate3d(0, 100%, 0);
}

/* Take into account the header height */
.gcn-modal .gcn-modal-container ion-content {
  height: calc(100% - 44px);
}

@keyframes slide-up {
  100% { transform: translate3d(0, 0%, 0); }
}

.gcn-modal .gcn-modal-container.slide-up {
  animation: slide-up 0.3s forwards;
}

.gcn-web .date-time-picker-container {
  min-width: 600px;
}

.gcn-web .date-time-picker-container .options-buttons-container {
  padding: 16px 20%;
}

.gcn-web .date-time-picker-container .options-buttons-container ion-button {
  --background-hover: var(--ion-color-primary);

  margin: 10px 0;
}

.gcn-web .date-time-picker-container .options-buttons-container ion-button:hover {
  opacity: 1;
}

.gcn-web .date-time-picker-container .options-buttons-container ion-button:hover::part(native) {
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
  color: #FFFFFF;
}

.gcn-web .date-time-picker-container .time-picker-container .date-picker-list {
  max-height: 300px;
  overflow: scroll;
}

.gcn-web .date-time-picker-container .calendar-container .calendar {
  --background: #FFFFFF;

  height: 260px;
}

.gcn-web .date-time-picker-container .time-picker-container .date-picker-list .time-item {
  --padding-start: 0;
  --background-hover: var(--ion-color-primary);

  text-align: center;
}

@media screen and (max-width: 600px) {
  .gcn-web .touch-blocker-overlay.dining-option.modal.future-order-step-view .popup {
    min-width: 100%;
    bottom: 0;
    padding-bottom: var(--ion-safe-area-bottom);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .gcn-web .future-order-step-view .popup-inner {
    height: 100%;
  }

  .gcn-web .date-time-picker-container {
    min-width: 100%;
  }

  .gcn-web .date-time-picker-container .options-buttons-container {
    padding: 16px;
  }

  .gcn-web .date-time-picker-container ion-datetime {
    margin: auto;
  }

  .gcn-web .date-time-picker-container .time-picker-container .date-picker-list {
    max-height: 200px;
  }
}

.gcn-web .gcn-modal.prompt-signup-modal {
  --width: 350px;
  --height: 300px;
}

/* Login UI */
.gcn-modal-container .login-content .ion-justify-content-center .ion-text-center.signup-benefits {
  font-weight: bold;
}

.sc-ion-modal-ios-h .login-content .ion-justify-content-center .ion-text-center {
  font-family: var(--font-title);
  font-weight: bold;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.sc-ion-modal-ios-h .login-content .ion-justify-content-center .login-button-container ion-button {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.gcn-modal .gcn-modal-container ion-toolbar {
  --background: var(--color-primary);
}

.gcn-modal .gcn-modal-container .toolbar-title-default {
  color: white;
}

.gcn-modal .gcn-modal-container ion-icon {
  color: white;
}

/* For button hover */
.gcn-web .gcn-modal.prompt-signup-modal .new-account-button-container ion-button {
  --background: var(--color-primary);
  --background-hover: #FFFFFF;
  --background-hover-opacity: 0.4;
  --border-radius: 6px;

  font-weight: bold;
}

.gcn-web .gcn-modal.prompt-signup-modal .prompt-signup-content {
  --padding: 16px;
  --padding-top: var(--padding);
  --padding-bottom: var(--padding);
  --padding-start: var(--padding);
  --padding-end: var(--padding);
}

.gcn-web .gcn-modal.prompt-signup-modal .cancel-button-container .cancel {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .gcn-web .gcn-modal.prompt-signup-modal {
    --width: 100%;
  }
}

.gcn-web .mobile-app-cart-container {
  /* The top-nav which normally holds the cart in flash is position:fixed. If position is not fixed,
  * then the cart just lives on top of the UI and can be scrolled with it */
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 10;
}

.gcn-web .mobile-app-cart-container .cart-button {
  display: none;
}

.gcn-web .gcn .fulfillment-header .menu-filters-container {
  border-bottom: unset;
  border-top: unset;
  justify-content: center;
  margin: 0 0 20px;
  padding: 0;
}

.gcn-web .gcn .fulfillment-header.collapse .menu-filters-container {
  margin: 0;
  padding: 0;
  width: 100%;
}

.gcn-web .gcn .fulfillment-header .menu-filters-container.active {
  margin: 0;
}

.gcn-web .gcn .fulfillment-header .menu-filters-button {
  font-size: 18px;
  color: black;
}

.gcn-web .gcn .fulfillment-header .menu-filters-button.active {
  display: inline-block;
  color: white;
}

.gcn-web .gcn .fulfillment-header .menu-filters-button .menu-filters-button-text {
  vertical-align: middle;
}

.gcn-web .gcn .fulfillment-header .menu-filters-header .menu-filters-container .modal-badge {
  background-size: 18px;
  height: 28px;
  width: 28px;
}

.gcn-web .gcn .modal-toast {
  margin-bottom: 80px;

  --border-radius: 30px;
  --width: 580px;
}

/* Stripe Elements */
.StripeElement {
  height: unset;
  box-shadow: unset;
  transition: none;
}

.StripeElement--focus {
  box-shadow: none;
  outline: none;
}

.StripeElement#payment-request-button-container {
  padding: 10px 0;
}

.freedompay-form-container .payment-request-button-container.google-pay {
  text-align: -webkit-center;
  height: 76px;
  overflow: hidden;
}

.freedompay-form-container .payment-request-button-container.apple-pay {
  text-align: -webkit-center;
  height: 100%;
  overflow: hidden;
}

.freedompay-form-container .payment-request-button-container.google-pay iframe {
  height: 76px;
  display: block;
  margin: auto;
  width: 180px;
}

.freedompay-form-container .payment-request-button-container.apple-pay iframe {
  height: 76px;
  display: block;
  margin: auto;
  width: 100%;
}

.freedompay-form-container #card-element iframe {
  width: 100%;
  height: 100%;
}

.freedompay-name-container {
  padding-left: 5px;
  font: 16px Arial, sans-serif;
  padding-right: 5px;
  width: 100%;
  margin-bottom: 16px 0;
}

.freedompay-name-label {
  font-size: 16px !important;
  font-family: Arial, sans-serif !important;
  padding-bottom: 8px !important;
}

.freedompay-name-error {
  color: #DC3545 !important;
  font-size: 12.8px !important;
  margin-top: 0.25rem !important;
  font-family: Arial, sans-serif !important;
}

.freedompay-name-field {
  --color: #495057;
  --placeholder-color: black;
  --padding-start: 10px !important;
  --highlight-color-invalid: #DC3545;
  --highlight-color-valid: #28A745;

  border: 1px solid #CED4DA;
  font: 16px Arial, sans-serif;
  border-radius: 0.25rem;
  display: block;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  height: 20px;
}

.freedompay-name-field.valid {
  border: 1px solid #28A745;
}

.freedompay-name-field.invalid {
  border: 1px solid #DC3545;
}
