/* stylelint-disable plugin/browser-compat */

/* FLOATING LABEL */
.checkout-form label {
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.5rem;
  position: absolute;
  z-index: 100;
  top: 14px;
  left: 17px;
}

.checkout-form .has-focus label,
.checkout-form .is-filled label {
  transform: scale(0.75);
  top: 2px;
  left: 4px;
}

/* Checkout form errors outside the checkout-form container */
.checkout-form-error {
  color: red;
  margin: 5px 0 0;
}

.checkout-form input {
  font-size: 100%;
  border-radius: 0;
  appearance: none;
  padding: 0;
  background: transparent;
  border-style: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* Don't show the number increment/decrement spinner in number fields */
.checkout-form input[type="number"]::-webkit-inner-spin-button,
.checkout-form input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.checkout-form input[type="number"] {
  appearance: textfield;
}

.checkout-form input:focus {
  outline-width: 0;
}

.checkout-form input:invalid,
.checkout-form textarea:invalid {
  box-shadow: none;
}
