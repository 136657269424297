/* Core CSS required for Ionic components to work properly */
@import "~@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/react/css/normalize.css";
@import "~@ionic/react/css/structure.css";
@import "~@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/react/css/padding.css";
@import "~@ionic/react/css/float-elements.css";
@import "~@ionic/react/css/text-alignment.css";
@import "~@ionic/react/css/text-transformation.css";
@import "~@ionic/react/css/flex-utils.css";
@import "~@ionic/react/css/display.css";

/* stylelint-disable max-line-length */

/**
Z-Index Strategy
- 101 Bottom Bar (this needs to be highest)
- 100 Alert
- 60 Overlay for protecting clicks
- 50 Simple Cover, Admin View
- 45 Spinner
- 40 Dining Options
- 39 Basic React popup
- 35 Any popup (touch-blocker-overlay)
- 30 Toast
- 25 Full-Screen-Flow
- 20 Kiosk Cart
- 15 Page Nav
- 10 Mobile App Cart (.mobile-app-cart-container)
- 3 Close Button on Popup
- 2 Item Details View Assorted Container
*/

.hidden {
  display: none;
}

.gcn {
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  position: relative;
  overflow: auto;
  width: 100%;
  max-width: inherit;
  height: 100%;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333333;
  line-height: 1.4285;
  background-color: var(--gcn-popup-background-color);
}

:root {
  --top-nav-bar-height: 56px;
  --scrolling-nav-bar-height: 72px;
  --scrolling-nav-row-height: var(--scrolling-nav-bar-height);
  --scrolling-nav-more-indicator-size: 42px;
  --gcn-popup-background-color: white;
  --gcn-reco-background-color: white;
  --gcn-rewards-background-color: white;
  --menu-filters-container-height: 50px;
  --menu-filters-container-background-color: white;
  --bottom-bar-height: 80px;
  --bottom-bar-space: 130px;
  --color-button-loyalty-account-text: #BD2F2B;
  --color-button-reward-number: white;
  --color-button-loyalty-account-icon: var(--color-primary);
  --item-order-view-bottom-nav: 64px;
  --side-navigation-width-percent: 33%;
  --page-width-with-side-nav-percent: 66%;

  /* Colors
   * The base colors have been tested to be WCAG AA compliant for large/UI text.
   * https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html
   */
  --ok-green: #27AA5E;
  --ok-darkgreen: #1E8549;
  --cancel-red: #E74C3C;
  --cancel-darkred: #C0392B;
  --disabled-lightgrey: #CCCCCC;
  --selected-blue: #3498DB;
  --plain-button-border-color: #999999;
  --plain-button-text-color: #333333;
  --reco-highlight-color: #F58538;
  --button-transition-values: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  --color-primary: var(--ok-darkgreen);
  --checkout-border: #666666;
  --assorted-image-placeholder: #CCCCCC;
  --side-nav-scrolling-spacer: 64px;
  --cart-top-bar-height: 64px;

  /* cart */
  --color-kiosk-cart-quantity: linear-gradient(180deg, #BC3648 0%, #EB445A 100%);
  --color-kiosk-cart-background: white;
  --color-kiosk-order-item-border: #CCCCCC;
  --color-kiosk-order-item-price: #7A7A7A;
  --color-kiosk-order-item-name: black;
  --color-kiosk-order-item-modifiers: #666666;
  --color-kiosk-order-item-remove-color: #CCCCCC;
  --color-kiosk-order-item-quantity: #B3B3B3;
  --color-kiosk-recommendation-title: black;
  --color-kiosk-recommendation-item-name: black;
  --color-loading-skeleton-pulse-start: #EFEFEF;
  --color-loading-skeleton-pulse-end: #FCFCFC;

  /* validation failed */
  --validation-fail-border-color: #CC0000;
  --validation-fail-text-color: #CC0000;
}

.gcn.tall {
  --scrolling-nav-bar-height: 88px;
  --scrolling-nav-row-height: var(--scrolling-nav-bar-height);
}

.gcn.has-page-images {
  --scrolling-nav-bar-height: 112px;
  --scrolling-nav-row-height: var(--scrolling-nav-bar-height);
}

.gcn.uses-2-row-nav-bar {
  --scrolling-nav-bar-height: 144px;
}

@media (max-width: 600px) {
  :root {
    --scrolling-nav-bar-height: 56px;
    --scrolling-nav-row-height: var(--scrolling-nav-bar-height);
    --scrolling-nav-more-indicator-size: 36px;
    --top-nav-bar-height: 48px;
  }

  .gcn.has-page-images {
    --scrolling-nav-bar-height: 80px;
  }

  /* No 2-row scrolling nav bar in mobile */
  .gcn.uses-2-row-nav-bar {
    --scrolling-nav-bar-height: 56px;
  }
}

body.bite-embed {
  background-size: cover;
}

.gcn input {
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-touch-callout: default;
  user-select: text;
}

.gcn.embed {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
}

.gcn ul {
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

.gcn .clearfix::before {
  display: table;
  content: " ";
}

.gcn .clearfix::after {
  display: table;
  content: " ";
  clear: both;
}

.gcn ::after,
::before {
  box-sizing: border-box;
}

.gcn * {
  box-sizing: border-box;
}

.gcn fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.gcn .carousel-h-outer {
  overflow: hidden;
  height: 100%;
}

.gcn .carousel-h-inner {
  overflow-x: scroll;
  overflow-y: hidden;
  width: auto;
  white-space: nowrap;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
  padding-bottom: 100px;
}

.gcn .carousel-h-inner::-webkit-scrollbar {
  display: none;
}

.gcn .customize-flow-view .carousel-h-inner {
  padding-bottom: 0;
}

.gcn.scroll .carousel-h-inner.show-scroll-bar {
  padding-bottom: 0;
  height: 100%;
}

.gcn .dictionary-card-view {
  background-color: white;
  border-radius: 4px;
  color: black;
  display: flex;
  width: 590px;
  height: 160px;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 600px) {
  .gcn .dictionary-card-view {
    width: inherit;
    height: 280px;
    display: block;
  }
}

.gcn .dictionary-card-view .image {
  background-color: #DDDDDD;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: block;
  flex: 0 0 160px;
  position: relative;
}

@media (max-width: 600px) {
  .gcn .dictionary-card-view .image {
    border-radius: 4px 4px 0 0;
    flex: none;
    width: 100%;
    height: 140px;
  }
}

.gcn .dictionary-card-view .text {
  background-color: white;
  display: block;
  flex: 2;
  overflow-y: scroll;
  padding: 24px;
  padding-top: 16px;
  position: relative;
}

.gcn .dictionary-card-view .name {
  font-size: 36px;
}

@media (max-width: 600px) {
  .gcn .dictionary-card-view .name {
    font-size: 24px;
  }
}

.gcn .dictionary-card-view .desc-long,
.gcn .dictionary-card-view .desc-short {
  font-size: 18px;
  margin-top: 8px;
}

@media (max-width: 600px) {
  .gcn .dictionary-card-view .desc-long,
  .gcn .dictionary-card-view .desc-short {
    font-size: 16px;
  }
}

.gcn .number-pad-view {
  margin: 24px 16px;
}

.gcn .number-pad-view .row {
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.gcn .number-pad-view .row:last-child {
  padding-left: 78px;
}

.gcn .number-pad-view .row .button {
  background-color: white;
  border: var(--plain-button-border-color) 1px solid;
  border-radius: 100px;
  color: #368BED;
  font-size: 24px;
  font-weight: 500;
  height: 72px;
  margin-right: 8px;
  padding: 15px;
  text-align: center;
  width: 72px;
}

.gcn .number-pad-view .row .button.del {
  color: white;
  background-image: url("https://assets.getbite.com/images-default/numpad-del-icon@2x.png");
  background-position: 12px 24px;
  background-repeat: no-repeat;
  background-size: 40px;
  font-size: 1px;
}

.gcn .number-pad-view .row .button.touched,
.bite-embed .gcn .number-pad-view .row .button.touched {
  opacity: 0.7;
}

.gcn .passcode-view {
  width: 305px;
}

.gcn .passcode-view .custom-message {
  background-color: white;
  border-bottom: #CCCCCC 2px solid;
  font-size: 20px;
  text-align: center;
  margin-bottom: 8px;
  padding: 16px;
}

.gcn .passcode-view .label {
  color: black;
  font-size: 24px;
  font-weight: 500;
  padding: 16px;
  text-align: center;
}

.gcn .passcode-view .label.error {
  color: var(--cancel-red);
}

.gcn .passcode-view .input-container {
  height: 48px;
  position: relative;
}

.gcn .passcode-view .input-container .user-input,
.gcn .passcode-view .input-container .underscores {
  position: absolute;
  width: 100%;
  height: 100%;
  color: black;
  font-family: Courier, monospace;
  font-size: 36px;
}

.gcn .passcode-view .input-container .user-input {
  font-weight: 700;
  left: 77px;
  top: -6px;
}

.gcn .passcode-view .underscores {
  color: #AAAAAA;
  font-weight: 300;
  text-align: center;
}

.gcn .refunder-view {
  background-color: var(--gcn-popup-background-color);
  width: 600px;
}

.gcn .refunder-view .header {
  background-color: #333333;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px;
}

.gcn .refunder-view .search-controls {
  display: flex;
  padding: 8px;
}

.gcn .refunder-view input {
  width: 480px;
  margin-right: 8px;
  padding-left: 8px;
}

.gcn .refunder-view .body {
  height: 416px;
  padding: 16px;
  text-align: center;
}

/* Folding this into the main body directive causes the search bar to go grey
 * when more than 1 character is typed.
 */
.gcn .refunder-view .body.scroll {
  overflow-y: scroll;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn .refunder-view .receipt-image {
  background-image: url("https://assets.getbite.com/images-default/receipt-auth-num@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 360px;
  margin-left: 150px;
  width: 220px;
}

.gcn .refunder-view .delay-message {
  font-size: 16px;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  text-align: center;
  color: var(--cancel-red);
}

.gcn .refunder-view .search-button {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  border-radius: 4px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 16px;
}

.gcn .refunder-view .search-button:active {
  opacity: 0.7;
}

.gcn .refunder-view .search-button.disabled {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn .refunder-view .order-cell-view .receipt-button {
  float: right;
}

.gcn .refunder-view .order-cell-view {
  background-color: white;
  border: 8px solid white;
  padding: 8px;
  text-align: initial;
  margin-bottom: 15px;
}

.gcn .refunder-view .order-cell-view .totals span {
  margin-right: 8px;
}

.gcn .refunder-view .order-cell-view .transactions .transaction-cell-view {
  border: 1px solid #DDDDDD;
  margin-top: 10px;
  padding: 5px 10px;
}

.gcn .refunder-view .order-cell-view .transactions .transaction-cell-view .transaction-info > span,
.gcn .refunder-view .order-cell-view .transactions .transaction-cell-view .refunds > span {
  display: block;
}

.gcn .refunder-view .order-cell-view .transactions .transaction-cell-view .refunds {
  margin-top: 3px;
}

.gcn .refunder-view .order-cell-view .transactions .transaction-cell-view .refund-controls {
  display: flex;
  padding: 7px 0;
}

.gcn .refunder-view .order-cell-view .error-label {
  padding-top: 6px;
  color: var(--cancel-red);
  font-size: 16px;
}

.gcn .refunder-view .report-body {
  padding: 16px;
  height: 100px;
}

.gcn .refunder-view .report-contents {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 0 32px;
}

.gcn .refunder-view .report-contents span {
  font-weight: 700;
}

.gcn .refunder-view .report-footnotes {
  color: #666666;
  font-size: 13px;
  margin-top: 16px;
  text-align: center;
}

.gcn .number-entry-view,
.gcn .room-number-entry {
  padding: 16px;
  width: 420px;
}

.gcn .number-entry-view .label,
.gcn .room-number-entry .label {
  margin: 16px;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}

.gcn .number-entry-view .icon {
  display: none;
}

.gcn .number-entry-view.phone .icon {
  background-image: url("https://assets.getbite.com/images-default/icon-sms-alert@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 64px;
  margin: 8px auto;
  width: 64px;
}

.gcn .number-entry-view.phone.simple-sign-up {
  padding: 0 0 16px;
  width: 500px;
}

.gcn .number-entry-view .user-input,
.gcn .room-number-entry .user-input {
  background-color: white;
  border: #999999 1px solid;
  border-radius: 4px;
  color: black;
  font-size: 24px;
  font-weight: 500;
  height: 50px;
  margin: 8px auto;
  padding: 8px;
  text-align: center;
  width: 220px;
  display: block;
}

.gcn .room-number-entry .user-input {
  width: 260px;
}

.gcn .number-entry-view .validation-failed-message,
.gcn .room-number-entry .validation-failed-message {
  color: var(--cancel-red);
  font-size: 20px;
  text-align: center;
  padding: 16px;
  margin-bottom: 8px;
}


.gcn .number-entry-view .user-input.error,
.gcn .room-number-entry .user-input.error {
  border: var(--cancel-red) 2px solid;
}

.gcn .number-entry-view .number-pad-view,
.gcn .room-number-entry .number-pad-view {
  margin: 24px auto;
}

.gcn .number-entry-view .footer .fallback-or-text-barcode-button {
  display: block;
  font-size: 18px;
  margin-top: 16px;
  text-align: center;
  font-weight: normal;
}

.gcn .number-entry-view .footer .barcode-button {
  text-align: center;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 16px;
}

.gcn .number-entry-view .footer .barcode-button:active {
  background-color: #cccccc;
  border-radius: 4px;
}

.gcn .number-entry-view .footer .footer-label,
.gcn .room-number-entry .footer .footer-label {
  font-size: 12px;
  margin-bottom: 24px;
  text-align: center;
  white-space: pre-wrap;
}

.gcn .number-entry-view .footer .footer-label > .disclaimer-clickable,
.gcn .email-receipt-view .email-footnote > .disclaimer-clickable {
  text-decoration: underline;
}

.gcn .number-entry-view .footer .button-container,
.gcn .room-number-entry .footer .button-container {
  display: flex;
  justify-content: center;
}

.gcn .room-number-entry .footer {
  margin-top: 16px;
}

.gcn .number-entry-view .button,
.gcn .room-number-entry .button {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 40px;
}

.gcn .number-entry-view .done-button.disabled,
.gcn .room-number-entry .done-button.disabled {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn .number-entry-view .done-button,
.gcn .room-number-entry .done-button {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  color: white;
  cursor: pointer;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  transition: var(--button-transition-values);
}

.gcn .number-entry-view .cancel-button,
.gcn .number-entry-view .skip-button,
.gcn .room-number-entry .cancel-button {
  background-color: white;
  border: var(--plain-button-border-color) 1px solid;
  color: var(--plain-button-text-color);
}

.bite-embed .gcn .number-entry-view .cancel-button:hover,
.bite-embed .gcn .room-number-entry .cancel-button:hover,
.gcn .number-entry-view .cancel-button:active,
.gcn .room-number-entry .cancel-button:active,
.bite-embed .gcn .number-entry-view .skip-button:hover,
.gcn .number-entry-view .skip-button:active {
  background-color: #DDDDDD;
  cursor: pointer;
}

.gcn .order-destination-view,
.gcn .payment-method-view {
  min-height: 300px;
  padding: 30px;
  width: 600px;
}

.gcn .order-destination-view .header {
  font-size: 36px;
  margin: 16px 0;
  text-align: center;
  width: 100%;
}

.gcn .order-destination-view .table-number .header {
  margin: 20px 0;
}

.gcn .order-destination-view.table-number {
  height: auto;
  padding: 10px;
}

.gcn .order-destination-view .dine-in-take-out .body {
  display: flex;
  justify-content: center;
}

.gcn .order-destination-view .button {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  height: 80px;
  padding: 20px 12px;
  margin: 0 0 16px;
  width: 100%;
  text-align: center;
}

.gcn .order-destination-view .button:last-child {
  margin: 0;
}

.gcn .order-destination-view .payment-destination .body {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  justify-content: center;
}

.gcn .order-destination-view .payment-destination .button-container {
  display: block;
  width: 100%;
}

.gcn .order-destination-view .payment-destination .button {
  color: white;
  display: block;
  font-size: 32px;
  height: 80px;
  padding: 20px 12px;
  width: 100%;
}

.gcn .order-destination-view .button:active,
.bite-embed .gcn .order-destination-view .button:hover {
  opacity: 0.7;
}

.gcn .order-destination-view .dine-in-take-out .button .image {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 140px;
}

.gcn .order-destination-view .dine-in-take-out .button.dine-in .image {
  background-image: url("https://assets.getbite.com/images-default/table-chairs@2x.png");
}

.gcn .order-destination-view .dine-in-take-out .button.take-out .image {
  background-image: url("https://assets.getbite.com/images-default/bag@2x.png");
}

.gcn .order-destination-view .dine-in-take-out .button .label {
  color: white;
  font-size: 24px;
  font-weight: 500;
  margin-top: 16px;
  text-align: center;
  width: 100%;
}

.gcn .order-destination-view .table-number .body {
  display: flex;
  justify-content: center;
}

.gcn .order-destination-view .table-number .table-stand {
  background-image: url("https://assets.getbite.com/images-default/table-number-stand@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  height: 336px;
  width: 213px;
}

.gcn .order-destination-view .table-number .table-stand .user-input {
  font-size: 140px;
  position: absolute;
  text-align: center;
  left: 60px;
  top: 230px;
  width: 217px;
}

.gcn .order-destination-view .table-number .table-stand .user-input.three-digit {
  font-size: 110px;
  left: 60px;
  top: 250px;
  width: 217px;
}

.gcn .order-destination-view .table-number .table-stand .user-input.four-digit {
  font-size: 80px;
  left: 60px;
  top: 250px;
  width: 217px;
}

.gcn .order-destination-view .table-number.tracker .table-stand {
  background-image: url('https://assets.getbite.com/images-default/table-number-tracker@2x.png');
}

.gcn .order-destination-view .table-number.tracker .table-stand .user-input {
  color: lime;
  font-family: monospace;
  font-size: 28px;
  left: 96px;
  position: absolute;
  transform: rotate(-20deg);
  text-shadow: lime 0 0 4px;
  top: 408px;
}

.gcn .order-destination-view .table-number .done-button,
.gcn .order-destination-view .guest-name .done-button,
.gcn .order-destination-view .vehicle-description .done-button {
  padding: 8px 24px;
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  color: white;
  cursor: pointer;
  font-size: 36px;
  font-weight: bold;
  border-radius: 6px;
  transition: var(--button-transition-values);
}

.gcn .order-destination-view .guest-name .skip-button {
  background-color: white;
  border: var(--plain-button-border-color) 1px solid;
  color: var(--plain-button-text-color);
  padding: 16px 40px;
  font-size: 24px;
  font-weight: 500;
  border-radius: 4px;
}

.bite-embed .gcn .order-destination-view .guest-name .skip-button:hover,
.gcn .order-destination-view .guest-name .skip-button:active {
  cursor: pointer;
}

@media (max-width: 600px) {
  .gcn .order-destination-view .button {
    height: 180px;
    width: 172px;
  }

  .gcn .order-destination-view .dine-in-take-out .button .image {
    height: 72px;
    width: 108px;
  }

  .gcn .order-destination-view .dine-in-take-out .button .label {
    font-size: 20px;
  }

  .gcn .order-destination-view .table-number .header {
    font-size: 16px;
    margin: 10px 0;
  }

  .gcn .order-destination-view .table-number .body {
    flex-direction: column;
  }

  .gcn .order-destination-view .table-number .table-stand {
    background-image: none;
    margin: 4px;
    height: initial;
    width: initial;
  }

  .gcn .order-destination-view .table-number .table-stand .user-input {
    position: relative;
    margin: 0;
    font-size: 68px;
    height: 50px;
    width: initial;
    top: -30px;
    left: 0;
  }

  .gcn .order-destination-view .number-pad-view {
    margin-bottom: 16px;
    margin-top: 4px;
  }

  .gcn .order-destination-view .number-pad-view .row {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .gcn .order-destination-view .number-pad-view .row .button {
    margin: 2px;
    border-radius: 4px;
    width: 53px;
    height: 53px;
    padding: 6px;
  }

  .gcn .order-destination-view .number-pad-view .row:last-child {
    padding-left: 55px;
  }

  .gcn .order-destination-view .number-pad-view .row .button.del {
    background-position: center;
    background-size: 32px;
  }

  .gcn .order-destination-view .table-number .done-button {
    padding: 8px 36px;
  }

  .gcn .order-destination-view .guest-name .guest-name-input,
  .gcn .order-destination-view .guest-name .guest-email-input {
    width: 360px;
  }
}

.gcn .order-destination-view .table-number .button-container,
.gcn .order-destination-view .guest-name .button-container,
.gcn .order-destination-view .vehicle-description .button-container {
  display: flex;
  justify-content: center;
}

.gcn .order-destination-view .guest-name .done-button {
  min-width: 200px;
  text-align: center;
}

.gcn .number-entry-view .done-button.disabled,
.gcn .room-number-entry .done-button.disabled,
.gcn .order-destination-view .table-number .done-button.disabled,
.gcn .order-destination-view .guest-name .done-button.disabled,
.gcn .order-destination-view .vehicle-description .done-button.disabled {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
  cursor: initial;
}

.gcn .number-entry-view .done-button:active,
.gcn .room-number-entry .done-button:active,
.bite-embed .gcn .number-entry-view .done-button:hover,
.bite-embed .gcn .room-number-entry .done-button:hover,
.gcn .order-destination-view .table-number .done-button:active,
.bite-embed .gcn .order-destination-view .table-number .done-button:hover,
.gcn .order-destination-view .guest-name .done-button:active,
.bite-embed .gcn .order-destination-view .guest-name .done-button:hover,
.gcn .order-destination-view .vehicle-description .done-button:active,
.bite-embed .gcn .order-destination-view .vehicle-description .done-button:hover {
  opacity: 0.7;
}

.gcn .list-picker-view .back-button,
.gcn .order-destination-view .back-button {
  background-color: white;
  border: var(--plain-button-border-color) 1px solid;
  border-radius: 6px;
  color: var(--plain-button-text-color);
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  padding: 8px;
  text-align: center;
  max-width: 30%;
}

.gcn .list-picker-view .back-button:active,
.bite-embed .gcn .list-picker-view .back-button:hover,
.gcn .order-destination-view .back-button:active,
.bite-embed .gcn .order-destination-view .back-button:hover {
  opacity: 0.7;
}

.gcn .order-destination-view .guest-name .header{
  font-size: 36px;
  padding-top: 16px;
  text-align: center;
}

.gcn .order-destination-view .guest-name .header.last-name {
  padding-top: 0;
}

.gcn .order-destination-view .guest-name .body {
  display: flex;
  justify-content: center;
}

.gcn .order-destination-view .guest-name .guest-name-input,
.gcn .order-destination-view .guest-name .guest-email-input {
  width: 100%;
  height: 80px;
  font-size: 54px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
  border: 1px solid #999999;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.30), 0 -2px 2px 0 rgba(0, 0, 0, 0.10) inset, 0 -12px 15px 0 rgba(255, 255, 255, 0.12) inset, 0 12px 20px 0 rgba(0, 0, 0, 0.04) inset;
  color: black;
}

.gcn .order-destination-view .guest-name .guest-name-input {
  text-transform: capitalize;
}

.gcn .order-destination-view .guest-name .guest-name-input:active,
.gcn .order-destination-view .guest-name .guest-name-input:focus {
  border: 2px solid var(--color-primary);
}

.gcn .order-destination-view .guest-name .guest-name-input.validation-failed,
.gcn .special-request-text .validation-failed,
.gcn .order-destination-view .guest-name .guest-email-input.validation-failed {
  border: 2px solid var(--validation-fail-border-color);
}

.gcn .order-destination-view .guest-name .footnote {
  margin-top: 16px;
  font-size: 24px;
  text-align: center;
}

.gcn .blocked-word-warning-container {
  color: var(--validation-fail-text-color);
  text-align: center;
  font-size: 22px;
  margin-top: 10px;
}

.gcn .order-destination-view .vehicle-description .header {
  margin: 32px 0;
}

.gcn .order-destination-view .vehicle-description .body {
  display: flex;
  flex-direction: column;
}

.gcn .order-destination-view .vehicle-description input {
  display: block;
  font-size: 32px;
  margin: 12px;
  text-align: center;
}

.gcn .group-recipient-guest-name-modal-view {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
  min-height: 200px;
}

.gcn .group-recipient-guest-name-modal-view .body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gcn .group-recipient-guest-name-modal-view .button {
  border-radius: 4px;
  color: white;
  background-color: var(--ok-green);
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  margin: 8px;
  padding: 12px;
  text-align: center;
}

.gcn .group-recipient-guest-name-modal-view .button:hover,
.gcn .group-recipient-guest-name-modal-view .button:active {
  opacity: 0.7;
}

.gcn .group-recipient-guest-name-modal-view .title {
  font-size: 28px;
  font-weight: 800;
  line-height: 1.3;
  padding: 16px;
  text-align: center;
}

.gcn .group-recipient-guest-name-modal-view .guest-name-input-container {
  width: 100%;
}

.gcn .group-recipient-guest-name-modal-view .guest-name-input-error {
  color: var(--cancel-red);
  font-size: 16px;
  text-align: center;
}

.gcn .group-order-prompt-view {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
}

.gcn .group-order-prompt-view .title {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.3;
  padding: 16px;
  text-align: center;
}

.gcn .group-order-prompt-view .subtitle {
  margin: 8px 0 20px;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  position: relative;
}

.gcn .group-order-prompt-view .image {
  max-height: 250px;
  height: auto;
  width: 100%;
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
  background-size: contain;
}

.gcn .group-order-prompt-view .button-container {
  display: flex;
  flex-direction: column;

  /* no chrome 56 alternatives to this */
  /* stylelint-disable-next-line */
  justify-content: space-evenly;
  width: 100%;
}

.gcn .group-order-prompt-view .button-container .button {
  border-radius: 6px;
  color: white;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  padding: 16px 32px;
  margin: 8px 0;
  text-align: center;
  width: 100%;
}

.gcn .group-order-prompt-view .button-container .button[data-value='yes'] {
  background-color: var(--ok-green);
}

.gcn .group-order-prompt-view .button-container .button[data-value='no'] {
  background-color: var(--cancel-red);
}

.gcn .group-recipient-view .title {
  font-family: var(--font-title);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.gcn .group-recipient-view {
  min-height: 60px;
  margin: 0 32px 24px;
  border-color: transparent;
}

.gcn .group-recipient-view.invalid {
  border: 2px solid var(--cancel-red);
  border-radius: 6px;
}

.gcn .group-guests-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: row;
}

.gcn .group-guests-container > * {
  margin-right: 8px;
}

@supports (gap: 8px) {
  .gcn .group-guests-container {
  gap: 8px;
    margin-right: 0;
  }
}


.gcn .item-order-view .group-guests-container .button.new-group-guest,
.gcn .group-guests-container .button.group-guest {
  justify-content: center;
  font-size: medium;
  background-color: var(--gray);
  color: black;
  border: solid 2px var(--selected-blue);
  border-width: 2px;
  min-width: 160px;
}

.gcn .group-guests-container .new-group-guest:hover,
.gcn .group-guests-container .new-group-guest:active,
.gcn .group-guests-container .button.group-guest:active,
.gcn .group-guests-container .button.group-guest:hover {
  opacity: 0.7;
}

.gcn .group-guests-container .button.group-guest.selected {
  background-color: var(--ok-green);
  border-color: var(--ok-darkgreen);
  color: white;
}

.gcn .group-recipient-guest-name-modal-view input {
  border-radius: 4px;
  color: black;
  display: block;
  font-size: 22px;
  height: 48px;
  margin: 16px auto 6px;
  padding: 4px;
  text-align: center;
  width: 100%;
}

.gcn .upsell-interstitial-view {
  height: 450px;
  padding: 30px 0 0;
  width: auto;

  /* Max width hard-coded to 786px */
}

@media (max-width: 600px) {
  .gcn .upsell-interstitial-view {
    padding: 16px 0 0;
    height: 620px;
  }
}

.gcn .upsell-interstitial-view .body {
  height: 320px;
}

.gcn .basic-react-popover {
  background-color: transparent;
  border: none;
}

.gcn .basic-react-popover .popover {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 10px;
  border-style: solid;
  overflow-y: scroll;
  box-shadow: 0 2px 6px rgba(0,0,0,0.50);
  opacity: 1;
  background-color: #FFFFFF;
}

.gcn .basic-react-popover .language-choice {
  border-radius: 6px;
  opacity: 1;
  min-width: 130px;
  height: 36px;
  padding: 4px;
  font-size: 18px;
  font-weight: 700;
  color: var(--plain-button-text-color);
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  border: none;
  display: flex;
  line-height: normal;
  align-items: center;
}

.gcn .basic-react-popover .language-choice .language-choice-pill {
  border-radius: 4px;
  opacity: 1;
  background-color: #D5DAE7;
  color: #303030;
  padding: 2px;
  align-self: flex-start;
  margin: auto 8px auto 0;
  min-width: 34px;
  text-align: center;
}

.gcn .basic-react-popover .language-choice.selected {
  border-radius: 6px;
  opacity: 1;
  background-color: var(--selected-blue);
  color: #FFFFFF;
}

.bite-embed .gcn .basic-react-popover .language-choice:hover {
  border-radius: 6px;
  opacity: 1;
  background-color: rgba(52,152,219,0.68);
}

.gcn .upsell-interstitial-view .bottom-bar {
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  position: absolute;
  width: 100%;
}

.gcn .upsell-interstitial-view .bottom-bar .button {
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 6px;
  color: black;
  font-size: 24px;
  margin: 16px;
  padding: 16px 32px;
  transition: var(--button-transition-values);
}

.gcn .upsell-interstitial-view .bottom-bar .button.active {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  color: white;
  font-weight: 500;
  text-decoration: none;
}

.gcn .upsell-interstitial-view .bottom-bar .button:active,
.bite-embed .gcn .upsell-interstitial-view .bottom-bar .button:hover {
  opacity: 0.7;
}

.gcn .upsell-interstitial-view .recommendations .header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;
}

.gcn .upsell-interstitial-view .recommendations .carousel-h-inner {
  padding-bottom: 0;
}

/* Pre-checkout recos to fit in popup (4 of them) */
.gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view {
  width: calc(25vw - 30px);
  height: 240px;
}

.gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view .image {
  height: 100px;
}

.gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view .card {
  min-height: 230px;
}

.gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view .title-line {
  width: 100%;
}

.gcn .popup .upsell-interstitial-view .recommendations .reco-container .carousel-h-inner .item-cell-view .title-line .price {
  display: table-footer-group;
}

.gcn .upsell-interstitial-view .recommendations .item-cell-view {
  /* TODO(steve): We can probably get around this if we fix the carousel heights
     to match it's container. */
  max-height: 273px;
}

.gcn .upsell-interstitial-view .recommendations .item-cell-view:first-child {
  margin-left: 24px;
}

@media (max-width: 600px) {
  .gcn .upsell-interstitial-view .recommendations .header {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .gcn .upsell-interstitial-view .recommendations .item-cell-view:first-child {
    margin-left: 32px;
  }

  .gcn .recommendations .item-cell-view {
    margin: 0 16px 0 0;
  }
}

.gcn .item-cell-view {
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
}

.gcn .item-cell-view.hidden-menu-item span.item-name {
  color: var(--disabled-lightgrey);
}

.gcn .recommendations .item-cell-view {
  margin: 12px 24px 24px 0;
}

.gcn .recommendations .item-cell-view:first-child {
  margin-left: 32px;
}

.gcn .recommendations .item-cell-view.not-selected {
  opacity: 0.35;
}

.gcn .customize-flow-step-view .recommendations .item-cell-view {
  margin: 12px 16px 24px;
}

.gcn .specials .item-cell-view {
  margin: 12px 12px 24px;
}

.gcn .page-view.side-nav-menu {
  padding-left: calc(var(--side-navigation-width-percent) + 1%);
}

.gcn .side-nav-view {
  position: fixed;
  left: 0;
  top: var(--top-nav-bar-height);
  flex-direction: column;
  height: calc(100% - var(--top-nav-bar-height));
  width: var(--side-navigation-width-percent);
  justify-content: flex-start;
  z-index: 2;
  padding: 20px 0 var(--bottom-bar-height);
  display: flex;
  background: #FFFFFF;
  overflow: scroll;

  /* Lateral Shadow/DER20 Shadow

  20px blur @20px facing RIGHT
  */
  box-shadow: 6px 0 20px rgba(0,0,0,0.2);
}

.gcn .side-nav-view.has-bottom-bar {
  height: calc(100% - (var(--bottom-bar-height) + var(--side-nav-scrolling-spacer)));
}

.gcn .side-nav-view.has-cart {
  height: calc(100% - (var(--cart-top-bar-height) + var(--bottom-bar-height) + var(--side-nav-scrolling-spacer)));
}

.gcn .side-nav-view .button-touch-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  margin: 0.625rem 1rem;
  border-radius: 8px;
  min-height: 57px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  /* stylelint-disable-next-line */
  align-self: stretch;
  flex-grow: 0;
  font-family: var(--font-body), Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0;
  text-align: left;
  transition: background-color 150ms linear;
  -webkit-transition: background-color 150ms linear;
  -ms-transition: background-color 150ms linear;
}

.gcn .side-nav-view .button-touch-area.selected {
  background: var(--color-primary);
  color: white;
  font-weight: 700;
  transition: background-color 200ms linear;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
}

.gcn .side-nav-view .button-touch-area .side-nav-button-image {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.30);
  width: 100px;
  height: 100px;
  margin-right: 16px;
  border-radius: 10px;
  object-fit: cover;
}


.gcn .page-view .section-view .item-cell-view {
  display: flex;
  margin: 24px 12px 0;

  /* Magic number for a 2 column grid with our current item cell padding and a fixed viewport width
     of 768pt across. This should be replaced with a css grids implementation. */
  width: 46.6%;
}

.gcn .page-view .section-view .item-cell-view.disabled {
  color: var(--disabled-lightgrey);
  box-shadow: none;
  border: 1px solid #999999;
}

.gcn .page-view .section-view .item-cell-view.disabled .description,
.gcn .page-view .section-view .item-cell-view.disabled .title-line .title {
  color: var(--disabled-lightgrey);
}

.gcn .page-view .section-view .item-cell-view.disabled .title-line .price {
  display: none;
}

.gcn .page-view .section-view .item-cell-view.wide {
  width: 100%;
}

@media (max-width: 600px), (min-width: 1200px) {
  .bite-embed:not(.menu-preview) .gcn .page-view .section-view .item-cell-view {
    margin: 8px 8px 0;
  }
}

.gcn .item-cell-view .header {
  display: none;
}

.gcn .section-view .item-cell-view.promoted {
  border: var(--reco-highlight-color) 4px solid;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  /* Item width compensation due to recommended items have
     a border that causes items to display as one column */
  width: calc(46.6% - 8px);
}

.gcn .section-view .item-cell-view.promoted .header {
  background-color: var(--reco-highlight-color);
  display: block;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 0;
  text-align: center;
}

@media (max-width: 600px), (min-width: 1200px) {
  .bite-embed:not(.menu-preview) .gcn .section-view .item-cell-view.promoted .header {
    border-radius: 10px;
    bottom: 4px;
    font-size: 14px;
    line-height: 1.2;
    padding: 2px 8px;
    position: absolute;
    right: 4px;
    z-index: 2px;
  }
}

.gcn .section-view .item-cell-view.promoted.wide .header {
  margin-left: -16px;
}

.gcn .item-cell-view .card {
  background-color: white;
  color: black;
  float: left;
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  min-height: 72px;
  cursor: pointer;
}

.gcn .item-cell-view .card.has-quantity-selector {
  padding-bottom: 72px;
}

.gcn .item-cell-view.wide .card {
  padding-left: 16px;
}

.gcn .item-cell-view.touchable {
  transition: transform 0.085s ease-in-out;
}

.bite-embed .gcn .item-cell-view.touchable:hover,
.gcn .item-cell-view.touchable.touched {
  transform: scale(0.98);
}

.bite-embed .gcn .item-cell-view.touchable .card:hover {
  cursor: pointer;
}

.bite-embed .gcn .item-cell-view.touchable .card:hover,
.gcn .item-cell-view.touchable .card:active,
.gcn .item-cell-view.touchable .card.touched {
  background-color: #DDDDDD;
}

.gcn .item-cell-view .image {
  background-color: #FFFFFF;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 200px;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
}

.gcn .item-cell-view .image.disabled {
  opacity: 0.5;
}

.gcn .item-cell-view .image.contain {
  background-size: contain;
}

.gcn .item-cell-view.wide .image {
  float: left;
  width: 350px;
  margin-right: 16px;
  margin-left: -16px;
}

@media (max-width: 600px), (min-width: 1200px) {
  .bite-embed:not(.menu-preview) .gcn .item-cell-view .image {
    background-size: cover;
  }

  .bite-embed:not(.menu-preview) .gcn .item-cell-view.wide .image {
    margin-right: initial;
  }
}

.gcn .item-cell-view .title-line {
  display: table;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  padding: 16px;
}

.gcn .item-cell-view.wide .title-line {
  padding-left: 0;
}

@media (max-width: 600px), (min-width: 1200px) {
  /* stylelint-disable-next-line plugin/browser-compat */
  .bite-embed:not(.menu-preview, .gcn-web) .gcn .section-view .item-cell-view.show-image:not(.wide) .card {
    display: flex;
  }

  /* stylelint-disable-next-line plugin/browser-compat */
  .bite-embed:not(.menu-preview, .gcn-web) .gcn .section-view .item-cell-view.show-image .image {
    width: 120px;
    height: 120px;
  }

  /* stylelint-disable-next-line plugin/browser-compat */
  .bite-embed:not(.menu-preview, .gcn-web) .gcn .section-view .item-cell-view .title-line {
    font-size: 18px;
    display: flex;
    flex: 1;
    padding: 12px;
  }

  /* stylelint-disable-next-line plugin/browser-compat */
  .bite-embed:not(.menu-preview, .gcn-web) .gcn .section-view .item-cell-view .title-line .title {
    flex: 1;
  }
}

.gcn .item-cell-view .title-line .title {
  color: #000000;
  display: table-cell;
  transition: font;
  transition-duration: 0.4s;
  width: 100%;
}

.gcn .item-cell-view .title-line .price {
  display: table-cell;
  padding-left: 10px;
  text-align: right;
  transition: font;
  transition-duration: 0.4s;
  font-weight: 600;
}

.gcn .item-cell-view .title-line .title .badges {
  float: right;
  margin-top: 0.25em;
}

.gcn .item-cell-view .title-line .title .badges span {
  vertical-align: middle;
  width: 0.85em;
  height: 0.85em;
  margin-left: 4px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.gcn .item-cell-view .false-button {
  background-color: white;
  border: solid 2px var(--selected-blue);
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.4);
  color: var(--selected-blue);
  font-size: 18px;
  padding: 6px 8px 2px;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 8px;
  vertical-align: middle;
}

.gcn .item-cell-view.selected .false-button {
  background-color: var(--selected-blue);
  color: white;
}

.gcn .item-cell-view .description {
  color: black;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.015em;
  line-height: 1.4em;
  padding: 0 16px 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  text-overflow: ellipsis;
  transition: font;
  transition-duration: 0.4s;
}

.gcn .item-cell-view.wide .description {
  padding-left: 0;
}

@media (max-width: 600px), (min-width: 1200px) {
  /* stylelint-disable-next-line plugin/browser-compat */
  .bite-embed:not(.menu-preview, .gcn-web) .gcn .item-cell-view .description:empty {
    display: none;
  }
}

.gcn .item-cell-view .description:empty {
  display: none;
}

.gcn .carousel-h-inner .item-cell-view {
  color: purple;
  display: inline-block;
  transition: opacity 0.4s;
  width: 320px;
}

@media (max-width: 600px) {
  .gcn .carousel-h-inner .item-cell-view {
    width: 240px;
  }
}

.gcn .carousel-h-inner .item-cell-view .title-line {
  bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.gcn .carousel-h-inner .item-cell-view .title-line .title {
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 56px;
  vertical-align: middle;
}

.gcn .carousel-h-inner .item-cell-view .title-line .price {
  vertical-align: middle;
}

.gcn .carousel-h-inner .item-cell-view .title-line .title .item-name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gcn .page-view {
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  max-width: inherit;
}

.gcn.scroll .page-view {
  overflow-y: scroll;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn.with-popup .page-view {
  pointer-events: none;
}

.gcn .page-view .background-container {
  position: relative;
  min-height: 100%;
  overflow: auto;
  padding-bottom: var(--bottom-bar-space);
}

.gcn.tall .page-view .background-container {
  padding:
    calc(0 - var(--scrolling-nav-bar-height))
    0
    calc(0 + var(--bottom-bar-space) + var(--scrolling-nav-bar-height));
}

.gcn .page-view .subpage-link {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 168px;
  margin: 24px;
  position: relative;
}

@media (max-width: 600px) {
  .gcn .page-view .subpage-link {
    min-height: 120px;
    margin: 12px;
  }
}

.bite-embed .gcn .page-view .subpage-link:hover {
  cursor: pointer;
}

.bite-embed .gcn .page-view .subpage-link:hover,
.gcn .page-view .subpage-link:active {
  background-color: #265500;
}

.gcn .page-view .subpage-link .scrim {
  background-color: rgba(0,0,0,0.2);
  border-radius: inherit;
  height: 100%;
  width: 100%;
}

.bite-embed .gcn .page-view .subpage-link:hover .scrim,
.gcn .page-view .subpage-link .scrim:active {
  background-color: rgba(0,0,0,0.4);
}

.gcn .page-view .subpage-link .scrim .name {
  bottom: 8px;
  color: #FFFFFF;
  font-size: 48px;
  left: 16px;
  position: absolute;
  text-shadow: rgba(0,0,0,0.7) 0 2px 4px;
}

@media (max-width: 600px) {
  .gcn .page-view .subpage-link .scrim .name {
    font-size: 34px;
  }
}

.gcn .section-view {
  background-color: transparent;
  width: 100%;
}

.gcn .page-view .center-align {
  text-align: center;
}

.gcn .page-view .quick-nav-view {
  position: relative;
  top: 0;
  z-index: 1;
  text-align: center;
  padding: 16px;
  background: white;
}

.gcn .page-view .quick-nav-view.sticky {
  position: fixed;
  margin: auto;
  width: 100%;
}

.gcn .page-view.touch-device .quick-nav-view.sticky,
.gcn .page-view .quick-nav-view.sticky {
  top: calc(var(--scrolling-nav-bar-height) + var(--top-nav-bar-height));
}

.gcn .page-view.touch-device .quick-nav-view.sticky.hybrid-navigation,
.gcn .page-view .quick-nav-view.sticky.hybrid-navigation {
  top: var(--top-nav-bar-height);
  width: var(--page-width-with-side-nav-percent);
}

.gcn .page-view .quick-nav-view.headless {
  display: none;
}

.gcn .page-view .quick-nav-view .caption {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  padding: 8px;
  text-transform: uppercase;
}

.gcn .page-view .quick-nav-view .button-row {
  display: inline-block;
}

.gcn .page-view .quick-nav-view .scroll-button-view {
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  font-weight: 500;
  background-color: white;
  border-style: solid;
  border-width: 4px;
  border-color: black;
  margin: 8px;
  height: 60px;
  min-width: 100px;
  padding: 0 16px;
  float: left;
  display: inline-block;
}

.gcn .page-view .quick-nav-view .scroll-button-view.double-line {
  line-height: 24px;
  padding-top: 1px;
  font-size: 23px;
  max-width: 220px;
}

@media (max-width: 600px) {
  .gcn .page-view .quick-nav-view .scroll-button-view {
    border-width: 2px;
    font-size: 16px;
    height: 42px;
    line-height: 36px;
    margin: 4px;
  }

  .gcn .page-view .quick-nav-view .scroll-button-view.double-line {
    line-height: 18px;
    padding-top: 0;
    font-size: 15px;
    max-width: 185px;
  }
}

.bite-embed .gcn .page-view .quick-nav-view .scroll-button-view:hover {
  cursor: pointer;
}

.bite-embed .gcn .page-view .quick-nav-view .scroll-button-view:hover,
.gcn .page-view .quick-nav-view .scroll-button-view:active {
  background-color: #DDDDDD;
}

.gcn .section-view .section-header {
  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 144px;
  position: relative;
  text-align: center;
  width: 100%;
}

@media (max-width: 600px) {
  .gcn .section-view .section-header {
    background-size: 100%;
    min-height: 80px;
  }
}

.gcn .section-view .section-header .scrim {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.45);
}

.gcn .section-view .section-header .name {
  /* Magic used to center this div. */
  display: inline-block;
  position: relative;

  /* TODO(steve): Vertically center this without a hardcoded value. */
  margin: 28px 10px;
  max-width: 70%;
  border-width: 4px;
  border-color: white;
  border-style: solid;
  color: white;
  font-size: 36px;
  line-height: normal;
  padding: 16px;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .gcn .section-view .section-header .name {
    font-size: 24px;
    border-width: 2px;
    max-width: 90%;
    margin: 8px;
  }
}

.gcn .section-view .section-description,
.gcn .section-view .section-footnote {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  transition: font;
  transition-duration: 0.4s;
}

.gcn .section-view .section-description {
  padding: 24px 48px 0;
}

.gcn .section-view .section-footnote {
  padding: 0 48px 24px;
}

.gcn .section-view .opt-out-button {
  text-align: center;
  text-decoration: underline;
  padding: 0 48px 24px;
}

@media (max-width: 600px) {
  .gcn .section-view .section-description,
  .gcn .section-view .section-footnote {
    padding: 24px 24px 0;
    font-size: 16px;
  }

  .gcn .section-view .filter {
    text-align: left;
    padding-left: 8px;
  }
}

.gcn .section-view .no-matching-items {
  padding: 20px;
  text-align: center;
  font-size: 24px;
  transition: font;
  transition-duration: 0.4s;
}

.gcn .item-cell-view .price-options {
  line-height: 1.3;
  font-size: 19px;
  font-weight: 500;
  padding: 8px 16px 16px;
  transition: font;
  transition-duration: 0.4s;
}

.gcn .item-cell-view .price-option {
  padding-right: 8px;
  display: block;
}

.gcn .item-cell-view.wide .price-options {
  padding-left: 0;
}

@media (max-width: 600px) {
  .gcn .item-cell-view .price-options {
    font-size: 16px;
    padding: 8px 12px 12px;
  }
}

.gcn .item-cell-view .price-options > span {
  display: inline-block;

  /* padding: 4px 10px 6px; */
}

.gcn .item-cell-view .price-options > span:not(:last-child) {
  margin-right: 16px;
  margin-bottom: 8px;
}

.gcn .section-view .column-container {
  padding-bottom: 30px;
}

@media (max-width: 600px) {
  .gcn .section-view .column-container {
    padding-bottom: 16px;
  }
}

.gcn .section-view .column {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  padding: 0;
  width: auto;
}

.gcn .section-view .divider {
  background-color: gray;
  display: none;
  height: 1px;
  margin: 0 auto;
  width: 50%;
}

.gcn .page-view .footnote {
  text-align: center;
  padding: 16px 40px 4px;
  font-size: 17px;
  transition: font;
  transition-duration: 0.4s;
  margin-bottom: 20px;
}

.gcn .page-view .footer-center {
  margin-bottom: calc(var(--scrolling-nav-bar-height) + var(--top-nav-bar-height));
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.gcn .page-view .footer-center .footer-link {
  color: #226FC9;
  display: inline-block;
  padding: 4px;
}

.bite-embed .gcn .page-view .footer-center .footer-link:hover,
.gcn .page-view .footer-center .footer-link:active {
  opacity: 0.7;
}

/**
 * Make sure to update .page-view .background-container bottom margin if
 * you change the height or margin of this logo.
 */
.gcn .page-view .bite-logo-container {
  display: block;
  height: 120px;
  text-align: center;
  position: relative;
}

.gcn .page-view .bite-logo-container .bite-logo {
  height: 100%;
  width: 200px;
  margin: 0 auto;
  background-size: 200px;
  background-image: url("https://assets.getbite.com/images-default/engineered-by-bite-orange.png");
  /* stylelint-disable plugin/browser-compat */
  background-image:
    image-set(
      /* stylelint-disable-next-line function-comma-newline-after */
      url("https://assets.getbite.com/images-default/engineered-by-bite-orange.png") 1x,
      url("https://assets.getbite.com/images-default/engineered-by-bite-orange@2x.png") 2x
    );
  /* stylelint-enable plugin/browser-compat */
  background-repeat: no-repeat;
  background-position: center;
}

.gcn .page-view .bite-logo-container .debug-panel {
  display: none;
  margin-top: 7px;
  color: var(--cancel-red);
  font-size: 14px;
  height: 100%;
}

.gcn .page-view .bite-logo-container .touch-calibration-controls {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  width: 220px;
  border: 1px solid black;
  text-align: left;
}

.gcn .page-view .bite-logo-container .touch-calibration-controls label,
.gcn .page-view .bite-logo .touch-calibration-controls input {
  display: block;
  width: 100%;
}

.gcn #touch-calibration-debug-window {
  width: 350px;
  height: 220px;
  position: fixed;
  top: 40%;
  right: 0;
  border: 1px solid white;
  z-index: 1000;
}

.gcn #touch-calibration-debug-window .log-pane {
  height: 200px;
  width: 100%;
  color: white;
  background-color: black;
  text-align: left;
  font-size: 10px;
  border-bottom: 1px solid white;
}

.gcn #touch-calibration-debug-window .close-button {
  height: 20px;
  margin: 0 auto;
  color: white;
  background-color: black;
  font-size: 10px;
  text-decoration: underline;
  text-align: center;
  z-index: 3;
}

@media (max-width: 600px) {
  .gcn .page-view .bite-logo-container {
    background-size: 160px;
    width: 160px;
  }
}

.gcn .item-details-view,
.gcn .item-customize-view {
  background-color: var(--gcn-popup-background-color);
  width: 100%;
  padding-bottom: 80px;
}

.gcn .popup .item-details-view {
  padding-bottom: 24px;
}

.gcn .touch-blocker-overlay.group-ordering {
  align-items: center;
  justify-content: center;
}

.gcn .touch-blocker-overlay.group-ordering .popup {
  max-width: 80%;
}

.gcn .item-details-view .item-image {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  position: relative;
  min-height: 56px;
}

.gcn .popup .item-details-view .item-image {
  min-height: 0;
}

.gcn .item-details-view .item-image.placeholder,
.gcn .item-details-view .item-image.assorted-container {
  height: 400px;
}

.gcn .item-details-view .item-image.assorted-container {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 50px;
  z-index: 2;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item {
  opacity: 0.8;
  position: relative;
  background-color: var(--assorted-image-placeholder);
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.active {
  opacity: 1;
  border: none;
  background-color: unset;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-3 {
  width: 135px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px 4px;
  border-radius: 135px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-3.active img {
  max-width: 135px;
  max-height: 135px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-4 {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 15px 30px;
  border-radius: 120px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-4.active img {
  max-width: 120px;
  max-height: 120px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-6 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 18px;
  border-radius: 100px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-6.active img {
  max-width: 100px;
  max-height: 100px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-8 {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px 4px;
  border-radius: 80px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-8.active img {
  max-width: 80px;
  max-height: 80px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-12 {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 15px;
  border-radius: 38px;
}

@media screen and (min-height: 1365px) {
  /* android */
  .gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-12 {
    margin: 12px 22px;
    border-radius: 34px;
  }

  .gcn .item-details-view .item-image.assorted-container {
    padding: 18px 78px;
  }
}

@media screen and (min-height: 1920px) {
  /* chromeOS & Tizen */
  .gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-12 {
    width: 90px;
    height: 90px;
    margin: 22px 44px;
    border-radius: 45px;
  }
}

.gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-12.active img {
  max-width: 80px;
  max-height: 80px;
}

@media screen and (min-height: 1920px) {
  .gcn .item-details-view .item-image.assorted-container .assorted-item.assorted-item-12.active img {
    max-width: 120px;
    max-height: 120px;
  }
}

.gcn .item-details-view .item-image.assorted-container .assorted-counter-container {
  position: absolute;
  bottom: 12px;
  display: flex;
  font-size: 18px;
}

.gcn .item-details-view .item-image.assorted-container .assorted-counter-container span {
  padding: 0 3px;
}

.gcn .item-details-view.assorted {
  height: 400px;
  z-index: 2;
}

.gcn .item-details-view.byo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: sticky;
  top: 0;
}

.gcn .item-details-view.byo .item-image {
  /* position: fixed; */
  background-color: white;
}

.gcn .item-details-view .margin {
  padding: 16px 32px 0;
}

@media (max-width: 600px) {
  .gcn .item-details-view .margin {
    margin: 8px 0 0;
    padding: 8px 16px 0;
  }

  .gcn .item-details-view .item-image.placeholder {
    height: 260px;
  }
}

.gcn .item-details-view .item-title-line {
  display: flex;
  font-size: 32px;
  transition: font;
  transition-duration: 0.4s;
}

@media (max-width: 600px) {
  .gcn .item-details-view .item-title-line {
    font-size: 24px;
  }
}

.gcn .popup .item-details-view .item-title-line.multi-button {
  display: block;
}

.gcn .item-details-view .item-title-line .name {
  flex: 1;
  width: 100%;
}

.gcn .item-details-view .item-title-line .price {
  padding-left: 16px;
  text-align: right;
}

.gcn .item-details-view .item-title-line .button-container {
  margin-left: 16px;
}

.gcn .item-details-view .item-title-line.multi-button .button-container {
  display: block;
  margin: 0;
  text-align: center;
}

.gcn .item-details-view .item-title-line .button-container .label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  text-align: center;
}

.gcn .item-details-view .item-title-line .price:empty {
  display: none;
}

.gcn .item-details-view > .item-description,
.gcn .item-details-view > .badges,
.gcn .item-details-view > .item-story {
  font-size: 18px;
  transition: font;
  transition-duration: 0.4s;
}

@media (max-width: 600px) {
  .gcn .item-details-view > .item-description,
  .gcn .item-details-view > .badges,
  .gcn .item-details-view > .item-story {
    font-size: 16px;
  }
}

.gcn .dict-word {
  background-color: rgba(103,144,55,0.4);
  border-radius: 4px;
}

.bite-embed .gcn .dict-word:hover {
  cursor: pointer;
}

.bite-embed .gcn .dict-word:hover,
.gcn .dict-word:active {
  opacity: 0.7;
}

.gcn .item-details-view > .badges span {
  vertical-align: middle;
  height: 20px;
  padding-left: 1.6em;
  margin-right: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.gcn .item-details-view .header {
  font-size: 20px;
  font-weight: bold;
  transition: font;
  transition-duration: 0.4s;
  margin-bottom: 12px;
}

@media (max-width: 600px) {
  .gcn .item-details-view .header {
    font-size: 16px;
  }
}

.gcn .popup .item-details-view .header {
  font-size: 18px;
}

.gcn .item-details-view > .price-options,
.gcn .item-details-view > .recommendations,
.gcn .item-details-view > .addon-set,
.gcn .item-customize-view > .recommendations {
  overflow: auto;
}

.gcn .item-details-view .recommendations,
.gcn .item-customize-view .recommendations {
  font-size: 24px;
  font-weight: 500;
  margin: 32px 0;
  padding: 0;
}

.gcn .item-details-view .recommendations .header,
.gcn .item-customize-view .recommendations .header {
  margin: 0 32px 8px;
}

.gcn .reco-details .customize-view .item-customize-view .header {
  margin: 0 0 8px;
}

.gcn .item-details-view .recommendations .reco-container {
  height: 300px;
  margin-top: -5px;
}

.gcn .item-customize-view .recommendations .reco-container .carousel-h-outer {
  overflow: visible;
}

.gcn .popup .item-details-view .recommendations .reco-container,
.gcn .customize-flow-view .recommendations .reco-container,
.gcn .item-customize-view .recommendations .reco-container {
  height: 250px;
}

.gcn .popup .item-details-view .recommendations .reco-container .item-cell-view,
.gcn .customize-flow-view .recommendations .reco-container .item-cell-view,
.gcn .item-customize-view .recommendations .reco-container .item-cell-view {
  width: 46%;
}

.gcn .popup .item-details-view .recommendations .reco-container .item-cell-view .image,
.gcn .customize-flow-view .recommendations .reco-container .item-cell-view .image,
.gcn .item-customize-view .recommendations .reco-container .item-cell-view .image {
  height: 160px;
}

.gcn .popup .item-details-view .recommendations .reco-container .item-cell-view .title-line,
.gcn .customize-flow-view .recommendations .reco-container .item-cell-view .title-line,
.gcn .item-customize-view .recommendations .reco-container .item-cell-view .title-line {
  font-size: 18px;
  height: 64px;
  padding: 8px 16px;
}

.gcn .popup .item-details-view .recommendations .reco-container .item-cell-view .title-line .title,
.gcn .customize-flow-view .recommendations .reco-container .item-cell-view .title-line .title,
.gcn .item-customize-view .recommendations .reco-container .item-cell-view .title-line .title {
  height: 45px;
}

.gcn .recommendations .reco-details {
  margin: 0 32px;
}

.gcn .recommendations .reco-details .item-customize-view {
  border-radius: 0 0 16px 16px;
  box-shadow: inset 0 0 20px rgba(0,0,0,0.35);
  padding: 16px 0;
}

.gcn .recommendations .reco-details .controls .button {
  float: right;
  padding: 12px;
  margin: 8px 0 0 8px;
}

.gcn .recommendations .reco-details .controls .button.done.disabled {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.bite-embed .gcn .recommendations .reco-details .controls .button:hover,
.gcn .recommendations .reco-details .controls .button:active {
  opacity: 0.7;
}

.gcn .recommendations .reco-details .controls .button.done {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
}

.gcn .recommendations .reco-details .controls .button.cancel {
  background-color: var(--cancel-red);
  border: 1px solid var(--cancel-darkred);
}

.gcn .recommendations-mini .header {
  color: white;
  font-size: 16px;
  padding: 4px 16px;
}

.gcn .recommendations-mini .items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 8px 16px;
}

.gcn .recommendations-mini .items .item-mini-view {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  font-size: 18px;
  width: 47%;
}

.gcn .recommendations-mini .items .item-mini-view:active {
  opacity: 0.7;
}

.gcn .recommendations-mini .items .item-mini-view.added {
  color: #4990E2;
}

.gcn .recommendations-mini .items .item-mini-view .name {
  padding: 0 8px;
}

.gcn .recommendations-mini .items .item-mini-view .image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  flex-shrink: 0;
  height: 46px;
  width: 46px;
}

.gcn .recommendations-mini .items .item-mini-view .image.contain {
  background-size: contain;
}

.gcn .recommendations-mini .items .item-mini-view.added .image {
  color: #4990E2;
}

.gcn .recommendations-mini .items .item-mini-view .check {
  background-color: rgba(74,144,226,0.85);
  border-radius: 100px;
  font-size: 32px;
  font-weight: 700;
  height: 100%;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, color 0.2s, all 0.2s ease-in-out;
  width: 100%;
}

.gcn .recommendations-mini .items .item-mini-view.added .check {
  opacity: 1;
  transform: scale(1);
}

.gcn .recommendations-mini .items .item-mini-view.added .check::after {
  color: white;
  content: "✓";
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-left: 6px;
  padding-top: 2px;
  position: absolute;
}

.gcn .item-details-view .price-options ul .gcn .item-details-view .addon-set ul {
  overflow: auto;
}

.gcn .item-details-view .price-options ul > div,
.gcn .item-details-view .addon-set ul > div {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  font-size: 20px;
  transition: font;
  transition-duration: 0.4s;
}

@media (max-width: 600px) {
  .gcn .item-details-view .price-options ul > div,
  .gcn .item-details-view .addon-set ul > div {
    font-size: 16px;
  }
}

.gcn .alert-view .price-corrections li {
  list-style-type: none;
}

.gcn .item-details-view .price-options li {
  list-style-type: none;
  display: table;
}

.bite-embed .gcn .item-details-view .price-options li:hover,
.bite-embed .gcn .item-details-view .price-options li input:hover,
.bite-embed .gcn .item-details-view .price-options li label:hover {
  cursor: pointer;
}

.gcn .item-details-view .addon-set li {
  margin-left: 24px;
}

.gcn .item-details-view .price-options input {
  display: table-cell;
  vertical-align: bottom;
  margin-left: 2px;
}

.gcn .item-details-view .price-options label {
  display: table-cell;
  padding-left: 8px;
}

.gcn .popup .item-details-view .price-options ul > div,
.gcn .popup .item-details-view .addon-set ul > div {
  font-size: 18px;
}

.gcn .item-details-view .nutri-section {
  margin-top: 16px;
  text-align: center;
}

.gcn .item-details-view .nutri-button {
  height: 48px;
  background-color: #326CB9;
  border-radius: 4px;
  border-bottom: solid rgab(0,0,0,0.2);
  color: rgba(255,255,255,0.8);
  font-size: 18px;
  line-height: 48px;
  margin-top: 16px;
  text-transform: uppercase;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

.bite-embed .gcn .item-details-view .nutri-button:hover,
.gcn .item-details-view .nutri-button:active {
  background-color: rgba(0,0,0,0.2);
  border-bottom: none;
  border-top: solid rgba(0,0,0,0.2);
}

.gcn .item-details-view div.nutri.margin {
  height: 350px;
  background-image: url("https://assets.getbite.com/images-default/nutrition-facts/nutritional-facts-2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 32px;
}

.gcn .item-details-view div.nutri.margin.fr {
  height: 350px;
  background-image: url("https://assets.getbite.com/images-default/nutrition-facts/nutritional-facts-fr.png");
}

.gcn .item-order-view {
  background-color: var(--gcn-popup-background-color);
  width: calc(100vw - 60px);
  height: calc(100vh - 200px);
}

.gcn .item-order-view.reco-order-view {
  width: 600px;
  min-height: 600px;
  height: unset;
}

@media screen and (max-height: 1024px) {
  /* iOS specific css to render a narrower item-order-view */
  .gcn .touch-blocker-overlay.menu-item-order-view.cart-shown .popup {
    /* top set to compensate for bottom bar and cart bar */
    top: 10px;
  }

  .gcn .item-order-view {
    width: calc(100vw - 200px);
    height: calc(100vh - 150px);
  }
}

.gcn .item-order-view .top-nav {
  background-color: #333333;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.25);
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.gcn .item-order-view .button {
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  margin: 8px;
  padding: 12px;
  text-align: center;
}

@media (min-height: 1600px) {
  .gcn .item-order-view .button {
    height: 56px;
    padding: 16px;
  }
}

.gcn .item-order-view .top-nav .title {
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding-top: 12px;
}

.gcn .item-order-view .contents {
  /* 64px is the height of the bottom nav with item total & Add button */
  height: auto;
  max-height: calc(100% - var(--item-order-view-bottom-nav));
  overflow: auto;
  overflow-x: hidden;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn .item-order-view .contents.reco-editor {
  min-height: 600px;
  max-height: calc(100% - var(--item-order-view-bottom-nav));
}

@media (max-width: 600px) {
  .gcn .item-order-view .contents {
    /* Allow smaller popups on mobile. */
    height: initial;
  }
}

.gcn .item-order-view .contents.show-mini-recos {
  padding-bottom: 100px;
}

.gcn .item-order-view.editing .item-customize-view {
  margin-top: 48px;
}

.gcn .item-order-view .bottom-nav {
  align-items: center;
  -ms-flex-align: center;
  background-color: var(--gcn-popup-background-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  height: var(--item-order-view-bottom-nav);
  padding-left: 16px;
  position: absolute;
  width: 100%;
}

@media (min-height: 1600px) {
  .gcn .item-order-view .bottom-nav {
    height: 72px;
  }
}

.gcn .item-order-view .bottom-nav .price-label {
  font-size: 20px;
  font-weight: 500;
}

.gcn .item-order-view .bottom-nav .button {
  text-transform: uppercase;
  transition: var(--button-transition-values);
  float: right;
  display: flex;
  align-items: center;
  max-width: 150px;
}

.gcn .item-cell-view .button.add {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  font-weight: 500;
  flex: 1 1 50%;
  margin: 0 16px 16px 8px;

}

.gcn .item-cell-view .buttons-right {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.gcn .item-cell-view .button.add.disabled,
.gcn .item-cell-view .button.add.disabled:active {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn .item-cell-view .quantity-selection-view {
  flex: 1 1 50%;
  border: 1px solid #A3A3A3;
  border-radius: 6px;
  margin: 0 8px 16px 16px;
}

.gcn .item-cell-view .quantity-selection-view .quantity {
  background-color: unset;
  flex: 1 1 auto;
  width: unset;
}

.gcn .item-cell-view .button {
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0 8px 8px;
  padding: 16px;
  text-align: center;
}

.gcn .item-order-view .bottom-nav .button.cancel {
  background-color: var(--cancel-red);
  border: 1px solid var(--cancel-darkred);
  margin-right: 0;
}

.gcn .item-order-view .bottom-nav .button.done {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  font-weight: 500;
}

.gcn .item-order-view .bottom-nav .button.done.disabled,
.gcn .item-order-view .bottom-nav .button.done.disabled:active {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.bite-embed .gcn .item-order-view .bottom-nav .button.done:hover,
.gcn .item-order-view .bottom-nav .button.done:active {
  opacity: 0.7;
}

.bite-embed .gcn .item-order-view .bottom-nav .button.done.disabled:hover,
.gcn .item-order-view .bottom-nav .button.done.disabled:active {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
  opacity: initial;
}

.gcn .item-order-view .bottom-nav .button.checkout {
  background-color: none;
  border: 1px solid var(--checkout-border);
  color: var(--checkout-border);
  font-weight: 500;
}

.gcn .item-order-view .bottom-nav .button.checkout.disabled,
.gcn .item-order-view .bottom-nav .button.checkout.disabled:active {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.bite-embed .gcn .item-order-view .bottom-nav .button.checkout:hover,
.gcn .item-order-view .bottom-nav .button.checkout:active {
  opacity: 0.7;
}

.bite-embed .gcn .item-order-view .bottom-nav .button.checkout.disabled:hover,
.gcn .item-order-view .bottom-nav .button.checkout.disabled:active {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
  opacity: initial;
}

.gcn .item-customize-view .item-header {
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  display: table;
  width: 100%;
}

.gcn .item-customize-view .item-header div {
  display: table-cell;
}

.gcn .item-customize-view .item-header div.item-image {
  background-color: #CCCCCC;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 120px;
  width: 210px;
}

.gcn .item-customize-view .item-header div.item-name {
  font-size: 32px;
  font-weight: 500;
  padding-left: 16px;
  vertical-align: middle;
}

.gcn .item-customize-view .price-option-picker,
.gcn .item-customize-view .addon-set {
  padding: 8px 32px;
}

.gcn .item-customize-view .price-option-picker .header .title,
.gcn .item-customize-view .addon-set .header .title {
  font-size: 24px;
  font-weight: 700;
}

.gcn .item-customize-view .price-option-picker .header .max-warning,
.gcn .item-customize-view .addon-set .header .max-warning {
  font-size: 16px;
  font-weight: 700;
}

.gcn .item-customize-view .addon-set .header .max-warning {
  margin-left: 24px;
  transition: color, font;
  transition-duration: 0.5s;
}

.gcn .item-customize-view .addon-set .header .max-warning.highlighted {
  color: var(--cancel-red);
  font-size: 20px;
}

.gcn .item-customize-view .addon-set .mod-group-description {
  font-size: 18px;
  padding-top: 4px;
}

@media (max-width: 600px) {
  .gcn .item-customize-view .item-header div.item-image {
    width: 160px;
  }

  .gcn .item-customize-view .price-option-picker,
  .gcn .item-customize-view .addon-set {
    padding: 8px 16px;
  }

  .gcn .item-customize-view .price-option-picker .header .title,
  .gcn .item-customize-view .addon-set .header .title {
    font-size: 18px;
  }

  .gcn .item-customize-view .addon-set .mod-group-description {
    font-size: 16px;
  }

  .gcn .item-customize-view .item-header div.item-name {
    font-size: 18px;
  }

  .gcn .item-customize-view .addon-set .header .max-warning {
    font-size: 14px;
    margin-left: 16px;
  }
}

/* Chrome 56 support */
.gcn .addon-set-picker-view .addons,
.gcn .price-option-picker-view .price-options {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  /* margin calculation: -8px - 6px 6px (default margin) = -14px */
  margin-left: -14px;

  /* margin calculation: -8px - 6px (default margin) = -14px + 16px (default top margin) = 2px */
  margin-top: 2px;
  margin-bottom: 16px;
  text-align: center;
}

.gcn .price-option-picker-view .price-options .price-option-button,
.gcn .addon-set-picker-view .addons .addon-item {
  align-items: center;
  -ms-flex-align: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  cursor: pointer;
  display: inline-flex;
  flex-flow: row wrap;
  font-size: 20px;
  padding: 24px 12px;
  text-align: left;
  transition: background-color 0.25s;
  word-break: break-word;
  margin-left: 14px;
  margin-top: 14px;
  width: calc(50% - 16px);
}

@supports (display: grid) {
  .gcn .addon-set-picker-view .addons,
  .gcn .price-option-picker-view .price-options {
    display: grid;
    flex-direction: row;
    grid-column-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 8px;
    flex-wrap: wrap;
    margin: 16px 0;
    text-align: center;
  }

  .gcn .price-option-picker-view .price-options .price-option-button,
  .gcn .addon-set-picker-view .addons .addon-item {
    align-items: center;
    -ms-flex-align: center;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
    cursor: pointer;
    display: inline-flex;
    flex-flow: row wrap;
    font-size: 20px;
    padding: 24px 12px;
    text-align: left;
    transition: background-color 0.25s;
    word-break: break-word;
    margin: 6px;
    width: auto;
  }
}

.gcn .addon-set-picker-view .link-button {
  margin: 8px 0;
}

.gcn .addon-set-pickers .link-button .deemphasize-button-image {
  height: 150px;
  margin: auto;
}

.gcn .addon-set-picker-view .addons .addon-item {
  padding: 0;
}

.gcn .addon-set-picker-view .addons .addon-item.promoted {
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  border: var(--reco-highlight-color) 4px solid;
  box-shadow: 0 2px 10px var(--reco-highlight-color);
  position: relative;
}

.gcn .addon-set-picker-view .addons .addon-item .content {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 24px 12px;
  flex: 1;
  width: 100%;
}

.gcn .addon-set-picker-view .addons .addon-item:not(.with-display-options) .content {
  height: 100%;
}

.gcn .addon-set-picker-view .addons .addon-item.promoted.selected {
  border: none;
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.gcn .addon-set-picker-view .addons .addon-item.promoted .header {
  background-color: var(--reco-highlight-color);
  border-color: var(--reco-highlight-color);
  border-width: 0;
  display: block;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.gcn .addon-set-picker-view .addons .addon-item.promoted.selected .header {
  background-color: transparent;
  border-bottom: 1px solid white;
}

.gcn .addon-set-picker-view .addons .addon-item.promoted.wide .header {
  margin-left: -16px;
}

.gcn .price-option-picker-view .price-options .price-option-button.disabled,
.gcn .addon-set-picker-view .addons .addon-item.disabled {
  /* Color below is our standard disabled state grey */
  color: var(--disabled-lightgrey);
  box-shadow: none;
  border: 1px solid #999999;
  justify-content: center;
  flex-direction: column;
}

.gcn .price-option-picker-view .price-options .price-option-button:active,
.gcn .addon-set-picker-view .addons .addon-item:active {
  box-shadow: inset 0 4px 4px 0 rgba(0,0,0,0.25);
}

.gcn .addon-set-picker-view .addons .addon-item .addon-details {
  flex: 1;
}

.gcn .price-option-picker-view .price-options .price-option-button .image.disabled,
.gcn .addon-set-picker-view .addons .addon-item .image.disabled {
  opacity: 0.5;
}

.gcn .out-of-stock-message,
.gcn .max-alcohol-in-cart-message,
.gcn .max-special-offer-in-cart-message {
  text-align: center;
  color: var(--disabled-lightgrey);
}

.gcn .addon-set-picker-view .addons .addon-item .line-break {
  flex-basis: 100%;
  width: 0;
}

.gcn .addon-set-picker-view .addons .addon-item .display-options {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  margin: 0 auto;
}

.gcn .addon-set-picker-view .addons .addon-item .display-options button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 6px;
  color: white;
  padding: 6px 4px;
  text-align: center;
  width: 80%;
  margin: 5px;
}

.gcn .addon-set-picker-view .addons .addon-item .display-options button.selected {
  background-color: white;
  border: none;
  color: var(--color-primary);
  font-weight: bold;
}

.gcn .price-option-picker-view .price-options .price-option-button .price-option-header,
.gcn .addon-set-picker-view .addons .addon-item .addon-details .addon-header {
  display: table;
  margin: auto;
}

.gcn .addon-set-picker-view .addons .addon-item .description {
  padding: 8px;
  display: block;
}

.gcn .price-option-picker-view .price-options .price-option-button .left-col,
.gcn .addon-set-picker-view .addons .addon-item .left-col {
  display: table-cell;
  width: 100%;
}

.gcn .price-option-picker-view .price-options .price-option-button .name,
.gcn .addon-set-picker-view .addons .addon-item .name {
  display: inline-block;
  padding-right: 8px;
  text-align: center;
}

.gcn .addon-set-picker-view .addons .addon-item .name .calories {
  font-size: 18px;
}

.gcn .price-option-picker-view .price-options .price-option-button .price,
.gcn .addon-set-picker-view .addons .addon-item .price {
  word-break: normal;
  display: table-cell;
  vertical-align: top;
}

.gcn .addon-set-picker-view .addons .addon-item .display-options .price {
  display: inline;
}

.gcn .addon-set-picker-view .addons .addon-item .image,
.gcn .price-option-picker-view .price-options .price-option-button .image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 100px;
  height: 48px;
  width: 48px;
  margin: 0 8px 0 0;
}

.gcn .addon-set-picker-view .addons .addon-item .image.no-image,
.gcn .price-option-picker-view .price-options .price-option-button .image.no-image {
  background-color: #FFFFFF;
  border: 2px solid #BBBBBB;
}

@media screen and (max-width: 600px) {
  .gcn .addon-set-picker-view .addons .addon-item .image.no-image,
  .gcn .price-option-picker-view .price-options .price-option-button .image.no-image {
    display: none;
  }
}

.gcn .addon-set-picker-view .addons .addon-item .image.no-image.disabled,
.gcn .price-option-picker-view .price-options .price-option-button .image.no-image.disabled {
  display: none;
}

.gcn .reco-editor .price-option-picker-view .price-options .price-option-button .image,
.gcn .reco-editor .addon-set-picker-view .addons .addon-item .image {
  display: none;
}

.gcn .price-option-picker-view .price-options .price-option-button.selected,
.gcn .addon-set-picker-view .addons .addon-item.selected {
  background-color: var(--selected-blue);
  color: white;
  box-shadow: inset 0 4px 4px 0 rgba(0,0,0,0.25);
}

.gcn .addon-set-picker-view .addons .addon-item.selected .image.no-image,
.gcn .price-option-picker-view .price-options .price-option-button.selected .image.no-image {
  border-color: #FFFFFF;
}

.gcn .addon-set-picker-view .addons .addon-item.selected .image::after,
.gcn .price-option-picker-view .price-options .price-option-button.selected .image::after {
  color: #FFFFFF;
  content: "✓";
  text-shadow: rgba(0,0,0,0.7) 0 2px 4px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 36px;
  padding-left: 6px;
  padding-top: 2px;
  vertical-align: super;
}

.gcn .addon-set-picker-view .addons .addon-item.selected .image.no-image::after,
.gcn .price-option-picker-view .price-options .price-option-button.selected .image.no-image::after {
  color: var(--selected-blue);
  text-shadow: none;
}

@media (max-width: 600px) {
  .gcn .addon-set-picker-view .addons,
  .gcn .price-option-picker-view .price-options {
    display: flex;
    justify-content: center;
  }

  .gcn .price-option-picker-view .price-options .price-option-button,
  .gcn .addon-set-picker-view .addons .addon-item {
    font-size: 16px;
  }

  .gcn .addon-set-picker-view .addons .addon-item {
    padding: 12px 10px;
  }

  .gcn .addon-set-picker-view .addons .addon-item .image,
  .gcn .price-option-picker-view .price-options .price-option-button.selected .image {
    margin-right: 6px;
    height: 36px;
    width: 36px;
  }

  .gcn .addon-set-picker-view .addons .addon-item.selected .image::after,
  .gcn .price-option-picker-view .price-options .price-option-button.selected .image::after {
    font-size: 24px;
  }
}

.gcn .item-customize-view .combo-builder-button-container {
  margin-top: 16px;
}

.gcn .item-customize-view .combo-builder-button-container .combo-builder-button {
  color: white;
  cursor: pointer;
  background-color: var(--color-primary);
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  font-size: 24px;
  font-weight: bold;
  margin: 8px 30px;
  padding: 8px 32px;
  text-align: center;
}

.gcn .ordered-item-view .item-card,
.gcn .reward-view {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  cursor: pointer;
  display: flex;
  margin: 16px 16px 0;
}

.gcn .ordered-item-view.editable {
  transition: transform 0.085s ease-in-out;
}

.bite-embed .gcn .ordered-item-view.editable:hover,
.gcn .ordered-item-view.editable:active {
  transform: scale(0.97);
}

.gcn .scrolling-disable-transitions .ordered-item-view.editable,
.gcn .scrolling-disable-transitions .item-cell-view.touchable {
  transition: none;
  transform: initial;
}

.gcn .ordered-item-view .item-card .item-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  flex: 2;
  height: 160px;
  width: 240px;
}

.gcn .ordered-item-view .item-card .item-image.contain {
  background-size: contain;
}

/* Placeholder images. */
.gcn .item-image.food {
  background-image: url("https://assets.getbite.com/images-default/food-placeholder.png");
}

.gcn .item-image.alc-beverage {
  background-image: url("https://assets.getbite.com/images-default/alc-bev-placeholder.png");
}

.gcn .item-image.non-alc-beverage {
  background-image: url("https://assets.getbite.com/images-default/non-alc-placeholder.png");
}

.gcn .item-image.other {
  background-color: #CCCCCC;
}

.gcn .ordered-item-view .details-card {
  background-color: white;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin: 0 16px;
  max-height: 160px;
  overflow-y: scroll;
  padding: 24px;
  vertical-align: middle;
  width: 480px;
}

.gcn .ordered-item-view .details-card > div:not(:last-child) {
  margin-bottom: 10px;
}

.gcn .ordered-item-view .item-card .item-details {
  flex: 2;
  padding: 16px;
  vertical-align: middle;
  display: flex;
}

.gcn .ordered-item-view .item-card .item-details .item-name {
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
}

.gcn .ordered-item-view .item-card .item-details .item-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: var(--color-kiosk-order-item-quantity);
  margin-right: 8px;
  width: 28px;
  height: 28px;
  margin-top: 2px;
  font-weight: bold;
}

.gcn .ordered-item-view .item-card .item-details .item-options {
  font-size: 20px;
}

.gcn .ordered-item-view .item-card .item-details .item-options .addon {
  color: #666666;
}

.gcn .ordered-item-view .item-card .item-details .price {
  font-size: 22px;
  margin-top: 8px;
}

.gcn .ordered-item-view .item-card .close-cell {
  margin-left: auto;
}

.gcn .ordered-item-view .item-card .close-cell .close-button {
  color: darkgrey;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  width: 48px;
}

.bite-embed .gcn .ordered-item-view .item-card .close-cell .close-button:hover,
.gcn .ordered-item-view .item-card .close-cell .close-button:active {
  background-color: var(--cancel-red);
  color: white;
}

.gcn .customize-flow-view .preview-container .ordered-item-view .item-card .close-cell .close-button {
  position: absolute;
  top: -24px;
  right: 0;
}

@media (max-width: 600px) {
  .gcn .ordered-item-view .item-card .item-details {
    padding: 16px;
  }

  .gcn .customize-flow-view .preview-container .ordered-item-view .item-card .close-cell .close-button {
    /* Makes space for scrollbar */
    right: 14px;
  }

  .gcn .ordered-item-view .item-card .item-details .item-name {
    font-size: 20px;
  }

  .gcn .ordered-item-view .item-card .item-details .item-options {
    font-size: 14px;
  }

  .gcn .ordered-item-view .item-card .item-details .price {
    font-size: 20px;
  }

  .gcn .ordered-item-view .details-card {
    padding: 16px;
  }
}

.gcn .special-request-view,
.gcn .item-recipient-view {
  margin: 32px;
  text-align: center;
}

.gcn .special-request-view label,
.gcn .item-recipient-view label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.gcn .special-request-view textarea,
.gcn .item-recipient-view textarea {
  display: inline-block;
  font-size: 20px;
  width: 320px;
  height: 72px;
  border: 1px solid #999999;
}

.gcn .marketing-consent-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.gcn .marketing-consent-view .marketing-consent-label {
  color: #666666;
  font-size: 14px;
  margin: 4px;
}

.gcn .marketing-consent-view .marketing-consent-input {
  height: 20px;
  width: 20px;
}

.gcn .special-request-view .submit-button {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-weight: 500;
  margin-top: 4px;
  min-width: 140px;
  padding: 8px;
  text-transform: uppercase;
  transition: var(--button-transition-values);
}

.bite-embed .gcn .special-request-view .submit-button:hover,
.gcn .special-request-view .submit-button:active {
  opacity: 0.7;
}

.gcn .special-request-view .submit-button.disabled {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.bite-embed .gcn .special-request-view .submit-button.disabled:hover,
.gcn .special-request-view .submit-button.disabled:active {
  opacity: initial;
}

.gcn .special-request-view .fine-print {
  padding-top: 8px;
}

.gcn .scrolling-nav-view {
  background-color: #F6F6F6;
  color: white;
  font-size: 30px;
  height: var(--scrolling-nav-bar-height);
  line-height: 50px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
  top: var(--top-nav-bar-height);
  white-space: nowrap;
  z-index: 1;
  max-width: inherit;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.3);
}

.gcn.tall .scrolling-nav-view {
  bottom: 0;
  top: initial;
  transition: height 400ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 -2px 2px 0 rgba(0,0,0,0.2), 0 -6px 10px 0 rgba(0,0,0,0.3);
}

.gcn.tall .scrolling-nav-view.extended {
  height: calc(var(--scrolling-nav-bar-height) + var(--bottom-bar-height));
}

.gcn .has-menu-filters-header {
  height: calc(var(--scrolling-nav-bar-height) + var(--menu-filters-container-height));
}

@media (max-width: 600px) {
  .gcn .scrolling-nav-view {
    font-size: 24px;
  }
}

.gcn.scroll .scrolling-nav-view {
  overflow-x: scroll;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn.with-popup .scrolling-nav-view {
  pointer-events: none;
}

.gcn .scrolling-nav-view .carousel-h-outer {
  text-align: center;
  height: var(--scrolling-nav-bar-height);
}

.gcn.uses-2-row-nav-bar .scrolling-nav-view .carousel-h-inner {
  white-space: normal;
}

.gcn .scrolling-nav-view .button-touch-area {
  display: inline-block;
  height: var(--scrolling-nav-row-height);
  line-height: 72px;
  margin-left: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 600px) {
  .gcn .scrolling-nav-view .button-touch-area {
    display: inline-block;
    line-height: 56px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.bite-embed .gcn .scrolling-nav-view .button-touch-area:hover {
  cursor: pointer;
}

.bite-embed .gcn .scrolling-nav-view .button-touch-area:hover,
.gcn .scrolling-nav-view .button-touch-area:active {
  background-color: rgba(0,0,0,0.1);
}

.gcn .scrolling-nav-view .button-touch-area:first-of-type {
  margin-left: 16px;
}

.gcn .scrolling-nav-view .button-touch-area:last-of-type {
  margin-right: 16px;
}

.gcn .scrolling-nav-view .button-actual {
  color: #8F8F8F;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  text-transform: uppercase;
}

.gcn .scrolling-nav-view .button-actual.has-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 160px;
}

.gcn.tall .scrolling-nav-view.extended .button-actual.has-image {
  background-position-y: 8px;
}

.gcn .scrolling-nav-view .button-actual.has-image:not(.selected) {
  opacity: 0.6;
}

.gcn .checkout-flow-view .scrolling-nav-view .button-actual.has-image {
  opacity: 1;
}

.gcn .scrolling-nav-view .button-actual.selected {
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.gcn.tall .scrolling-nav-view .button-actual.selected {
  border-bottom-width: 0;
  border-bottom-style: none;
  border-top-width: 6px;
  border-top-style: solid;
}

@media (max-width: 600px) {
  .gcn .scrolling-nav-view .button-actual.selected {
    border-bottom-width: 4px;
  }
}

.gcn .scrolling-nav-view .carousel-h-inner .more-indicator {
  color: #666666;
  background-color: white;
  border-radius: 100px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  height: var(--scrolling-nav-more-indicator-size);
  line-height: var(--scrolling-nav-more-indicator-size);
  position: absolute;
  bottom: calc(var(--scrolling-nav-bar-height) / 2 - var(--scrolling-nav-more-indicator-size) / 2);
  width: var(--scrolling-nav-more-indicator-size);
  opacity: 0.9;
  z-index: 10;
}

.gcn .scrolling-nav-view .carousel-h-inner .more-indicator.right {
  right: 16px;
}

.gcn .scrolling-nav-view .carousel-h-inner .more-indicator.left {
  left: 16px;
}

.bite-embed .gcn .scrolling-nav-view .carousel-h-inner .more-indicator:hover,
.gcn .scrolling-nav-view .carousel-h-inner .more-indicator:active {
  background-color: #DDDDDD;
  cursor: pointer;
  opacity: 0.6;
}

/* Enable this in full screen flow. */
.gcn .order-summary .scrolling-nav-view {
  box-shadow: none;
  pointer-events: initial;
  position: relative;
  top: initial;
}

.gcn .top-nav-view {
  color: white;
  height: var(--top-nav-bar-height);
  line-height: var(--top-nav-bar-height);
  text-align: center;
  z-index: 3;
  position: fixed;
  top: 0;
  max-width: inherit;
  width: 100%;
}

.gcn .top-nav-view.has-side-nav {
  border-bottom: 1px solid #999999;
}

.gcn.tall .top-nav-view {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.3);
}

@media (max-width: 600px) {
  .gcn .top-nav-view {
    --top-nav-bar-height: 48px;
  }
}

.gcn.with-popup .top-nav-view {
  pointer-events: none;
}

.gcn .top-nav-view .container {
  /**
   * Set the height to 101% to conceal a narrow sub 1px gap between the top-nav-view and the
   * scrolling-nav-view that occurs because we set the viewport size to be smaller than the
   * actual pixel size of the screen and that causes artifacting.
   */
  height: 101%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  transition: opacity;
  transition-delay: 0s;
  transition-duration: 0.6s;
  width: 100%;
  z-index: -1;
}

.gcn .top-nav-view .button-container {
  height: 56px;

  /* padding: 4px; */
  position: absolute;
}

.gcn .top-nav-view .button-container.home {
  left: 0;
  top: 0;
}

.gcn .top-nav-view .button-container.settings {
  right: 0;
  top: 0;
}

.gcn .top-nav-view .button {
  height: 48px;
  background-color: #679037;
  border-radius: 4px;
  border-bottom: solid rgba(0,0,0,0.2);
  color: rgba(255,255,255,0.8);
  display: inline-block;
  font-size: 18px;
  line-height: 48px;
  margin: 4px;
  text-transform: uppercase;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0;
  z-index: 4;
}

@media (max-width: 600px) {
  .gcn .top-nav-view .button {
    border-width: 2px;
    font-size: 16px;
    height: 44px;
    line-height: 44px;
    margin: 2px;
  }
}

.gcn .top-nav-view .button.settings {
  background-image: url("https://assets.getbite.com/images-default/icon-profile-green@2x.png");
  background-color: transparent;
  background-position: 98% 4px;
  background-repeat: no-repeat;
  border: none;
  background-size: 40px;
  padding-right: calc(40px + 6px);
  color: var(--color-primary);
  text-transform: none;
  font-size: 18px;
  letter-spacing: 0;
  margin: 4px 2px;
  padding-left: 1em;
}

.gcn .top-nav-view .button.settings.signup {
  background: none;
  padding-right: 18px;
}

.gcn .top-nav-view .button.settings .account-popover-item {
  font-weight: normal;
}

.gcn .top-nav-view .button.settings .account-popover-item::part(native):hover { /* stylelint-disable-line plugin/browser-compat */
  background-color: rgba(0,148,122,0.5); /* Need a new color */
}

@media (max-width: 600px) {
  .gcn .top-nav-view .button.settings {
    padding-left: 0;
    padding-right: 48px;
    width: max-content;
  }
}

.bite-embed .gcn .top-nav-view .button:hover {
  cursor: pointer;
}

.gcn .top-nav-view .button:active {
  background-color: rgba(0,0,0,0.2);
  border-bottom: none;
  border-top: solid rgba(0,0,0,0.2);
}

.gcn .top-nav-view .title-container {
  display: inline-block;
  text-align: left;
  position: relative;
}

.gcn .top-nav-view .title {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.gcn .touch-blocker-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 35;
  pointer-events: all;
  background-color: rgba(0,0,0,0.45);
  opacity: 1;
  width: 100%;
  height: 100%;
}

.gcn .touch-blocker-overlay.animate,
.gcn .touch-blocker-overlay.modal,
.gcn .touch-blocker-overlay.spinner {
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
}

.gcn .touch-blocker-overlay.dining-option.modal {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 40;
}

.gcn .touch-blocker-overlay.generic.modal {
  z-index: 100;
}

.gcn .touch-blocker-overlay.dining-option.modal .popup {
  border-radius: 20px;
}

.gcn .touch-blocker-overlay.dining-option.modal .popup.showing-title {
  position: relative;
  top: 8%;
}

.gcn .touch-blocker-overlay.centered {
  align-items: center;
  justify-content: center;
}

.gcn .touch-blocker-overlay.recommended-item {
  align-items: center;
  justify-content: center;
}

.gcn .touch-blocker-overlay.dining-option.modal .overlay-logo {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 32px;
  color: #FFFFFF;
  min-height: 100px;
  max-width: 720px;
  min-width: 30%;
  position: absolute;
  top: 8%;
  text-align: center;
}

@media (max-width: 600px) {
  .gcn .touch-blocker-overlay.dining-option.modal .overlay-logo {
    min-width: 200px;
    max-width: 300px;
    height: 60px;
    max-height: 80px;
  }

  .gcn .touch-blocker-overlay.dining-option.modal .fulfillment-method-picker-view .list-item-button {
    margin: 10px auto;
  }
}

.gcn .touch-blocker-overlay.spinner {
  background-color: transparent;
  z-index: 45;
}

.gcn .touch-blocker-overlay.pane {
  z-index: 19;  /* one below the bottom pane for the cart */
}

.gcn .touch-blocker-overlay .popup {
  border-radius: 20px;
  background-color: var(--gcn-popup-background-color);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.3);
  opacity: 1;
  position: absolute;
}

.gcn .toast-container {
  bottom: 45%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 35;
}

.gcn .touch-blocker-overlay .popup .popup-inner {
  background: var(--gcn-popup-background-color);
  border-radius: inherit;
  overflow-y: auto;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 600px) {
  .gcn .touch-blocker-overlay .popup .popup-inner {
    max-height: inherit;
  }
}

.bite-embed .gcn .touch-blocker-overlay .popup .popup-inner::-webkit-scrollbar {
  display: none;
  width: 0;
}

/*
  this magically fixes rounded corners on scrolling popups, but this mask must
  be applied after the content was resized. Otherwise, on iOS 10, the content
  might (quite often, actually) appear blank, which is not very nice.
*/
.gcn .touch-blocker-overlay .popup .popup-inner.masked {
  mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}

.gcn .touch-blocker-overlay .close-label {
  background-color: rgba(0,0,0,0.55);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  font-size: 18px;
  padding: 8px 16px;
  pointer-events: none;
  position: absolute;
}

.gcn .touch-blocker-overlay .close-button {
  background-color: rgba(0,0,0,0.4);
  border-radius: 100px;
  color: rgba(255,255,255,0.8);
  font-size: 28px;
  padding: 0 14px 4px;
  position: absolute;
  margin: 4px 6px;
  right: 0;
  top: 0;
  z-index: 3;
}

.bite-embed .gcn .touch-blocker-overlay .close-button:hover,
.gcn .touch-blocker-overlay .close-button:active {
  background-color: rgba(0,0,0,0.65);
  cursor: pointer;
}

.gcn .touch-blocker-overlay.ada-instruction-modal {
  align-items: center;
  justify-content: center;
  background: none;
}

.gcn .touch-blocker-overlay.ada-instruction-modal .popup,
.gcn .touch-blocker-overlay.ada-instruction-modal .popup .popup-inner {
  border-radius: 20px;
  background-color: transparent;
  overflow: hidden;
}

@media (max-height: 680px) {
  .gcn .touch-blocker-overlay.ada-instruction-modal .popup,
  .gcn .touch-blocker-overlay.ada-instruction-modal .popup .popup-inner {
    overflow: scroll;
  }

  .gcn .touch-blocker-overlay.ada-instruction-modal .popup {
    transform: scale(0.7);
  }
}

.gcn .touch-blocker-overlay.ada-instruction-modal .popup .ada-instructions-view {
  padding: 0 10px;
  background-color: rgba(0,0,0,0.75);
  opacity: 1;
  color: white;
  font-size: 30px;
}

.gcn .touch-blocker-overlay.ada-instruction-modal .ada-instructions-view .instruction-image {
  height: 100px;
  width: 100px;
}

.gcn .touch-blocker-overlay.ada-instruction-modal .instruction-text .bottom {
  padding-bottom: 0;
}

.ada-instructions-view ion-row {
  justify-content: center;
}

.gcn .spinner-overlay {
  background-color: rgba(0,0,0,0.75) !important;
  border-radius: 8px;
  height: 240px;
  padding: 30px 24px 24px;
  position: absolute;
  width: 240px !important;
}

.gcn .spinner-overlay .spinner-image {
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.gcn .spinner-overlay .spinner-image.checkmark {
  margin: 20px auto 30px;
  height: 100px;
  width: 100px;
  background-image: url("https://assets.getbite.com/images-default/toast-checkmark@2x.png");
}

.gcn .spinner-overlay .message {
  color: white;
  font-size: 22px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
}

.gcn .full-screen-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 25;
  pointer-events: all;
  background-color: white;
  opacity: 1;
  transition: opacity 0.3s linear;
}

/* TODO: Share with all full-screen-flow-views */
.gcn .customize-flow-view,
.gcn .checkout-flow-view {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  pointer-events: all;
  transition: background-image 0.6s ease-in-out;
  transition-delay: 0.6s;
  width: 100%;
}

/* TODO: Share with all full-screen-flow-views */
.gcn .customize-flow-view .contents,
.gcn .checkout-flow-view .contents {
  transition: 0.6s ease-in-out;
}

/* TODO: Share with all full-screen-flow-views */
.gcn .customize-flow-view .customize-flow-step-view,
.gcn .full-screen-flow-step-view {
  height: 100vh;
  position: relative;
  width: 100%;
  padding-bottom: var(--bottom-bar-space);
}

.gcn .customize-flow-view .step-panel,
.gcn .full-screen-flow-step-view .step-panel {
  --step-panel-margin: 24px;

  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 var(--step-panel-margin);
  max-height: 70%;
  min-height: 600px;
  position: absolute;
  border-radius: 16px;
  bottom: var(--bottom-bar-space);
  width: calc(100% - 2 * var(--step-panel-margin));
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.3), 0 11px 17px 0 rgba(0,0,0,0.19);
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view .step-panel {
    margin: 0;
    min-height: initial;
    max-height: initial;
    width: 100%;
    border-radius: 0;
    bottom: 0;
  }
}

.gcn .customize-flow-step-view.confirmation.with-recos .step-panel {
  min-height: 680px;
}

.gcn .full-screen-flow-step-view .step-panel {
  max-height: initial;
}

.gcn .item-order-view .recos {
  background-color: var(--gcn-reco-background-color);
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  left: 8px;
  overflow: hidden;
  position: absolute;
  transition:
    height 0.4s,
    width 0.4s,
    bottom 0.4s,
    left 0.4s,
    border-radius 0.4s;
  transition-timing-function: ease-in-out;
  width: calc(100% - 8 * 2px);

  /* Is this necessary? */

  /* z-index: 2; */
}

.gcn .item-order-view .recos.expanded {
  width: 100%;
  height: 340px;
  border-radius: 0;
  box-shadow:
    0 -4px 10px 0 rgba(0,0,0,0.16),
    0 -4px 0 0 rgba(0,0,0,0.09);
  bottom: 64px;
  left: 0;
}

.gcn .item-order-view .recos.expanded .recommendations {
  position: absolute;
  top: 0;
}

.gcn .item-order-view .recos.expanded .recommendations .header {
  margin-left: 16px;
  margin-top: 16px;
  font-size: 24px;
  font-weight: 500;
}

.gcn .customize-flow-step-view .step-panel .scroll-indicator,
.gcn .item-order-view .scroll-indicator {
  background-color: #2386EF;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  bottom: 0;
  color: white;
  cursor: pointer;
  height: 70px;
  opacity: 0.7;
  position: absolute;
  width: 140px;
  z-index: 3;
}

.gcn .customize-flow-step-view .step-panel .scroll-indicator .more-text,
.gcn .item-order-view .scroll-indicator .more-text {
  font-size: 18px;
  font-weight: 700;
  padding-top: 10px;
  text-align: center;
}

.gcn .customize-flow-step-view .step-panel .scroll-indicator .arrow-icon,
.gcn .item-order-view .scroll-indicator .arrow-icon {
  font-size: 48px;
  font-weight: 700;
  margin: -30px;
  text-align: center;
}

@supports not (display: grid) {
  /* Downward arrow caret shows up as tofu on Tizen (likely due to unicode characters
     not rendering properly) so it's being hidden on Chrome 56 devices for now */

  .gcn .customize-flow-step-view .step-panel .scroll-indicator .arrow-icon,
  .gcn .item-order-view .scroll-indicator .arrow-icon {
    display: none;
  }

  .gcn .customize-flow-step-view .step-panel .scroll-indicator .more-text,
  .gcn .item-order-view .scroll-indicator .more-text {
    font-size: 24px;
    padding-top: 22px;
  }
}

.gcn .item-order-view .warning-toast {
  opacity: 0;
  z-index: 10;
  background-color: #E84439;
  border: 1px #9C2E26 solid;
  border-radius: 20px;
  padding: 12px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  position: absolute;
  left: 10%;
  width: 80%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.gcn .customize-flow-step-view .step-panel .warning-toast {
  opacity: 0;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  min-height: 40px;
  width: 80%;
  background-color: #E84439;
  border: 1px #9C2E26 solid;
  border-radius: 20px;
  bottom: 20%;
  margin: auto;
  left: 10%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar {
  padding: 8px;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar .button {
  padding: 10px;
  min-width: 100px;
  text-align: center;
  height: initial;
  display: block;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar .button.checkout {
  border: solid 1px var(--checkout-border);
  color: var(--checkout-border);
  background-color: white;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar .button.next {
  font-weight: bold;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar .quantity-selection-view {
  margin: auto;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar .quantity-selection-view .quantity-selector-label {
  font-size: 24px;
  margin-right: 5px;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar .quantity-selection-view .quantity {
  font-size: 27px;
  height: 40px;
  width: 40px;
  line-height: 38px;
}

.gcn .full-screen-overlay .customize-flow-view .customize-flow-step-view .step-panel .button-bar .quantity-selection-view .adjust-button {
  font-size: 45px;
  height: 40px;
  width: 51px;
  line-height: 32px;
}

.gcn .customize-flow-view .step-panel.with-bottom-bar {
  bottom: 114px;
}

.gcn .full-screen-flow-step-view .step-panel.with-bottom-bar {
  bottom: 114px;
  top: 32px;
}

.gcn .customize-flow-view .preview-container {
  position: absolute;
  text-align: center;
  top: 24px;
  width: 100%;
}

.gcn .customize-flow-view .preview-container .ordered-item-view {
  display: inline-block;
  transition-timing-function: ease-in-out;
}

.gcn .customize-flow-view .preview-container .ordered-item-view .item-card {
  width: 480px;
  height: 160px;
}

.gcn .customize-flow-view .preview-container .ordered-item-view .item-card .item-details {
  overflow-y: scroll;
  text-align: initial;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 600px) {
  .gcn .customize-flow-view .step-panel {
    bottom: 12px;
    margin: 0 12px;
    max-height: 560px;
    min-height: 460px;
    width: calc(100% - 12 * 2px);
  }

  .gcn .customize-flow-view .step-panel .button-bar {
    padding: 8px;
  }

  .gcn .price-option-picker-view .price-options .price-option-button,
  .gcn .addon-set-picker-view .addons .addon-item {
    margin: 0 0 6px;
  }

  .gcn .customize-flow-step-view .step-body {
    padding: 12px;
  }
}

.gcn .customize-flow-view .button-bar,
.gcn .checkout-flow-view .button-bar {
  background-color: white;
  display: flex;
  justify-content: space-between;
  min-height: fit-content;
  padding: 0 64px;
  width: 100%;
  box-shadow: 0 -4px 4px 0 rgba(0,0,0,0.2);
  z-index: 1;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.gcn .checkout-flow-view .button-bar {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  text-align: center;
}

.gcn .checkout-flow-view .payment-completed .button-bar {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  justify-content: center;
  padding: 10px 16px 0;
}

.gcn .full-screen-flow-step-view .payment-completed .refresh-button,
.gcn .checkout-flow-view .payment-completed .refresh-button {
  background-color: var(--ok-green);
  border: 1px solid var(--plain-button-border-color);
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 8px;
  text-align: center;
  width: 40%;
  margin: 8px auto 36px;
}

.gcn .checkout-flow-view .button-bar.showing-receipt-options > .button {
  display: block;
  height: 74px;
}

.gcn .checkout-flow-view .button {
  display: inline-block;
}

.gcn .checkout-flow-view .button-bar .receipt-buttons-container {
  display: flex;
}

.gcn .checkout-flow-view .button-bar.showing-guest-survey .receipt-buttons-container {
  display: block;
  margin-top: -4px;
}

.gcn .checkout-flow-view .button-bar .receipt-buttons-container .button.receipt-option {
  align-items: center;
  -ms-flex-align: center;
  background-color: white;
  border: var(--plain-button-border-color) 1px solid;
  color: var(--plain-button-text-color);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 8px 24px;
}

.gcn .checkout-flow-view .button-bar .receipt-buttons-container .button.receipt-option .icon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 32px;
  margin: 0 auto 2px;
  opacity: 0.7;
  width: 32px;
}

.gcn .checkout-flow-view .button-bar .receipt-buttons-container .button.receipt-option.email .icon {
  background-image: url('https://assets.getbite.com/images-default/icon-email@2x.png');
}

.gcn .checkout-flow-view .button-bar .receipt-buttons-container .button.receipt-option.print .icon {
  background-image: url('https://assets.getbite.com/images-default/icon-receipt-printer@2x.png');
}

.gcn .checkout-flow-view .button-bar .receipt-buttons-container .button.receipt-option.text .icon {
  background-image: url('https://assets.getbite.com/images-default/icon-text-message.svg');
  height: 36px !important;
  width: 36px !important;
}

.gcn .checkout-flow-view .button-bar .receipt-buttons-container .button.receipt-option.disabled {
  opacity: 0.5;
}

@media (max-width: 600px) {
  .gcn .customize-flow-view .button-bar,
  .gcn .checkout-flow-view .button-bar {
    padding: 8px;
    margin: 0;
  }

  .gcn .checkout-flow-view .button-bar {
    display: flex;
    flex-direction: column;
  }

  .gcn .checkout-flow-view .payment-completed .button-bar.showing-guest-survey {
    border-top: #CCCCCC 1px solid;
    padding-top: 8px;
  }

  .gcn .checkout-flow-view .button {
    margin: 0;
  }

  .gcn .button-bar .button {
    float: none;
    width: 100%;
  }
}

.bite-embed .gcn .customize-flow-view .button:hover,
.bite-embed .gcn .checkout-flow-view .button:hover,
.gcn .customize-flow-view .button:active,
.gcn .checkout-flow-view .button:active {
  opacity: 0.7;
}

.gcn .customize-flow-view .button-bar .button,
.gcn .checkout-flow-view .button-bar .button {
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  margin: 8px;
  padding: 20px 40px;
}

.gcn .customize-flow-view .button-bar > .button,
.gcn .customize-flow-view .button-bar > .buttons-right > .button,
.gcn .checkout-flow-view .button-bar > .button,
.gcn .checkout-flow-view .button-bar > .buttons-right > .button {
  padding: 10px 40px;
  display: flex;
  align-items: center;
  height: 78px;
}

.gcn .customize-flow-view .button.prev,
.gcn .checkout-flow-view .button.prev {
  color: var(--cancel-red);
  border: var(--plain-button-border-color) 1px solid;
}

@media (max-width: 600px) {
  .gcn .customize-flow-view .button-bar .button,
  .gcn .checkout-flow-view .button-bar .button {
    margin: 4px;
  }

  .gcn .customize-flow-view .button.prev,
  .gcn .checkout-flow-view .button.prev {
    background-color: transparent;
    color: #333333;
    font-size: 16px;
    padding: 10px 0;
    width: auto;
  }
}

.gcn .customize-flow-view .buttons-right,
.gcn .checkout-flow-view .buttons-right {
  display: flex;
}

.gcn .customize-flow-view .buttons-right .button,
.gcn .checkout-flow-view .buttons-right .button {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  transition: var(--button-transition-values);
}

.gcn .customize-flow-view .button.prev.disabled,
.gcn .checkout-flow-view .button.prev.disabled,
.gcn .customize-flow-view .buttons-right .button.disabled,
.gcn .checkout-flow-view .buttons-right .button.disabled,
.gcn .gift-card-view .button.disabled,
.gcn .gift-card-view .button.select-card.disabled {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn .checkout-flow-view .button-bar.multiple-payment-methods .button {
  font-size: 22px;
}

.gcn .checkout-flow-view .button-bar.multiple-payment-methods .button.prev {
  padding: 16px 48px;
}

.gcn .checkout-flow-view .button-bar.multiple-payment-methods .buttons-right .button {
  margin-left: 0;
  padding: 16px 20px;
}

.gcn .checkout-flow-view .button-bar.multiple-payment-methods .label {
  color: #666666;
  font-size: 18px;
  margin-right: 12px;
  padding-top: 16px;
  text-align: right;
  width: 48px;
}

@media (max-width: 600px) {
  .gcn .checkout-flow-view .button-bar.multiple-payment-methods .button {
    font-size: 18px;
  }

  .gcn .checkout-flow-view .button-bar.multiple-payment-methods .button.prev {
    border: none;
    font-size: 18px;
    padding: 10px 48px;
    text-decoration: underline;
  }

  .gcn .checkout-flow-view .button-bar.multiple-payment-methods .label {
    font-size: 12px;
    margin-right: 8px;
  }
}

.gcn .customize-flow-view .button.disabled {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn .customize-flow-view .button.disabled:active {
  opacity: initial;
}

.gcn .customize-flow-view .image {
  width: 100%;
  height: 200px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* TODO: Can probably fold into a common step class. */
.gcn .customize-flow-step-view .header-container,
.gcn .full-screen-flow-step-view .header-container {
  text-align: center;
}

.gcn .customize-flow-step-view .header-container .description-subtitle {
  font-size: 16px;
  margin: 0 16px 20px;
  text-align: center;
}

/* TODO: Can probably fold into a common step class. */
.gcn .customize-flow-step-view .header-container .header,
.gcn .full-screen-flow-step-view .header-container .header {
  background-color: transparent;
  color: white;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0 24px;
  padding: 16px 24px;
  text-align: center;
  text-transform: uppercase;
}

.gcn .full-screen-flow-step-view .header-container .header {
  margin-top: 24px;
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view .header-container .header {
    font-size: 16px;
    margin: 12px 0;
    padding: 8px 16px;
  }
}

.gcn .customize-flow-step-view .max-warning {
  color: #4A4A4A;
  font-size: 24px;
  padding: 8px;
  transition: color, font;
  transition-duration: 0.5s;
}

.gcn .customize-flow-step-view .max-warning.highlighted {
  color: var(--cancel-red);
  font-size: 26px;
}

.gcn .customize-flow-step-view .step-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  overflow: scroll;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn .customize-flow-step-view.price-option .price-option-picker-view .price-options .price-option-button {
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  display: inline-block;
  font-size: 36px;
  margin-bottom: 16px;
  padding: 24px 12px;
  text-align: center;
}

.gcn .customize-flow-step-view.price-option .price-option-picker-view .price-options .price-option-button .price .cents {
  font-size: 22px;
  line-height: 2;
}

.gcn .full-screen-flow-step-view.order-summary .price .cents {
  font-size: 18px;
}

.gcn .checkout-flow-view .full-screen-flow-step-view .step-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .items-list {
  flex: 1;
  margin: auto;
  padding: 0 0 24px;
  width: 80%;
  overflow-y: scroll;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .items-list .vendor-header {
  border-top: 1px #CCCCCC solid;
  padding-top: 8px;
  margin: 0 16px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .items-list .vendor-header:not(:first-of-type) {
  margin-top: 32px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .items-list .vendor-header .title {
  font-size: 18px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .items-list .vendor-header .name {
  font-size: 24px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .divider {
  border-top: #CCCCCC 1px solid;
  height: 1px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .notice,
.gcn .full-screen-flow-step-view.order-summary .step-body .footnote {
  margin: 16px;
  text-align: center;
  font-size: 20px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details,
.gcn .full-screen-flow-step-view.order-summary .step-body .loyalty-container,
.gcn .full-screen-flow-step-view.order-summary .step-body .stored-value-container {
  display: flex;
  font-size: 24px;
  font-weight: 500;
  padding: 0 64px;
  text-align: right;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .loyalty-container,
.gcn .full-screen-flow-step-view.order-summary .step-body .stored-value-container {
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 8px;
}

.gcn .loyalty-pane-view .reward-list,
.gcn .stored-value-pane-view .transactions-list {
  flex-direction: column;
  max-height: 176px;
  overflow: scroll;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn .loyalty-pane-view .reward-list.no-rewards {
  background-color: var(--gcn-rewards-background-color);
  border-radius: 16px;
  color: #666666;
  font-size: 16px;
  font-weight: 300;
  margin-top: 8px;
  padding: 16px 150px;
  text-align: center;
}

.gcn .loyalty-pane-view .header {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
}

.gcn .loyalty-pane-view .header .rewards-title {
  flex: 1;
  margin-left: 16px;
  text-align: left;
}

.gcn .loyalty-pane-view .header .progress {
  text-align: right;
  margin-right: 16px;
  color: var(--color-primary);
  font-weight: 800;
}

.gcn .loyalty-pane-view .estimated-points-container {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
}

.gcn .loyalty-pane-view .estimated-points-container .estimated-points-info {
  flex: 1;
  margin-left: 16px;
  text-align: left;
}

.gcn .loyalty-pane-view .estimated-points-container .estimated-points-value {
  text-align: right;
  margin-right: 16px;
  font-weight: 800;
}

.gcn .reward-view,
.gcn .card-transaction {
  align-items: center;
  -ms-flex-align: center;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  border-left: 4px solid var(--color-primary);
  cursor: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  margin: 8px 16px;
}

.gcn .reward-view.disabled {
  opacity: 0.5;
}

.gcn .reward-view .reward-claim-button,
.gcn .card-transaction .refund-card-transaction-button {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 32px;
  margin: 8px;
  text-align: center;
}

.gcn .reward-view .reward-claim-button {
  background-color: white;

  --plain-button-text-color: var(--color-primary), #417505;
}

.gcn .card-transaction .refund-card-transaction-button {
  color: black;
  border-color: black;
}

.gcn .reward-view .reward-claim-button.claimed {
  border: none;
  color: var(--color-primary);
  padding-left: 19px;
  padding-right: 19px;
}

.gcn .reward-view .reward-claim-button.selected {
  background-color: inherit;
}

.card-transaction-details {
  text-align: left;
}

.gcn .reward-view .details {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.gcn .reward-view .name,
.gcn .card-transaction .card-name,
.gcn .card-transaction .card-balance {
  font-size: 20px;
  font-weight: 300;
}

.gcn .full-screen-flow-step-view.order-summary .reward-view .name .cents {
  font-size: 14px;
}

.gcn .reward-view .description {
  font-size: 16px;
  font-weight: 300;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .totals {
  flex: 1;
  margin: 8px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .totals .deducted-total {
  color: var(--color-primary);
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .controls {
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding-top: 4px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .controls .button {
  background: none;
  background-color: #EAEAEA;
  border: var(--plain-button-border-color) 1px solid;
  border-color: #333333;
  border-radius: 100px;
  border-style: solid;
  border-width: 1px;
  color: var(--plain-button-text-color);
  cursor: pointer;
  font-size: 16px;
  margin: 0 0 8px;
  min-width: 120px;
  padding: 8px 24px;
  text-align: center;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .controls .button:active {
  opacity: 0.7;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .controls .button.disabled {
  opacity: 0.5;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .controls .button.add-tip {
  background-color: #D9FFD5;
  border-color: #1E8549;
  color: #1E8549;
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view.order-summary .step-body .items-list {
    padding: 0;
    width: 100%;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .notice,
  .gcn .full-screen-flow-step-view.order-summary .step-body .footnote {
    font-size: 14px;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .order-details {
    font-size: 16px;
    margin: 0;
    padding: 4px 16px;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .order-details .cents {
    font-size: 12px;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .order-details .grand-total {
    font-size: 18px !important;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .order-details .grand-total .cents {
    font-size: 16px !important;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .order-details .totals {
    margin: 0;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .order-details .controls {
    margin: 0;
  }
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .spinner {
  margin: 8px 10px;
  padding: 8px;
  position: absolute;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .grand-total {
  font-size: 28px;
  font-weight: 700;
}

.gcn .email-receipt-view,
.gcn .ebt-card-terminal-view,
.gcn .gift-card-terminal-view,
.gcn .gift-card-view,
.gcn .coupon-view,
.gcn .loyalty-manual-auth-view,
.gcn .loyalty-card-number-view,
.gcn .tip-view {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  text-align: center;
}

.gcn .ebt-card-terminal-view,
.gcn .gift-card-terminal-view {
  padding: 48px;
  text-align: center;
}

.gcn .loyalty-manual-auth-view,
.gcn .loyalty-card-number-view {
  padding: 0;
  padding-bottom: 24px;
}

.gcn .email-receipt-view label,
.gcn .gift-card-view label,
.gcn .coupon-view label,
.gcn .loyalty-barcode-view .title,
.gcn .loyalty-barcode-view .subtitle,
.gcn .ebt-card-terminal-view .subtitle,
.gcn .gift-card-terminal-view .subtitle,
.gcn .loyalty-manual-auth-view .instructions,
.gcn .loyalty-card-number-view .instructions {
  font-size: 22px;
}

.gcn .simple-loyalty-qrcode-signup-view .title {
  font-weight: 500;
  text-align: left;
  font-size: 1.875rem;
  padding-bottom: 1.25rem;
}

.gcn .simple-loyalty-qrcode-signup-view .subtitle {
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: left;
  font-weight: 400;
}


.gcn .loyalty-barcode-view, .gcn .simple-loyalty-qrcode-signup-view {
  padding: 0;
}

.gcn .loyalty-barcode-view .body, .gcn .simple-loyalty-qrcode-signup-view .body {
  padding: 32px;
  text-align: center;
}

.gcn .loyalty-barcode-view .image {
  width: 109px;
  height: 154px;
  background-image: url("https://assets.getbite.com/images-default/pay-with-app@2x.png");
  background-position: center;
  background-size: contain;
  margin: 32px auto;
}

.gcn .simple-loyalty-qrcode-signup-view .image img {
  width: 220px;
  height: 220px;
  margin: 32px auto;
}

.gcn .ebt-card-terminal-view .title,
.gcn .gift-card-terminal-view .title {
  color: #417505;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
}

.gcn .ebt-card-terminal-view .images-container,
.gcn .gift-card-terminal-view .images-container {
  display: flex;
  justify-content: center;
  margin: 32px auto;
}

.gcn .ebt-card-terminal-view .image,
.gcn .gift-card-terminal-view .image {
  width: 192px;
  height: 192px;
  background-position: center;
  background-size: contain;
}

.gcn .ebt-card-terminal-view .image.secondary,
.gcn .gift-card-terminal-view .image.secondary {
  position: absolute;
  transition: opacity 0.4s;
  transition-timing-function: ease-in-out;
}

.gcn .ebt-card-terminal-view .image.ingenico,
.gcn .gift-card-terminal-view .image.ingenico {
  background-image: url("https://assets.getbite.com/images-default/pay-ingenico-swipe@2x.png");
}

.gcn .ebt-card-terminal-view .image.verifone,
.gcn .gift-card-terminal-view .image.verifone {
  background-image: url("https://assets.getbite.com/images-default/pay-verifone-dip@2x.png");
}

.gcn .ebt-card-terminal-view .image.hid,
.gcn .gift-card-terminal-view .image.hid {
  background-image: url("https://assets.getbite.com/images-default/pay-with-hid@2x.png");
}

.gcn .ebt-card-terminal-view .image.msr,
.gcn .gift-card-terminal-view .image.msr {
  background-image: url("https://assets.getbite.com/images-default/pay-with-msr@2x.png");
}

.gcn .gift-card-terminal-view .image.scanner {
  background-image: url("https://assets.getbite.com/images-default/icon-barcode@2x.png");
}

.gcn .ebt-card-terminal-view .cancel-button,
.gcn .gift-card-terminal-view .cancel-button {
  background-color: white;
  border: 1px solid var(--plain-button-border-color);
  border-radius: 4px;
  color: var(--cancel-red);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin-top: 16px;
  padding: 8px 24px;
}

.bite-embed .gcn .ebt-card-terminal-view .cancel-button:hover,
.gcn .ebt-card-terminal-view .cancel-button:active,
.bite-embed .gcn .gift-card-terminal-view .cancel-button:hover,
.gcn .gift-card-terminal-view .cancel-button:active {
  opacity: 0.7;
}

.gcn .loyalty-barcode-view .fallback-button {
  display: inline-block;
  font-size: 18px;
  margin-top: 16px;
  text-decoration: underline;
  transition: opacity 0.6s;
}

.gcn .loyalty-barcode-view .fallback-or-text-phone-button {
  display: block;
  font-size: 18px;
  margin-top: 16px;
  transition: opacity 0.6s;
}

.gcn .loyalty-barcode-view .fallback-or-text-redemption-button,
.gcn .gift-card-barcode-view .fallback-or-text-redemption-button {
  display: none;
  font-size: 18px;
  margin-top: 16px;
  transition: opacity 0.6s;
}

.bite-embed .gcn .loyalty-barcode-view .fallback-button:hover,
.gcn .loyalty-barcode-view .fallback-button:active {
  background-color: #CCCCCC;
  border-radius: 4px;
}

.gcn .loyalty-manual-auth-view .instructions,
.gcn .loyalty-card-number-view .instructions {
  margin-bottom: 16px;
  font-weight: 500;
  color: #4A4A4A;
}

.gcn .loyalty-manual-auth-view .sign-out-notice {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #888888;
}

.gcn .loyalty-manual-auth-view .instructions.error,
.gcn .loyalty-card-number-view .instructions.error,
.gcn .loyalty-phone-number-view .label.error {
  color: var(--cancel-red);
}

.gcn .gift-card-view .gift-card-popup-super-title,
.gcn .gift-card-barcode-view .gift-card-popup-super-title {
  font-size: 16px;
  font-weight: bold;
}

.gcn .gift-card-barcode-view .gift-card-view-top {
  padding: 30px 10px;
  max-width: 400px;
  text-align: center;
  height: 180px;
}

.gcn .gift-card-barcode-view .gift-card-view-bottom {
  text-align: center;
  margin-bottom: 30px;
  text-decoration: underline;
}

.gcn .gift-card-terminal-view .gift-card-view-bottom {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  text-decoration: underline;
}

.gcn .coupon-view .coupon-popup-title,
.gcn .gift-card-view .gift-card-popup-title,
.gcn .gift-card-barcode-view .gift-card-popup-title {
  font-size: 22px;
  font-weight: bold;
}

.gcn .gift-card-barcode-view .gift-card-popup-title {
  padding: 22px;
}

.gcn .coupon-view .coupon-popup-title.error,
.gcn .gift-card-view .gift-card-popup-title.error,
.gcn .gift-card-barcode-view .gift-card-popup-title.error {
  font-size: 20px;
  color: var(--cancel-red);
}

.gcn .gift-card-barcode-view .gift-card-popup-title.error {
  padding: 12px 22px;
}

.gcn .coupon-view .icon,
.gcn .gift-card-view .icon,
.gcn .gift-card-barcode-view .icon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 58px;
  margin: 0 auto 8px;
  width: 72px;
}

.gcn .coupon-view .icon {
  background-image: url("https://assets.getbite.com/images-default/icon-coupon@2x.png");
}

.gcn .gift-card-view .icon {
  background-image: url("https://assets.getbite.com/images-default/icon-gift-card@2x.png");
}

.gcn .gift-card-barcode-view .icon {
  background-image: url("https://assets.getbite.com/images-default/icon-barcode@2x.png");
}

.gcn .email-receipt-view input:not([type="checkbox"]),
.gcn .coupon-view #coupon-code-input,
.gcn .gift-card-view #gift-card-number-input,
.gcn .gift-card-view #gift-card-pin-input,
.gcn .loyalty-manual-auth-view input,
.gcn .loyalty-card-number-view input,
.gcn .item-recipient-view input {
  border-radius: 4px;
  color: black;
  display: block;
  font-size: 22px;
  height: 48px;
  margin: 16px auto 6px;
  padding: 4px;
  text-align: center;
}

.gcn .gift-card-view #gift-card-number-input.payment-token {
  display: none;
}

.gcn .gift-card-view label.payment-token {
  display: none;
}

.gcn .email-receipt-view input:not([type="checkbox"]) {
  width: 320px;
}

.gcn .loyalty-manual-auth-view input,
.gcn .loyalty-card-number-view input {
  margin: 6px auto;
}

.gcn .loyalty-manual-auth-view input.error,
.gcn .loyalty-card-number-view input.error {
  border-color: var(--cancel-red);
  border-width: 3px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .gift-card .gift-card-applied-amount,
.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .loyalty-payment {
  color: #1E8549;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .discounts .amount {
  color: #1E8549;
}

.gcn .email-receipt-view .button.ok,
.gcn .coupon-view .button.ok,
.gcn .gift-card-view .button.ok,
.gcn .loyalty-manual-auth-view .button.done,
.gcn .loyalty-card-number-view .button.done {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  width: 140px;
}

.gcn .gift-card-view #choose-stored-value-card-list,
.gcn .gift-card-barcode-view #choose-stored-value-card-list {
  list-style: none;
}

.gcn .gift-card-view .button.select-card,
.gcn .gift-card-barcode-view .button.select-card {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  width: 100%;
  color: white;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  margin: 2px;
  padding: 7px 40px;
}

.gcn .loyalty-manual-auth-view .button.done,
.gcn .loyalty-card-number-view .button.done {
  width: 200px;
}

.gcn .email-receipt-view .button.ok:active,
.gcn .coupon-view .button.ok:active,
.gcn .gift-card-view .button.ok:active,
.gcn .gift-card-barcode-view .button.ok:active,
.gcn .loyalty-manual-auth-view .button.done:active,
.gcn .loyalty-card-number-view .button.done:active {
  opacity: 0.7;
}

.gcn .email-receipt-view .button.ok.disabled,
.gcn .email-receipt-view .button.ok.disabled:active,
.gcn .coupon-view .button.ok.disabled,
.gcn .gift-card-view .button.ok.disabled,
.gcn .gift-card-view .button.ok.disabled:active,
.gcn .gift-card-barcode-view .button.ok.disabled,
.gcn .gift-card-barcode-view .button.ok.disabled:active,
.gcn .loyalty-manual-auth-view .button.done.disabled,
.gcn .loyalty-card-number-view .button.done.disabled,
.gcn .loyalty-manual-auth-view .button.done.disabled:active,
.gcn .loyalty-card-number-view .button.done.disabled:active {
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn .tip-view .popup {
  width: 500px;
}

.gcn .tip-view .close-button {
  display: none;
}

.gcn .email-receipt-view .title,
.gcn .tip-view .title {
  font-size: 24px;
  font-weight: 700;
  color: #1E8549;
}

.gcn .tip-view .subtitle {
  font-size: 18px;
}

.gcn .tip-view .subtitle.error,
.gcn .email-receipt-view .title.error {
  font-size: 20px;
  color: var(--cancel-red);
}

.gcn .tip-view .subtotal {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 12px;
}

.gcn .tip-view .preset-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gcn .tip-view .tip-button {
  background-color: white;
  border: #1E8549 1px solid;
  border-radius: 6px;
  color: #1E8549;
  padding: 12px 24px;
  width: calc(50% - 6px);
}

.gcn .tip-view .tip-button:first-child {
  margin-left: 0;
}

.gcn .tip-view .tip-button:last-child {
  margin-right: 0;
}

.bite-embed .gcn .tip-view .tip-button:hover,
.gcn .tip-view .tip-button:active {
  opacity: 0.7;
}

.gcn .tip-view .tip-button .percentage {
  font-weight: bold;
  font-size: 28px;
}

.gcn .tip-view .tip-button-custom,
.gcn .tip-view .tip-button-absolute {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gcn .tip-view .tip-button-custom:nth-child(2n+1):last-child {
  /*
   * Forces the custom tip button to fill the entire row, but only if it it the last button and
   * there are an odd number of buttons.
   * This only works as long as there are two columns for absolute and custom tips.
   */
  width: 100%;
}

.gcn .tip-view .tip-button-custom[data-active="true"],
.gcn .tip-view .tip-button[data-active="true"] {
  background-color: #1E8549;
  color: white;
}

.gcn .tip-view .custom-tip-view {
  margin-top: 16px;
}

.gcn .tip-view .custom-tip-view .custom-tip-display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  border: 1px solid #E5E5E5;
  background-color: white;
}

.gcn .tip-view .custom-tip-view .custom-tip-display .custom-tip-display-placeholder {
  color: #D4D4D4;
}

.gcn .tip-view .custom-tip-view .custom-tip-display .custom-tip-display-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.gcn .tip-view .custom-tip-view .custom-tip-submit-button {
  background: var(--ok-green);
  color: white;
  border-radius: 0.375rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.30), 0 -2px 2px 0 rgba(0, 0, 0, 0.10) inset, 0 -12px 15px 0 rgba(255, 255, 255, 0.12) inset, 0 12px 20px 0 rgba(0, 0, 0, 0.04) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0.75rem 1rem;
}

.gcn .tip-view .custom-tip-view .custom-tip-error-message {
  color: var(--cancel-red);
  font-size: 16px;
  font-weight: 500;
}


.gcn .tip-view .tip-button .amount {
  margin-top: 4px;
  font-size: 20px;
  font-weight: 400;
}

.gcn .tip-view .tip-button,
.gcn .tip-view .no-tip-section .tip-button {
  /* Proper margin between buttons so they don't touch  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 12px;
  height: 120px;
  font-size: 28px;
  font-weight: bold;
}

.gcn .tip-view .no-tip-section .tip-button {
  width: 100%;
  height: 56px;
  padding: 12px 24px;
  margin: 0;
}

@media (max-width: 600px) {
  .gcn .tip-view .preset-section {
    margin: 0;
  }
}

.gcn .email-receipt-view {
  height: initial;
}

.gcn .email-receipt-view .icon {
  background-image: url("https://assets.getbite.com/images-default/icon-email@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 58px;
  margin: 0 auto 8px;
  width: 58px;
}

.gcn .email-receipt-view .email-footnote {
  font-size: 12px;
  margin: 16px;
  white-space: pre-wrap;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .grand-total .spinner {
  margin: 12px 18px;
}

.gcn .full-screen-flow-step-view.order-summary .step-body .order-details .total-subscript {
  padding: 0 0 0 200px;
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .title-text {
  color: #417505;
  font-size: 64px;
  font-weight: 500;
  margin-top: 220px;
  text-align: center;
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .sub-text {
  color: #4A4A4A;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}

.gcn .full-screen-flow-step-view .step-body .image-container {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 32px 0;
  position: relative;
}

.gcn .full-screen-flow-step-view .step-body .image-container.frame2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image {
  background-image: url("https://assets.getbite.com/images-default/pay-ingenico-dip@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.5s ease-in-out;
  height: 448px;
  width: 448px;
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image.square.frame1 {
  background-image: url("https://assets.getbite.com/images-default/pay-square-dip@2x.png");
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image.square.frame2 {
  background-image: url("https://assets.getbite.com/images-default/pay-square-nfc@2x.png");
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image.ingenico.frame1 {
  background-image: url("https://assets.getbite.com/images-default/pay-ingenico-dip@2x.png");
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image.verifone.frame1 {
  background-image: url("https://assets.getbite.com/images-default/pay-verifone-dip@2x.png");
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image.verifone.frame2 {
  background-image: url("https://assets.getbite.com/images-default/pay-verifone-tap@2x.png");
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image.ingenico.frame2 {
  background-image: url("https://assets.getbite.com/images-default/pay-ingenico-apple-pay@2x.png");
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .check-button-image {
  background-image: url("https://assets.getbite.com/images-default/miura-check-button@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 64px;
  width: 43px;
  margin: 8px 8px -26px;
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view.handle-payment .step-body .payment-image {
    height: 260px;
    width: 260px;
  }

  .gcn .full-screen-flow-step-view.handle-payment .step-body .swipe-dip-text {
    font-size: 18px;
    padding: 16px 48px;
  }
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .success-header {
  margin: 64px auto 0;
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .payment-success-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view.payment-completed .step-body .success-header {
    margin: 32px auto 0;
  }
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .success-header .payment-success-title-container .payment-check-image {
  background-image: url("https://assets.getbite.com/images-default/success-check@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 100px;

  /* Manually align it with the bottom component better. */
  margin-bottom: -8px;
  transition: transform 0.5s ease-in-out;
  width: 100px;
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view .step-body .image-container {
    padding: 4px;
  }

  .gcn .full-screen-flow-step-view.payment-completed .step-body .success-header .payment-success-title-container .payment-check-image {
    display: none;
  }

  .gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment .number {
    font-size: 48px;
  }
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .success-header .payment-success-title-container .payment-success-title {
  color: #417505;
  display: inline-block;
  font-size: 48px;
  font-weight: 500;
  line-height: 1;
  margin-left: 24px;
  text-align: left;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  word-break: break-word;
}

@media (max-width: 600px), (min-width: 1200px) {
  .gcn .full-screen-flow-step-view.payment-completed .step-body .success-header .payment-success-title-container .payment-success-title {
    font-size: 36px;
    margin-left: 0;
    text-align: center;
  }
}

.bite-embed:not(.menu-preview) .gcn .full-screen-flow-step-view.payment-completed .step-body .success-image,
.gcn .full-screen-flow-step-view.payment-completed .step-body .success-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 360px;
  transition: opacity 0.8s ease-in-out;
  width: 360px;
}

.bite-embed:not(.menu-preview) .gcn .full-screen-flow-step-view.payment-completed .step-body .success-image.empty,
.gcn .full-screen-flow-step-view.payment-completed .step-body .success-image.empty {
  background-image: url("https://assets.getbite.com/images-default/pay-printer-m30@2x.png");
}

.bite-embed:not(.menu-preview) .gcn .full-screen-flow-step-view.payment-completed .step-body .success-image {
  display: none;
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view.payment-completed .step-body .success-image {
    height: 5rem;
    width: 5rem;
  }
}

.gcn .full-screen-flow-step-view.handle-payment .step-body .swipe-dip-text,
.gcn .full-screen-flow-step-view.handle-payment .step-body .press-button-text,
.gcn .full-screen-flow-step-view.payment-completed .step-body .payment-success-subtitle,
.gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment-instructions-text,
.gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment {
  color: #4A4A4A;
  font-size: 24px;
  font-weight: 500;
  padding: 16px 100px;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  white-space: pre-line;
}

/* we want the medium sized `swipe-dip-text` only on Android */
@media screen and (min-height: 1024px) {
  .gcn .full-screen-flow-step-view.handle-payment .step-body .swipe-dip-text,
  .gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment-instructions-text {
    font-size: 28px;
  }
}

/* we want the bigger sized `swipe-dip-text` only on /Tizen/ChromeOS */
@media screen and (min-height: 1910px) {
  .gcn .full-screen-flow-step-view.handle-payment .step-body .swipe-dip-text {
    font-size: 40px;
  }

  .gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment-instructions-text {
    font-size: 48px;
  }
}

@media (max-width: 600px) {
  .gcn .full-screen-flow-step-view.handle-payment .step-body .swipe-dip-text,
  .gcn .full-screen-flow-step-view.handle-payment .step-body .press-button-text,
  .gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment-instructions-text,
  .gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment {
    padding: 16px;
  }
}

/* * * ADA Reduced Screen Height UI * * *
 * How did we arrive at 915px...
 * We scale the Elo screens which are 1080x1920 to have a viewport width of
 * 768px. Which means that the height ends up being (768/1080*1920) = 1365px
 * As of Tournant build 1.10.15, the reduced height feature introduces a 33%
 * top margin, so the total viewport height ends up being 1365*0.66 = 900px.
 * 915 is just 900 with a bit of room for error.
 */
@media (max-height: 915px) {
  /* Allow scrolling in Page Nav */
  .gcn.elo .touch-blocker-overlay.page-nav-view {
    overflow: scroll;
  }

  .gcn.elo .touch-blocker-overlay .popup .popup-inner {
    max-height: 620px;
  }

  .gcn.elo .ordered-item-view .item-card .item-image {
    height: 120px;
    width: 180px;
  }

  .gcn.elo .ordered-item-view .item-card .item-details .item-name {
    font-size: 18px;
  }

  .gcn.elo .ordered-item-view .item-card .item-details {
    padding: 12px;
  }

  .gcn.elo .customize-flow-step-view .header-container .header,
  .gcn.elo .full-screen-flow-step-view .header-container .header {
    margin: 0;
  }

  .gcn.elo .full-screen-flow-step-view.handle-payment .step-body .title-text {
    margin-top: 32px;
  }

  .gcn.elo .full-screen-flow-step-view.payment-completed .step-body .success-header {
    margin: 24px auto 0;
  }

  .gcn.elo .guest-survey-view .header {
    margin: auto 0;
    font-size: 18px;
    width: 150px;
  }

  .gcn.elo .guest-survey-view .buttons > .button {
    font-size: 46px;
    height: 44px;
    width: 48px;
  }

  /* Reduce font size of Grand Total */
  .gcn.elo .full-screen-flow-step-view.order-summary .step-body .order-details .grand-total {
    font-size: 20px;
  }

  /* Hide loyatly message header */
  .gcn.elo .loyalty-pane-view .header {
    display: none;
  }

  /* Reduce size of rewards "apply" button */
  .gcn.elo .card-transaction .refund-card-transaction-button,
  .gcn.elo .reward-view .reward-claim-button {
    padding: 4px 10px;
  }

  /* Reduce margin above rewards custom string */
  .gcn.elo .full-screen-flow-step-view.order-summary .step-body .loyalty-container,
  .gcn.elo .full-screen-flow-step-view.order-summary .step-body .stored-value-container {
    margin-top: 6px;
    margin-bottom: 0;
  }

  /* ADA Reduced Screen Height: Reduce size Guest Satisfaction Survey */
  .gcn.elo .guest-survey-view {
    padding: 8px 0 8px 16px;
    display: flex;
    flex-direction: row;
  }
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment {
  padding-top: 34px;
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .fulfillment .number {
  color: black;
  font-size: 54px;
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .future-order-time {
  color: black;
  font-size: 16px;
  margin: 16px;
  text-align: center;
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .custom-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 240px;
  margin: 64px auto 0;
  width: 400px;
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .loyalty-signup-banner {
  min-height: 180px;
  max-height: 180px;
  width: 680px;
  border-radius: 20px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .loyalty-signup-banner-fallback {
  display: flex;
}

.gcn .full-screen-flow-step-view.payment-completed .step-body .loyalty-signup-banner-fallback .loyalty-signup-banner-fallback-label {
  color: #000000;
  font-size: 64px;
  font-weight: 500;
  margin: auto;
}

.gcn .customize-flow-view .customize-flow-step-view .recommendations .header {
  font-size: 18px;
  font-weight: 700;
  margin: 24px 16px 0;
}

.gcn .guest-survey-view {
  background-color: var(--gcn-popup-background-color);
  border: #CCCCCC 1px solid;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
  margin: 0 auto 8px;
  padding: 12px 36px 36px;
}

.gcn .guest-survey-view .header {
  font-size: 24px;
  font-weight: 500;
  margin: 16px 0;
  transition: transform 0.25s ease-in-out, color 0.25s ease-in-out;
}

.gcn .guest-survey-view .buttons > .button {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: black;
  font-size: 76px;
  height: 72px;
  line-height: 1;
  padding: 0;
  width: 72px;
}

.gcn .guest-survey-view .buttons .button.rating-1 {
  background-image: url("https://assets.getbite.com/images-default/guest-survey-icon-1.png");
}

.gcn .guest-survey-view .buttons .button.rating-2 {
  background-image: url("https://assets.getbite.com/images-default/guest-survey-icon-2.png");
}

.gcn .guest-survey-view .buttons .button.rating-3 {
  background-image: url("https://assets.getbite.com/images-default/guest-survey-icon-3.png");
}

.gcn .guest-survey-view .buttons .button.rating-4 {
  background-image: url("https://assets.getbite.com/images-default/guest-survey-icon-4.png");
}

.gcn .guest-survey-view .buttons > .confirmation {
  display: none;
}

.gcn .guest-survey-view .button:active {
  opacity: 1;
  transform: scale(1.2);
}

.gcn .guest-survey-view .button:not(:last-child) {
  margin-right: 12px;
}

.gcn .guest-survey-view.completed .header {
  display: none;
}

.gcn .guest-survey-view.completed .button {
  display: none;
}

.gcn .guest-survey-view.completed .button.selected {
  transform: scale(1.2);
  display: inline-block;
}

.gcn .guest-survey-view.completed .confirmation {
  display: block;
  line-height: 1.5;
  font-style: italic;
  font-size: 24px;
  font-weight: 600;
}

.gcn .showing-receipt-options .guest-survey-view {
  height: 156px;
  padding: 8px 36px;
}

.gcn .showing-receipt-options.completed .guest-survey-view {
  padding: 32px 36px;
}

.gcn .showing-receipt-options .guest-survey-view .header {
  margin: 8px 0;
}

@media (max-width: 600px), (min-width: 1200px) {
  .gcn .guest-survey-view {
    padding: 16px;
  }

  .gcn .showing-receipt-options .guest-survey-view {
    padding: 8px;
    margin: 8px;
    width: 100%;
  }

  .gcn .guest-survey-view .buttons > .button {
    font-size: 48px;
    height: 48px;
    margin: 6px;
    width: 48px;
  }

  .gcn .guest-survey-view .header {
    font-size: 18px;
    margin: 8px 0;
  }

  .gcn .guest-survey-view .button:not(:last-child) {
    margin-right: initial;
  }
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}

.loading-skeleton {
  border-radius: 6px;
  height: 100%;
  width: 100%;
  background-color: linear-gradient(-90deg, #EFEFEF 0%, #FCFCFC 50%, #EFEFEF 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
}

.cart-container {
  position: fixed;
  z-index: 20;
  bottom: calc(var(--bottom-bar-height) - 2px);
  left: 0;
  width: 100%;
}

.cart-view__top-bar {
  position: relative;
  bottom: -1px;
  z-index: 2;
  height: var(--cart-top-bar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: var(--color-primary);
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
}

.cart-view__top-bar__cart {
  display: flex;
  align-items: center;
}

.cart-view__top-bar__cart__icon-container {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.cart-view__top-bar__cart__icon-container__icon {
  margin-right: 10px;
}

.cart-view__top-bar__cart__amount {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.875rem;
  background-image: var(--color-kiosk-cart-quantity);
  border-radius: 4px;
  border: 1px solid var(--color-kiosk-cart-quantity);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  padding: 0 8px;
}

.cart-view__top-bar__fulfillment {
  display: flex;
  align-items: center;
}

.cart-view__top-bar__fulfillment > *:not(:last-child) {
  margin-right: 10px;
}

.cart-view__top-bar__fulfillment__method {
  width: max-content;
}

.cart-view__top-bar__fulfillment__method__pickup {
  width: max-content;
}

.cart-view__top-bar__fulfillment__button {
  border: 1px solid white;
}

.cart-view__bottom-bar {
  position: fixed;
  z-index: 101;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: var(--bottom-bar-height);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.2);
  background-color: white;
}

.cart-view__bottom-bar__buttons {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  width: 100%;
}

.cart-view__bottom-bar__buttons button[data-active="true"] {
  background-color: var(--color-primary);
  color: white;
}

/* give buttons a margin right except the last */
.cart-view__bottom-bar__buttons > *:not(:last-child) {
  margin-right: 10px;
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language="en-us"] {
  background-image: url("https://assets.getbite.com/images-default/icon-bottom-bar-select-language-english.png");
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language="fr-ca"] {
  background-image: url("https://assets.getbite.com/images-default/icon-bottom-bar-select-language-french.png");
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language="es-us"] {
  background-image: url("https://assets.getbite.com/images-default/icon-bottom-bar-select-language-spanish.png");
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language="zh-cn"] {
  background-image: url("https://assets.getbite.com/images-default/icon-bottom-bar-select-language-chinese-simplified.png");
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language="ja-jp"] {
  background-image: url("https://assets.getbite.com/images-default/icon-bottom-bar-select-language-japanese.png");
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language="ko-kr"] {
  background-image: url("https://assets.getbite.com/images-default/icon-bottom-bar-select-language-korean.png");
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language='pt-br'] {
  background-image: url('https://assets.getbite.com/images-default/icon-bottom-bar-select-language-portuguese.png');
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language='de-de'] {
  background-image: url('https://assets.getbite.com/images-default/icon-bottom-bar-select-language-german.png');
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language='it-it'] {
  background-image: url('https://assets.getbite.com/images-default/icon-bottom-bar-select-language-italian.png');
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language='hi-in'] {
  background-image: url('https://assets.getbite.com/images-default/icon-bottom-bar-select-language-hindi.png');
}

.cart-view__bottom-bar__buttons .language-popover-icon[data-language='vi-vn'] {
  background-image: url('https://assets.getbite.com/images-default/icon-bottom-bar-select-language-vietnamese.png');
}

.cart-view__bottom-bar__checkout-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-view__bottom-bar__checkout-total > *:first-child {
  margin-right: 16px;
}

.cart-view__bottom-bar__checkout-total__total {
  font-size: 2.25rem;
  font-weight: 700;
  width: max-content;
  color: black;
  white-space: nowrap;
}

.cart-view__bottom-bar__checkout-total__checkout {
  color: white;
  font-weight: 700;
  font-size: 1.5rem;
}

.cart-view__collapsible-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--color-kiosk-cart-background);
}

.cart-view__collapsible-view__container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.cart-view__collapsible-view__container.with-loyalty-disclaimer {
  padding: 0 20px 20px;
}

.cart-view__collapsible-view__loyalty-disclaimer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  opacity: 1;
  background-color: #27AA5E;
}

.cart-view__collapsible-view__orders {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  position: relative;
  padding: 0 10px;
  margin-bottom: 20px;
}

.cart-view__collapsible-view__orders::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.cart-view__collapsible-view__orders::-webkit-scrollbar-track{
   margin-top: 20px;
 }

.cart-view__collapsible-view__orders::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

/* add bottom border to each item except last */
.cart-view__collapsible-view__orders > *:not(:last-child) {
  border-bottom: 1px solid var(--color-kiosk-order-item-border);
}

.cart-view__collapsible-view__orders__item-container {
  position: relative;
  z-index: 1;
}

.cart-view__collapsible-view__orders__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding-left: 10px;
  width: 100%;
  background-color: var(--color-kiosk-cart-background);
  z-index: 1;
  position: ab;
}

.cart-view__collapsible-view__orders__item-delete-indicator {
  position: absolute;
  height: 95%;
  width: 99%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cancel-red);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: white;
  z-index: -1;
  font-size: 1.25rem;
  font-weight: 700;
}

.cart-view__collapsible-view__orders__item__quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  font-size: 22px;
  color: var(--color-kiosk-order-item-quantity);
  margin-right: 8px;
  width: 28px;
  font-weight: bold;
}

.cart-view__collapsible-view__orders__item__name-modifier {
  display: flex;
  align-items: center;
}

.cart-view__collapsible-view__orders__item__name-modifier__name {
  margin-right: 16px;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--color-kiosk-order-item-name);
  white-space: nowrap;
  width: max-content;
}

.cart-view__collapsible-view__orders__item__name-modifier__modifier {
  color: var(--color-kiosk-order-item-modifiers);
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.cart-view__collapsible-view__orders__item__name-modifier__recipient {
  color: var(--color-kiosk-order-item-recipient);
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
  margin-left: 1rem;
}

.cart-view__collapsible-view__orders__item__options {
  display: flex;
  align-items: center;
}

.cart-view__collapsible-view__orders__item__options > :not(:last-child) {
  margin-right: 10px;
  margin-left: 10px;
}

.cart-view__collapsible-view__orders__item__options__price {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--color-kiosk-order-item-price);
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.cart-view__collapsible-view__orders__item__options__edit {
  color: var(--ok-green);
}

.cart-view__collapsible-view__orders__item__options__remove {
  background-color: transparent;
  color: var(--color-kiosk-order-item-remove-color);
}

.cart-view__collapsible-view__recommendations__title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-kiosk-recommendation-title);
  margin-bottom: 10px;
}

.cart-view__collapsible-view__recommendations__items {
  display: flex;
  padding-bottom: 20px;
}

.cart-view__collapsible-view__recommendations__items__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 8px;
  width: 100%;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  height: 112px;
  transition: opacity 0.2s ease-in-out;
}

.cart-view__collapsible-view__recommendations__items__item[data-pressed] {
  opacity: 0.7;
}

.cart-view__collapsible-view__recommendations__items__item--loading {
  height: 100px;
}

.cart-view__collapsible-view__recommendations__items__item:not(:last-child) {
  margin-right: 16px;
}

.cart-view__collapsible-view__recommendations__items__item__picture {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  margin-right: 14px;
  object-fit: cover;
}

.cart-view__collapsible-view__recommendations__items__item__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-view__collapsible-view__recommendations__items__item__details__name-price {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cart-view__collapsible-view__recommendations__items__item__details__name-price__name {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--color-kiosk-recommendation-item-name);
  margin-bottom: 4px;
  display: box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cart-view__collapsible-view__recommendations__items__item__details__name-price__price {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--color-kiosk-order-item-price);
}

.cart-view__collapsible-view__recommendations__items__item__details__add {
  border-radius: 50%;
  border: 1px solid #CCCCCC;
  background-color: transparent;
  color: var(--ok-green);
  padding: 2px;
}

.gcn .alert-view {
  border-radius: 16px;
  padding: 16px;
  width: 400px;
}

.gcn .alert-view.terms-of-service {
  width: 700px;
  overflow-y: auto;
}

.gcn .alert-view .body {
  font-size: 24px;
  font-weight: 400;
  padding: 24px 16px;
  text-align: center;
}

.gcn .alert-view.terms-of-service .body {
  padding: 24px 16px;
  font-size: 1rem;
  text-align: left;
}

.gcn .alert-view.terms-of-service .body p {
  margin-bottom: 1rem;
}

.gcn .alert-view .bottom-nav {
  padding: 8px;
  text-align: center;
}

.gcn .alert-view .button,
.gcn .email-receipt-view .button,
.gcn .coupon-view .button,
.gcn .gift-card-view .button,
.gcn .loyalty-manual-auth-view .button,
.gcn .loyalty-card-number-view .button {
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  min-width: 120px;
  padding: 16px;
}

.gcn .alert-view .button.disabled,
.gcn .email-receipt-view .button.disabled,
.gcn .gift-card-view .button.disabled,
.gcn .gift-card-view .button.select-card.disabled,
.gcn .loyalty-manual-auth-view .button.disable,
.gcn .loyalty-card-number-view .button.disabled {
  box-shadow: none;
}

.gcn .number-entry-view .phone .phone-scrim-container {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  justify-content: center;
  min-height: 106px;
  padding: 16px 15px;
}

.gcn .number-entry-view .phone .phone-scrim-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: auto;
  max-height: 200px;
  width: 100%;
  position: absolute;
  left: 16px;
  border-radius: 50%;
  top: -90px;
}

.gcn .loyalty-program-header-view .header {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  justify-content: center;
  min-height: 106px;
  padding: 16px 15px;
}

.gcn .loyalty-program-header-view .header.has-logo {
  display: block;
  padding: 16px 15px 15px 212px;
}

.gcn .loyalty-program-header-view .header .rewards-title {
  font-size: 32px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: left;
  line-height: 1.2;
}

.gcn .loyalty-program-header-view .header .rewards-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 180px;
  width: 180px;
  position: absolute;
  left: 16px;
  border-radius: 50%;
  top: -90px;
}

.gcn .loyalty-manual-auth-view .body,
.gcn .loyalty-card-number-view .body {
  padding: 16px 64px;
}

/** LOYALTY ACCOUNT MANAGEMENT MODAL */
.gcn .loyalty-account__points {
  color: var(--color-primary);
}

.gcn .loyalty-account__reward_button {
  border-color: var(--color-primary);
  color: var(--color-primary);
  justify-content: flex-start;
}

.gcn .loyalty-account__reward_button.selected,
.bite-embed .gcn .loyalty-account__reward_button:hover {
  background: var(--color-primary);
  color: white;
}

.gcn .loyalty-account-button {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.gcn .loyalty-account-button .loyalty-account-reward-count {
  background: var(--color-primary);
  font-weight: 700;
  color: white;
  height: 36px;
  width: 36px;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -1px;
  font-size: 24px;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
}

.gcn .alert-view .button:last-child {
  margin-left: 12px;
}

.gcn .alert-view .button.ok {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
}

.gcn .alert-view .button.cancel {
  background-color: var(--cancel-red);
  border: 1px solid var(--cancel-darkred);
}

.bite-embed .gcn .alert-view .button:hover,
.gcn .alert-view .button:active {
  opacity: 0.7;
}

.bite-embed .gcn .alert-view .button.cancel:hover,
.gcn .alert-view .button.cancel:active {
  background-color: var(--cancel-darkred);
}

.gcn .touch-blocker-overlay.simple-cover span {
  text-align: center;
  font-size: 1.3em;
  color: #FFFFFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
}

.gcn .touch-blocker-overlay.simple-cover {
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: table;
  padding: 0;
  transition: transform 0.6s ease-in-out;
  z-index: 50;
}

.gcn .touch-blocker-transition-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  padding: 0;
  z-index: 60;
  opacity: 0.5;
}

.gcn .touch-blocker-overlay.simple-cover.has-bottom-bar {
  height: calc(100% - var(--bottom-bar-height));
}

.gcn .touch-blocker-overlay.page-nav-view {
  display: flex;
  flex-direction: column;
  background-color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 0;
  transition: transform 0.6s ease-in-out;
  width: 100%;
  z-index: 15;
}

.gcn .touch-blocker-overlay.page-nav-view.has-bottom-bar {
  height: calc(100% - var(--bottom-bar-height));
  overflow: auto;
}

.gcn .touch-blocker-overlay.closed-screen-view {
  align-items: center;
  -ms-flex-align: center;
  background-color: rgba(0,0,0,0.75);
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 0;
  width: 100%;
}

.gcn .touch-blocker-overlay.closed-screen-view .logo {
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.gcn .touch-blocker-overlay.closed-screen-view .closed-message {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  padding: 0 16px;
  text-align: center;
  vertical-align: middle;
}

.gcn .page-nav-view .body {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 80px;
  padding-bottom: var(--bottom-bar-space);
}

/* Chrome 56 support */
.gcn .page-nav-view .column {
  display: flex;
  justify-content: flex-start;
  margin-left: -60px;
  margin-top: -16px;
  flex-wrap: wrap;
}

.gcn .page-nav-view .column .page-button {
  background-color: initial;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
  color: white;
  display: inline-block;
  font-size: 26px;
  height: initial;
  line-height: initial;
  margin-left: 60px;
  margin-top: 32px;
  padding: 16px 8px;
  text-align: center;
  transition: transform 0.085s ease-in-out;
  width: calc(50% - 60px);
}

@supports (display: grid) {
  .gcn .page-nav-view .column {
    align-items: center;
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: repeat(2, 1fr);
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-row-gap: 8px;
    justify-content: center;
    margin: 0;
  }

  .gcn .page-nav-view .column .page-button {
    width: 260px;
    margin: 16px 0;
  }
}

.bite-embed .gcn .page-nav-view .column .page-button:hover,
.gcn .page-nav-view .column .page-button:active {
  transform: scale(0.9);
}

.gcn .page-nav-view .header-container .logo.has-image {
  height: 120px;
  width: 240px;
}

.gcn .page-nav-view .header-container .logo-container {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  height: 180px;
  justify-content: center;
}

.gcn .page-nav-view .header-container .logo-container .logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 32px;
  color: #FFFFFF;
}

.gcn .page-nav-view .header-container .title-container {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  justify-content: center;
  margin: 32px;
}

.gcn .page-nav-view .header-container .title-container .title {
  border: #4A4A4A 1px solid;
  border-radius: 8px;
  color: #4A4A4A;
  font-size: 24px;
  font-weight: 500;
  padding: 10px 48px;
  text-align: center;
}

.gcn .page-nav-view .exit-to-cover-button {
  position: absolute;
  top: 50px;
  height: 60px;
  max-width: 300px;
  display: flex;
  align-items: center;
  border-radius: 0 12px 12px 0;
  border: 1px solid rgb(204,204,204);
  box-shadow: 0 1px 5px rgba(0,0,0,0.2);
  opacity: 1;
  background-color: rgba(244,242,242,0.6);
  padding: 0 12px;
}

.gcn .page-nav-view .exit-to-cover-button .icon {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.7;
  background-image: url("https://assets.getbite.com/images-default/icon-exit@2x.png");
  transform: scaleX(-1);
}

.gcn .page-nav-view .exit-to-cover-button .text {
  margin-left: 12px;
  opacity: 1;
  color: rgb(102,102,102);
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
}

.gcn .page-nav-view .exit-to-cover-button .text:empty {
  display: none;
}

.gcn .page-nav-view .footer {
  display: block;
  height: 120px;
}

@media (max-width: 600px) {
  .gcn .page-nav-view .header-container .logo-container {
    height: 100px;
  }

  .gcn .page-nav-view .header-container .title-container {
    margin: 16px;
  }

  .gcn .page-nav-view .header-container .title-container .title {
    font-size: 20px;
    padding: 0;
  }

  .gcn .page-nav-view .column {
    grid-column-gap: 16px;
    height: 100%;
    width: 100%;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .gcn .page-nav-view .column {
      height: max-content;
    }
  }

  .gcn .page-nav-view .column .page-button.has-image {
    background-size: 80%;
    height: 100%;
    margin: 0;
    width: 100%;
  }
}

.gcn .addon-set .badges {
  margin-top: 8px;
}


.gcn .order-failed-view {
  background-color: white;
  padding: 32px;
}

.gcn .order-failed-view .messages {
  font-size: 24px;
  line-height: 1;
  padding: 0 32px 16px;
  text-align: center;
}

.gcn .order-failed-view .messages div {
  margin-bottom: 16px;
}

.gcn .order-failed-view .order-title {
  font-size: 20px;
  font-weight: 700;
  padding: 8px;
  text-align: center;
}

.gcn .order-failed-view .item-list {
  max-height: 460px;
  background-color: var(--gcn-popup-background-color);
  border-radius: 2px;
  margin-bottom: 32px;
  overflow-y: scroll;
  padding-bottom: 16px;
  /* stylelint-disable-next-line plugin/browser-compat */
  -webkit-overflow-scrolling: touch;
}

.gcn .order-failed-view .bottom-nav {
  text-align: center;
}

.gcn .order-failed-view .bottom-nav .button.done {
  background-color: var(--ok-green);
  border: 1px solid var(--ok-darkgreen);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 32px;
}

.bite-embed .gcn .order-failed-view .bottom-nav .button.done:hover,
.gcn .order-failed-view .bottom-nav .button.done:active {
  opacity: 0.7;
}

.gcn .order-failed-view  .showing-receipt-options > .button {
  display: block;
  height: 74px;
}

.gcn .order-failed-view .receipt-buttons-container .button.receipt-option {
  align-items: center;
  -ms-flex-align: center;
  background-color: white;
  border: var(--plain-button-border-color) 1px solid;
  border-radius: 40px;
  color: var(--plain-button-text-color);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 8px 24px;
  margin-bottom: 20px;
}

.gcn .order-failed-view .receipt-buttons-container .button.receipt-option .icon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 32px;
  margin: 0 auto 2px;
  opacity: 0.7;
  width: 32px;
}

.gcn .order-failed-view .receipt-buttons-container .button.receipt-option.email .icon {
  background-image: url("https://assets.getbite.com/images-default/icon-email@2x.png");
}

.gcn .order-failed-view .receipt-buttons-container .button.receipt-option.print .icon {
  background-image: url("https://assets.getbite.com/images-default/icon-receipt-printer@2x.png");
}

.gcn .order-failed-view .receipt-buttons-container .button.receipt-option.disabled {
  opacity: 0.5;
}

@media (max-width: 600px) {
  .gcn .order-failed-view .messages {
    padding: 0;
  }
}

.gcn .full-screen-flow-step-view .map {
  border: #CCCCCC 1px solid;
  height: 533px;
}

.gcn .layered-image-view {
  height: 100%;
  position: relative;
  width: 100%;
}

.gcn .layered-image-view .base-layer,
.gcn .layered-image-view .top-layers {
  height: inherit;
  position: absolute;
  width: inherit;
}

.gcn .layered-image-view .layer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: inherit;
  position: absolute;
  width: inherit;
}

.gcn .build-your-own-view {
  background-color: white;
  height: inherit;
}

.gcn .build-your-own-view .layered-image-view .layer.top {
  /* TODO: This is a hack to get toppings to appear on the pizza properly.
     Move to the pizza demo css. */
  background-size: 82%;
}

.gcn .scanner-toast-view {
  background-color: rgba(0,0,0,0.85);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  color: white;
  display: flex;
  height: 220px;
  padding: 30px;
  width: 640px;
}

.gcn .scanner-toast-view .image {
  background-color: #DDDDDD;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  display: block;
  height: 100%;
  position: relative;
  width: 160px;
}

.gcn .scanner-toast-view .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.gcn .scanner-toast-view .text .name {
  font-size: 36px;
  padding-bottom: 8px;
}

.gcn .scanner-toast-view .text .label {
  font-size: 24px;
}

.gcn .list-picker-view {
  padding: 24px;
}

.gcn .list-picker-view .header {
  font-size: 24px;
  margin: 16px;
}

.gcn .list-picker-view .list-item-button {
  border-radius: 4px;
  background-color: var(--ok-green);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 16px;
  border: 1px solid var(--ok-darkgreen);
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  margin: 8px;
  padding: 24px 24px 24px 52px;
}

.gcn .future-order-picker-view .list-item-button.asap {
  background-image: url("https://assets.getbite.com/images-default/icon-walking-guy.png");
}

.gcn .future-order-picker-view .list-item-button.later-today,
.gcn .future-order-picker-view .list-item-button.next-business-day {
  background-image: url("https://assets.getbite.com/images-default/icon-clock.png");
}

.gcn .future-order-picker-view .list-item-button:active,
.bite-embed .gcn .future-order-picker-view .list-item-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.gcn .future-order-picker-view .missing-timeslots-warning {
  max-width: 360px;
  text-align: center;
  margin: 0 auto 16px;
  font-style: italic;
}

.gcn .future-order-picker-view .choose-order-time .header {
  text-align: center;
}

.gcn .fulfillment-method-picker-view .title-bar .fulfillment-close-button {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 16px;
  width: 16px;
  padding: 5px;
  margin: 15px;
  cursor: pointer;
  background-image: url("https://assets.getbite.com/images-default/icon-popup-exit.png");
}

.gcn .fulfillment-method-picker-view .choose-fulfillment-method {
  text-align: center;
}

@media (min-width: 600px) {
  .gcn .fulfillment-method-picker-view .fulfillment-options {
    display: flex;
    padding: 30px 10px 40px;
    flex-direction: row;
    justify-content: center;
  }

  .gcn .fulfillment-method-picker-view.list-picker-view {
    padding: 40px 20px 20px;
  }

  .gcn .fulfillment-method-picker-view.list-picker-view .list-item-button {
    display: flex;
    align-content: flex-end;
    height: 240px;
    width: 180px;
    background-position: 50% 35%;
    background-size: 100px;
    border-radius: 10px;
    padding: 20px 20px 16px;
    margin: 0 20px;
    cursor: pointer;
  }

  .gcn .fulfillment-method-picker-view.list-picker-view .list-item-button .title {
    width: 100%;
    min-height: 70px;
    display: inline-flex;
    align-self: flex-end;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
  }

  .gcn .fulfillment-method-picker-view .list-item-button.dine-in {
    background-position: 50% 38%;
    background-size: 120px;
    background-image: url("https://assets.getbite.com/images-default/table-chairs@2x.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.to-go {
    background-position: 50% 30%;
    background-size: 80px;
    background-image: url("https://assets.getbite.com/images-default/bag@2x.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.curbside {
    background-position: 50% 35%;
    background-size: 130px;
    background-image: url("https://assets.getbite.com/images-default/curbside-2-white.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.delivery {
    background-position: 50% 35%;
    background-size: 120px;
    background-image: url("https://assets.getbite.com/images-default/delivery-truck-2-white.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.outpost {
    background-position: 50% 30%;
    background-image: url("https://assets.getbite.com/images-default/pickup-outpost-white@2x.png");
  }
}

@media (max-width: 600px) {
  .gcn .list-picker-view .header {
    font-size: 22px;
  }

  .gcn .future-order-picker-view .list-item-button:active,
  .bite-embed .gcn .future-order-picker-view .list-item-button:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .gcn .fulfillment-method-picker-view.list-picker-view .list-item-button {
    background-color: var(--ok-green);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 20px;
    border: 1px solid var(--ok-darkgreen);
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    margin: 8px;
    padding: 30px 24px 30px 80px;
    cursor: pointer;
  }

  .gcn .fulfillment-method-picker-view .list-item-button.dine-in {
    background-size: 70px;
    background-image: url("https://assets.getbite.com/images-default/table-chairs@2x.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.to-go {
    background-position: 30px 15px;
    background-size: 50px;
    background-image: url("https://assets.getbite.com/images-default/bag@2x.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.curbside {
    background-position: 25px 12px;
    background-size: 70px;
    background-image: url("https://assets.getbite.com/images-default/curbside-2-white.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.delivery {
    background-position: 25px 12px;
    background-size: 70px;
    background-image: url("https://assets.getbite.com/images-default/delivery-truck-2-white.png");
  }

  .gcn .fulfillment-method-picker-view .list-item-button.outpost {
    background-size: 70px;
    background-image: url("https://assets.getbite.com/images-default/pickup-outpost-white@2x.png");
  }
}

.gcn .fulfillment-method-picker-view.list-picker-view .list-item-button:active,
.bite-embed .gcn .fulfillment-method-picker-view.list-picker-view .list-item-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.gcn .fulfillment-method-picker-view.list-picker-view .list-item-button.disabled {
  pointer-events: none;
  opacity: 0.8;
}

.gcn .future-order-picker-view .times-list .button {
  background-color: #FFFFFF;
  border: 1px solid var(--plain-button-border-color);
  font-size: 16px;
  font-weight: 500;
  padding: 24px;
  text-align: center;
}

.gcn .future-order-picker-view .times-list .button:active,
.bite-embed .gcn .future-order-picker-view .times-list .button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.gcn .future-order-picker-view .times-list .button.disabled {
  pointer-events: none;
  opacity: 0.8;
  background-color: var(--disabled-lightgrey);
  border-color: var(--disabled-lightgrey);
}

.gcn .future-order-picker-view .times-list .button:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.gcn .future-order-picker-view .times-list .button:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.gcn .weighable-container {
  display: flex;
  justify-content: center;
}

.gcn .weighable-view {
  align-items: center;
  -ms-flex-align: center;
  background-color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  text-align: center;
  width: 320px;
}

.gcn .weighable-view .instructions {
  font-size: 18px;
  margin-bottom: 8px;
}

.gcn .weighable-view .weight-display {
  background-color: #ECECEC;
  border-radius: 8px;
  box-shadow: inset 0 0 4px rgba(0,0,0,0.25);
  padding: 8px 12px;
  width: 96px;
}

.gcn .weighable-view .weight-display .weight-value {
  font-size: 24px;
}

.gcn .weighable-view .unit {
  font-size: 18px;
}

.gcn .note-with-icon {
  display: flex;
  justify-content: center;
}

.gcn .note-with-icon .contents {
  align-items: center;
  -ms-flex-align: center;
  border-radius: 100px;
  display: flex;
  padding: 8px;
}

.gcn .note-with-icon.alcohol-id-check .contents {
  border: #E80E2F 2px solid;
}

.gcn .note-with-icon.scanner-prompt .contents {
  border: #CCCCCC 1px solid;
  padding: 4px 12px;
}

.gcn .note-with-icon .contents .icon {
  font-size: 16px;
  font-weight: 700;
}

.gcn .note-with-icon.alcohol-id-check .contents .icon {
  background-color: #E80E2F;
  border-radius: 100px;
  color: #FFFFFF;
  font-weight: 500;
  padding: 12px 10px;
}

.gcn .note-with-icon.scanner-prompt .contents .icon {
  background-image: url("https://assets.getbite.com/images-default/status-scanner-icon@2x.png");
  background-position: center;
  background-size: contain;
  height: 22px;
  width: 32px;
}

.gcn .note-with-icon .contents .label {
  margin-left: 12px;
}

.gcn .note-with-icon.alcohol-id-check .contents .label {
  color: #E80E2F;
  width: 200px;
}

.gcn .note-with-icon.scanner-prompt .contents .label {
  color: #666666;
  font-size: 20px;
  margin-left: 6px;
  text-align: center;
}

.gcn .quantity-selection-view {
  display: flex;
  align-items: center;
  -ms-flex-align: center;
}

.gcn .item-order-view .quantity-selection-view {
  margin-left: auto;
  margin-right: 8px;
}

.gcn .item-order-view .quantity-selection-view .quantity-selector-label {
  font-size: 24px;
  margin-right: 5px;
}

.gcn .quantity-selection-view .adjust-button {
  color: var(--color-primary);
  font-size: 30px;
  text-align: center;
  width: 34px;
  height: 40px;
  line-height: 1.1;
}

/* Chrome, Safari, Edge, Opera */
/* stylelint-disable-next-line plugin/browser-compat */
.gcn .quantity-selection-view .quantity::-webkit-outer-spin-button,
.gcn .quantity-selection-view .quantity::-webkit-inner-spin-button {
  appearance: none;
  border: 1px solid #CCCCCC;
  box-shadow: 0 1px 2px rgba(0,0,0,0.5);
  margin: 0;
}

/* Firefox */
.gcn .quantity-selection-view .quantity[type="number"] {
  appearance: textfield;
  border: 1px solid #CCCCCC;
  box-shadow: 0 1px 2px rgba(0,0,0,0.5);
}

.gcn .quantity-selection-view .quantity:invalid {
  border: none;
  border: 3px solid red;
}

.gcn .quantity-selection-view .adjust-button.minus {
  border-radius: 3px 0 0 3px;
}

.gcn .quantity-selection-view .adjust-button.plus {
  border-radius: 0 3px 3px 0;
}

@media (max-width: 600px) {
  .gcn .quantity-selection-view .adjust-button {
    font-size: 40px;
    line-height: 0.8;
  }

  .gcn .quantity-selection-view .quantity {
    font-size: 24px !important;
    line-height: 1.6 !important;
  }
}

.gcn .quantity-selection-view .adjust-button:active,
.bite-embed .gcn .quantity-selection-view .adjust-button:hover {
  background-color: rgba(0,0,0,0.2);
}

.gcn .quantity-selection-view .adjust-button.disabled {
  pointer-events: none;
  border-color: var(--disabled-lightgrey);
  color: var(--disabled-lightgrey);
}

.gcn .quantity-selection-view .quantity {
  background-color: #FFFFFF;
  border-radius: 3px;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  line-height: 2;
  margin: 0;
  text-align: center;
  width: 40px;
}

.gcn .item-order-view.weighable-item .quantity-selection-view .quantity {
  width: 100px;
}

.gcn .addon-item .quantity-selection-view {
  flex-direction: column-reverse;
  padding-right: 8px;
}

.gcn .addon-item .quantity-selection-view .adjust-button {
  border-radius: 1px;
  height: 40px;
  line-height: 1.5;
  width: 36px;
}

.gcn .addon-item .quantity-selection-view .adjust-button.plus {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: white;
}

.gcn .addon-item .quantity-selection-view .adjust-button.minus {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
}

.gcn .addon-item .quantity-selection-view .quantity {
  border-radius: 3px;
  line-height: 1.9;
  margin: 0;
  width: 36px;
}

.gcn .addon-item .quantity-selection-view .adjust-button.disabled {
  border-color: var(--disabled-lightgrey);
  color: rgba(0,0,0,0.4);
}

/*
 * COMMON STYLES
 */

.gcn .price.braces::before {
  content: "(";
}

.gcn .price.braces::after {
  content: ")";
}

.gcn .shadow-z-1 {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);
}

.gcn .bg-color-spot-1 {
  background-color: #417505;
}

.gcn .color-spot-1 {
  color: #417505;
}

.gcn .border-color-spot-1 {
  border-color: #417505;
}

.gcn .font-title {
  font-family: Futura, Arial, sans-serif;
}

.gcn .font-body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.gcn .price .cents {
  line-height: 1.8;
  vertical-align: top;
  top: 0;
  font-size: 14px;
}

.gcn .link-button {
  color: white;
  cursor: pointer;
  background-color: var(--color-primary);
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 0 0 rgba(0,0,0,0.09);
  font-size: 24px;
  margin: 8px 30px;
  padding: 8px 32px;
  text-align: center;
}

.bite-embed .gcn .link-button:hover,
.gcn .link-button:active {
  opacity: 0.7;
}

.gcn .link-button-inline {
  color: var(--selected-blue);
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}

.bite-embed .gcn .link-button-inline:hover,
.gcn .link-button-inline:active {
  opacity: 0.7;
}

/*
 * VARIABLES
 */

/* animate.css subset */
.animated.bounce {
  animation-duration: 1.25s;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes bounce-right {
  0%,
  20%,
  53%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-30px, 0, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-15px, 0, 0);
  }

  90% {
    transform: translate3d(-4px, 0, 0);
  }
}

@keyframes bounce-left {
  0%,
  20%,
  53%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(30px, 0, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(15px, 0, 0);
  }

  90% {
    transform: translate3d(4px, 0, 0);
  }
}

.bounce-left {
  animation-name: bounce-left;
  transform-origin: center bottom;
}

.bounce-right {
  animation-name: bounce-right;
  transform-origin: center bottom;
}

/* Strikethrough */
@keyframes strikethrough {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.strikethrough {
  display: inline-block;
  position: relative;
  transition: all 1s ease-out;
}

.strikethrough::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -0.7em;
  background: black;
  transform-origin: center left;
  animation: strikethrough 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

/* https://projects.lukehaas.me/css-loaders/ */
.mini-loader,
.mini-loader::after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}

.mini-loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(0,0,0,0.25);
  border-right: 3px solid rgba(0,0,0,0.25);
  border-bottom: 3px solid rgba(0,0,0,0.25);
  border-left: 3px solid #FFFFFF;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.loader,
.loader::after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255,255,255,0.2);
  border-right: 1.1em solid rgba(255,255,255,0.2);
  border-bottom: 1.1em solid rgba(255,255,255,0.2);
  border-left: 1.1em solid #FFFFFF;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
  position: relative;
  z-index: 1;
}

/* Stripe Elements */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #E6EBF1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #CFD7DF;
  border: none;
}

.StripeElement--invalid {
  border-color: #FA755A;
}

.StripeElement--webkit-autofill {
  background-color: #FEFDE5 !important;
}

/* IE11 Specific stuff */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* Scrollbars are very ugly and intrusive on IE11 */
  body::-webkit-scrollbar {
    display: none;
  }

  html,
  body {
    -ms-overflow-style: none;
    overflow: auto;
  }

  /* Exception for the summary item list */
  .gcn .full-screen-flow-step-view.order-summary .step-body .items-list::-webkit-scrollbar {
    display: block;
  }

  .gcn .full-screen-flow-step-view.order-summary .step-body .items-list {
    -ms-overflow-style: auto;
  }

  .gcn {
    /* So backdrop overlays can go full width/height */
    position: inherit;
  }

  .gcn .spinner-overlay {
    position: relative;
  }

  .gcn .touch-blocker-overlay .popup,
  .gcn .customize-flow-view .step-panel,
  .gcn .full-screen-flow-step-view .step-panel {
    position: relative;
  }

  .gcn .addon-set-picker-view .addons,
  .gcn .price-option-picker-view .price-options {
    display: flex;
  }

  .gcn .price-option-picker-view .price-options .price-option-button,
  .gcn .addon-set-picker-view .addons .addon-item {
    flex-basis: 40%;
    margin: 8px;
  }

  .gcn .customize-flow-view .step-panel,
  .gcn .full-screen-flow-step-view .step-panel {
    bottom: 0;
  }

  .gcn .checkout-flow-view .payment-completed .button-bar {
    justify-content: inherit;
  }

  .gcn .guest-survey-view .buttons > .button {
    color: transparent;
  }

  .gcn .guest-survey-view .buttons > .button::after {
    color: #000000;
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid #000000;
    border-radius: 50%;
  }

  .gcn .guest-survey-view .buttons > .button.rating-1::after {
    content: "1";
  }

  .gcn .guest-survey-view .buttons > .button.rating-2::after {
    content: "2";
  }

  .gcn .guest-survey-view .buttons > .button.rating-3::after {
    content: "3";
  }

  .gcn .guest-survey-view .buttons > .button.rating-4::after {
    content: "4";
  }
}

.gcn-web.bite-web .gcn.mobile-app .top-nav-view {
  display: none;

  --top-nav-bar-height: 0 !important;
}

.gcn-web.bite-web .gcn.mobile-app {
  --top-nav-bar-height: 0 !important;
}

.gcn .menu-filters-container {
  border-bottom: 1px solid #CCCCCC;
  background-color: var(--menu-filters-container-background-color);
  border-top: 1px solid #CCCCCC;
  color: var(--ion-color-contrast);
  display: inline-flex;
  height: var(--menu-filters-container-height);
  text-align: center;
  transition: all 0.2s linear;
  width: 100%;
}

.gcn .menu-filters-container.active {
  background-color: var(--color-primary);
  display: inline-flex;
}

.gcn .menu-filters-button {
  align-items: center;
  background-color: var(--card-background);
  color: black;
  border-radius: 30px;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  margin: auto;
  padding: 5px 10px;
  transition: all 0.2s linear;
}

.gcn .menu-filters-button.active {
  background-color: unset;
  color: white;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.gcn .menu-filters-button .menu-filters-button-icon {
  padding: 0 5px 0 0;
  font-size: 18px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.gcn .side-nav-menu .menu-filters-header {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;

  /* Render the box-shadow on top of the element below the header */
  z-index: 1;
}

.gcn .menu-filters-header .menu-filters-container .modal-badge {
  height: 36px;
  width: 36px;
  margin-left: 12px;
  background-size: 24px;
  background-color: var(--card-background);
  border-radius: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-position: center;
}

.gcn .menu-filters-header .menu-filters-container .clear-filters-button {
  background-color: unset;
  display: flex;
  align-self: center;
}

.modal-toast {
  top: -70px;
  text-align: center;
  font-weight: 700;

  --background: #EB445A;
  --width: 100%;
  --height: 50px;
  --border-radius: 0;
  --start: 0;
}

@media screen and (min-width: 590px) {
  .modal-toast {
    top: -80px;

    --border-radius: 30px;
    --width: 580px;
    --height: 36px;
  }
}

.gcn .fulfillment-header .menu-filters-button .menu-filters-button-text.additional-filters {
  display: none;
}

.gcn .make-it-a-combo-view {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
}

.gcn .make-it-a-combo-view .header {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.3;
  padding: 16px;
}

.gcn .make-it-a-combo-view .item-details {
  margin: 8px 0 20px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  position: relative;
}



.gcn .make-it-a-combo-view .image {
  height: 250px;
  width: 100%;
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
  background-size: contain;
}

.gcn .make-it-a-combo-view .button-container {
  display: flex;
  flex-direction: column;

  /* no chrome 56 alternatives to this */
  /* stylelint-disable-next-line */
  justify-content: space-evenly;
  width: 100%;
}

.gcn .make-it-a-combo-view .button-container .button {
  border-radius: 6px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 32px;
  margin: 8px 0;
  text-align: center;
  width: 100%;
}

.gcn .make-it-a-combo-view .button-container .button.yes {
  background-color: var(--ok-green);
}

.gcn .make-it-a-combo-view .button-container .button.no {
  background-color: var(--cancel-red);
}

/* stylelint-disable-next-line at-rule-no-vendor-prefix */
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.gcn .make-it-a-combo-view .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.gcn .make-it-a-combo-view .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.gcn .make-it-a-combo-view .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.gcn .make-it-a-combo-view .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

.gcn .make-it-a-combo-view .accepted-animation {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gcn .make-it-a-combo-view .accepted-animation .svg {
  display: block;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.gcn .bag-opt-in-view {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
}

.gcn .bag-opt-in-view .header {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.3;
  padding: 16px;
}

.gcn .bag-opt-in-view .image {
  height: 200px;
  width: 200px;
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
  background-size: contain;
  margin: 10px;
}

.gcn .bag-opt-in-view .button-container {
  display: flex;
  flex-direction: row;

  /* no chrome 56 alternatives to this */
  /* stylelint-disable-next-line */
  justify-content: space-evenly;
  width: 100%;
}

.gcn .bag-opt-in-view .button-container .button {
  border-radius: 6px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 32px;
  margin: 0 8px;
  text-align: center;
  width: 100%;
}

.gcn .bag-opt-in-view .button-container .button.opt-in {
  background-color: var(--ok-green);
}

.gcn .bag-opt-in-view .button-container .button.opt-out {
  background-color: var(--cancel-red);
}

@media screen and (max-width: 768px) {
  .gcn .fulfillment-header .menu-filters-button .menu-filters-button-text.additional-filters {
    display: unset;
  }

  .gcn .fulfillment-header .menu-filters-header .menu-filters-container span.modal-badge:nth-child(n + 5) {
    display: none;
  }
}

/** Need to set dimension and position for menu-filters-modal to show on 3 series ELO devices **/
.gcn-modal.menu-filters-modal {
  height: 100vh;
  width: 100vw;
  top: 0;
}

.gcn-button {
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}

.gcn-button[data-pressed] {
  opacity: 0.5;
}

@supports (text-decoration-line: underline) {
  .gcn-custom-string {
    position: relative;
    display: inline-block;
    text-decoration-line: underline;
    text-decoration-style: dashed;
    text-decoration-color: rgba(0,0,0,.4);
  }

  .gcn-custom-string .tooltip {
    visibility: hidden;
    background-color: black;
    color: #ffffff;
    text-align: center;
    border-radius: 0.3125rem;
    padding: 0.3125rem 18px;
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 14%;
  }

  .gcn-custom-string:hover {
    cursor: pointer;
  }

  .gcn-custom-string:hover .tooltip {
    visibility: visible;
  }
}

@supports not(text-decoration-line: underline) {
  .gcn-custom-string {
    position: relative;
    display: inline-block;
    border-bottom: 1px solid rgba(0,0,0,.4)
  }

  .gcn-custom-string .tooltip {
    visibility: hidden;
    background-color: black;
    color: #ffffff;
    text-align: center;
    border-radius: 0.3125rem;
    padding: 0.3125rem 18px;
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 14%;
  }

  .gcn-custom-string:hover {
    cursor: pointer;
  }

  .gcn-custom-string:hover .tooltip {
    visibility: visible;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

}
